<p-confirmDialog></p-confirmDialog>

<p-header>
  <div class="flex justify-content-between pb-4 pt-2">
    <p-tabMenu [model]="tabMenuItems" [(activeItem)]="activeMenuItem" (activeItemChange)="onActiveItemChange()">
      <ng-template pTemplate="item" let-item>
        <a class="p-menuitem-link p-3" [routerLink]="item.routerLink" [routerLinkActive]="'p-menuitem-link-active'"> {{ item.label | translate }}</a>
      </ng-template>
    </p-tabMenu>
    <i class="pi pi-times cursor-pointer flex align-items-center" (click)="ref.close()"></i>
  </div>
</p-header>
<div class="mt-4">
  @if(addedAccess) {
  <span class="pt-5">
    @switch (addedAccess) { @case ('user') {
    <span class="w-full">
      <p class="mb-0">{{ 'private.sourceDetailsPage.createAccess.modal.selectUser' | translate }}</p>
      <p-multiSelect id="users" styleClass="w-full" [options]="users" appendTo="body" optionLabel="fullName" [(ngModel)]="user"></p-multiSelect>
    </span>
    } @case ('group') {
    <span class="w-full">
      <p class="mb-0">{{ 'private.sourceDetailsPage.createAccess.modal.selectGroup' | translate }}</p>
      <p-multiSelect id="groups" styleClass="w-full" [options]="groups" appendTo="body" optionLabel="name" [(ngModel)]="group"></p-multiSelect>
    </span>
    } }
  </span>

  <div class="flex w-full justify-content-end pt-5 gap-2">
    <button
      pButton
      [label]="'private.sourceDetailsPage.createAccess.modal.removeAllRules' | translate"
      [loading]="isDeleting"
      [disabled]="removeAllButtonDisabled"
      class="p-button-danger"
      (click)="deleteAllVisibilities()"
    ></button>
    <button pButton [label]="'common.add' | translate" [disabled]="buttonDisabled" [loading]="isCreating" (click)="addVisibility()"></button>
  </div>
  }
</div>
