import { Component, Input } from '@angular/core';
import { Message } from 'src/app/main/private/chat/api/message.model';
import { ChatService } from 'src/app/main/private/chat/service/chat.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { KbReferences } from '../../models/chat-message.model';

@Component({
  selector: 'app-chat-kb-span',
  templateUrl: './chat-kb-span.component.html',
  styleUrls: ['./chat-kb-span.component.scss'],
})
export class ChatKbSpanComponent {
  @Input() message?: Message;
  isWholeKbListVisible: boolean = false;

  constructor(private readonly toastService: ToastService, private readonly chatService: ChatService) {}

  showKbMessage() {
    if (this.message?.kb) {
      this.toastService.addSuccess({ detail: 'This message has been generated with the bot Knowledge base!' });
    } else {
      this.toastService.addWarning({ detail: 'This message has not been generated with the bot Knowledge base' });
    }
  }

  setSelectedSource(value: KbReferences) {
    if (value.type != 'FILE') window.open(value.data, '_blank');
    // this.chatService.setSelectedSource$(value);
  }

  onViewAllKbClick() {
    this.isWholeKbListVisible = !this.isWholeKbListVisible;
  }

  isKbReferencesLarge() {
    return this.message && this.message.kbReferences.length >= 3;
  }
}
