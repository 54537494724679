import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ICreateDataVisibilityDTO } from '../shared/models/chat-bot-source.model';
import { DataVisibility, IDataVisibility } from '../shared/models/data-visibility.model';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataVisibilityService {
  constructor(private readonly apiService: ApiService) {}

  createVisibility$(sourceId: string, body: ICreateDataVisibilityDTO) {
    return this.apiService.post<any>(`manage/data-sources/${sourceId}/data-visibilities`, body);
  }

  editVisibility$(sourceId: string, visibilityId: string, body: Partial<ICreateDataVisibilityDTO>) {
    return this.apiService.patch<any>(`manage/data-sources/${sourceId}/data-visibilities/${visibilityId}`, body);
  }

  deleteVisibility$(sourceId: string, visibilityId: string) {
    return this.apiService.delete<any>(`manage/data-sources/${sourceId}/data-visibilities/${visibilityId}`);
  }
}
