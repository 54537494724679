import { Directive, OnInit, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';

@Directive({
  selector: '[appResponsiveColumns]',
})
export class ResponsiveColumnsDirective implements OnInit, OnDestroy {
  @Output() columnsChange = new EventEmitter<number>();
  private resizeObserver?: ResizeObserver;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        const columns = this.calculateColumns(width);
        this.columnsChange.emit(columns);
      }
    });

    this.resizeObserver.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  private calculateColumns(width: number): number {
    if (width < 600) return 1;
    if (width < 900) return 2;
    if (width < 1200) return 3;
    return 4;
  }
}
