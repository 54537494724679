import { DateTime } from 'luxon';

export type SharedVisibility = 'public' | 'loggedin' | 'company';

export const VISIBILITIES: { label: string; value: SharedVisibility }[] = [
  {
    label: 'Pubblica',
    value: 'public',
  },
  {
    label: 'Utenti autorizzati',
    value: 'loggedin',
  },
  {
    label: 'Utenti della compagnia',
    value: 'company',
  },
];

export interface ICreateSharedConversationDTO {
  conversationId: string;
  title: string;
  visibility: SharedVisibility;
  enableComments: boolean;
  enableUpdate: boolean;
  enableReuse: boolean;
}

interface ISharedMessage {
  id: string;
  createdAt: string;
  query: string;
  answer: string;
  vendor: string | null;
}

interface IShareAssistant {
  id: string;
  name: string;
  avatar: string;
  welcomeMessage: string;
}

class ShareAssistant {
  id: string = '';
  name: string = '';
  avatar: string = '';
  welcomeMessage: string = '';

  constructor(res?: IShareAssistant) {
    if (res) {
      this.id = res.id;
      this.name = res.name;
      this.avatar = res.avatar;
      this.welcomeMessage = res.welcomeMessage;
    }
  }
}

class SharedMessage {
  id: string = '';
  createdAt: DateTime = DateTime.now();
  query: string = '';
  answer: string = '';
  vendor: string | null = null;

  constructor(res?: ISharedMessage) {
    if (res) {
      this.id = res.id;
      this.createdAt = DateTime.fromISO(res.createdAt);
      this.query = res.query;
      this.answer = res.answer;
      this.vendor = res.vendor;
    }
  }
}

export interface IShareUser {
  id: string;
  name: string;
  surname: string;
}

export class ShareUser {
  id: string = '';
  fullName: string = '';

  constructor(res?: IShareUser) {
    if (res) {
      this.id = res.id;
      this.fullName = `${res.name} ${res.surname}`;
    }
  }
}

export interface IShare {
  id: string;
  conversationId: string;
  enableComments: boolean;
  enableReuse: boolean;
  enableUpdate: boolean;
  createdAt: string;
  title: string;
  views: number;
  visibility: SharedVisibility;
  assistant: IShareAssistant;
  user: IShareUser;
  messages: ISharedMessage[] | null;
}

export class Share {
  id: string = '';
  conversationId: string = '';
  enableComments: boolean = false;
  enableReuse: boolean = false;
  enableUpdate: boolean = false;
  createdAt: DateTime = DateTime.now();
  title: string = '';
  views: number = 0;
  visibility?: SharedVisibility;
  assistant?: ShareAssistant;
  user?: ShareUser;
  messages: SharedMessage[] = [];

  constructor(res?: IShare) {
    if (res) {
      this.id = res.id;
      this.conversationId = res.conversationId;
      this.enableComments = res.enableComments;
      this.enableReuse = res.enableReuse;
      this.enableUpdate = res.enableUpdate;
      this.createdAt = DateTime.fromISO(res.createdAt);
      this.title = res.title;
      this.views = res.views;
      this.visibility = res.visibility;
      this.assistant = new ShareAssistant(res.assistant);
      this.user = new ShareUser(res.user);
      if (res.messages) this.messages = this.mapMessages(res.messages);
    }
  }

  private mapMessages(array: ISharedMessage[]) {
    return array.map((m) => new SharedMessage(m));
  }
}
