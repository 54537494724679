import { MenuItem } from 'primeng/api';

export const URL_REGEXP = /https?:\/\/(?:www\.)?[\w-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/[\w-./?%&=]*)?/;

export const NOT_URL_REGEXP = /^(?!https?:\/\/)[^\s\/$.?#].[^\s]*$/;

export const SOURCES_IN_CHAT_THRESHOLD = 3;

export const POLLING_SOURCES_TIMER = 5000;

export const ACCESS_TYPES: { label: string; value: string; description: string }[] = [
  {
    label: 'private.sourceDetailsPage.accessType.public',
    value: 'PUBLIC',
    description: 'private.sourceDetailsPage.accessType.publicDescription',
  },
  {
    label: 'private.sourceDetailsPage.accessType.private',
    value: 'PRIVATE',
    description: 'private.sourceDetailsPage.accessType.privateDescription',
  },
];
