import { Component } from '@angular/core';
import { AssistantCreationService } from 'src/app/main/services/assistant-creation.service';
import { CHARACTERS } from 'src/app/main/shared/constants/assistant-creation-wizard/characters.const';
import { AssistantCreationWizard } from 'src/app/main/shared/models/assistant-creation.model';

@Component({
  selector: 'app-character',
  templateUrl: './character.component.html',
  styleUrls: ['./character.component.scss'],
})
export class CharacterComponent {
  creationWizard: AssistantCreationWizard = new AssistantCreationWizard();

  readonly characters = CHARACTERS;

  constructor(private readonly assistantCreationService: AssistantCreationService) {
    this.assistantCreationService.getAssistantCreationWizard$().subscribe({
      next: (a) => (this.creationWizard = a),
    });
  }

  nextStep() {
    this.assistantCreationService.setAssistantCreationWizard(this.creationWizard);
    this.assistantCreationService.setNextStep(true);
  }
}
