import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

interface IWithOrder {
  order: number;
}

@Pipe({
  name: 'sortKeyvalue',
})
export class SortKeyvaluePipe implements PipeTransform {
  transform<K extends string, V extends IWithOrder>(value: Array<KeyValue<K, V>>): Array<KeyValue<string, V>> {
    return value.sort((a, b) => a.value.order - b.value.order);
  }
}
