import { Component } from '@angular/core';

@Component({
  selector: 'app-add-dropbox-tab',
  templateUrl: './add-dropbox-tab.component.html',
  styleUrls: ['./add-dropbox-tab.component.scss']
})
export class AddDropboxTabComponent {

}
