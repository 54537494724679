import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { cloneDeep, uniqBy } from 'lodash';
import { TreeNode } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, Subscription, catchError, debounceTime, filter, finalize, switchMap, tap, throwError } from 'rxjs';
import { BotsService } from 'src/app/main/services/bots.service';
import { GoogleDriveService } from 'src/app/main/services/google-drive.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { SsoService } from 'src/app/main/services/sso.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { IDriveFiles, IDriveItemData } from 'src/app/main/shared/models/google-drive.model';

@Component({
  selector: 'app-add-drive-tab',
  templateUrl: './add-drive-tab.component.html',
  styleUrls: ['./add-drive-tab.component.scss'],
})
export class AddDriveTabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() active: boolean = false;

  private readonly searchSubject$: Subject<string> = new Subject<string>();
  private readonly interruptSearch$: Subject<any> = new Subject();

  getDriveFilesUnsubscribe$?: Subscription;
  driveLodingUnsubscribe$?: Subscription;

  isDriveInitialized: boolean = false;
  loading: boolean = true;
  isPaginating: boolean = false;
  isProcessing: boolean = false;
  isLoggingOut: boolean = false;
  isFiltered: boolean = false;
  isSearching: boolean = false;
  isSessionActive: boolean = true;

  pageSize: number = 10;
  nextPageToken?: string = undefined;

  driveFiles: IDriveFiles<IDriveItemData>[] = [];
  selectedDriveFiles: IDriveFiles<IDriveItemData>[] = [];

  currentBotId?: string = '';
  filters: { search: string; orderBy: string; desc: boolean } = { search: '', orderBy: '', desc: false };
  orderByOptions: { label: string; value: string }[] = [
    { label: 'private.knowledgePage.addSourceModal.drive.orderFields.name', value: 'name' },
    { label: 'private.knowledgePage.addSourceModal.drive.orderFields.creationDate', value: 'createdTime' },
    { label: 'private.knowledgePage.addSourceModal.drive.orderFields.updateDate', value: 'modifiedTime' },
    { label: 'private.knowledgePage.addSourceModal.drive.orderFields.recent', value: 'recency' },
  ];

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly ssoService: SsoService,
    private readonly botsService: BotsService,
    private readonly toastService: ToastService,
    private readonly googleDriveService: GoogleDriveService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.googleDriveService.loading$.subscribe({
      next: (v) => (this.loading = v),
    });

    this.currentBotId = this.botsService.getCurrentBot()?.id;

    this.driveLodingUnsubscribe$ = this.googleDriveService.driveLogin$$
      .pipe(
        filter((isInitialized) => isInitialized),
        switchMap(() => this.getDriveFiles())
      )
      .subscribe();

    googleDriveService.orderBy$.subscribe({
      next: (orderBy) => {
        if (orderBy) {
          this.filters.orderBy = orderBy;
        }
      },
    });

    this.searchSubject$
      .pipe(
        debounceTime(300),
        switchMap(() => this.getDriveFiles())
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.initGoogleSSO();
  }

  ngOnDestroy(): void {
    this.getDriveFilesUnsubscribe$?.unsubscribe();
    this.driveLodingUnsubscribe$?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['active'] && changes['active'].currentValue) {
      this.getDriveFilesUnsubscribe$ = this.getDriveFiles().subscribe();
    }
  }

  onSortChange(nextPageToken?: string) {
    this.getDriveFiles(nextPageToken).subscribe({});
  }

  getDriveFiles(nextPageToken?: string) {
    if (nextPageToken) this.isPaginating = true;
    else {
      this.isSearching = true;
      this.selectedDriveFiles = [];
    }
    if (this.filters.search) this.isFiltered = true;
    return this.googleDriveService.getDriveFiles$(nextPageToken, this.filters).pipe(
      finalize(() => {
        this.googleDriveService.loading$$ = false;
        this.isPaginating = false;
        this.isSearching = false;
      }),
      tap((v) => {
        this.googleDriveService.orderBy$$ = this.filters.orderBy;
        if (nextPageToken) {
          this.pageSize += v.pageSize;
          this.driveFiles = this.driveFiles.concat(v.files);
        } else {
          this.pageSize = v.pageSize;
          this.driveFiles = v.files;
        }
        this.nextPageToken = v.nextPageToken;
        this.isSessionActive = true;
      }),
      catchError((e) => {
        this.isSessionActive = false;
        return throwError(() => e);
      })
    );
  }

  initGoogleSSO() {
    this.ssoService.initGoogleSSO('https://www.googleapis.com/auth/drive.readonly', false);
  }

  onButtonClick() {
    this.ssoService.googleClient?.requestCode();
  }

  getSingleItem(clickedNode: TreeNode<IDriveItemData>) {
    const selectedNode = this.selectedDriveFiles.find((file) => file.key === clickedNode.key);
    if (clickedNode.children) return;
    if (clickedNode.data && clickedNode.data.dataId) {
      // clickedNode.loading = true;
      this.isPaginating = true;
      this.googleDriveService.getDriveFilesInFolder$(clickedNode.data.dataId, clickedNode.key).subscribe({
        next: (v) => {
          clickedNode.children = v.files;
          if (selectedNode) selectedNode.children = v.files;
          if (this.selectedDriveFiles.some((file) => file.key === clickedNode.key))
            this.selectedDriveFiles = uniqBy([...this.selectedDriveFiles, ...v.files], 'key');
          // clickedNode.loading = false;
          this.isPaginating = false;
        },
        error: (e) => {
          this.isSessionActive = false;
        },
      });
    }
  }

  saveFiles() {
    this.isProcessing = true;
    const selectedFilesCopy = cloneDeep(this.selectedDriveFiles);
    selectedFilesCopy.forEach((file) => {
      if (file.children?.length) {
        const allChildrenSelected = file.children.every((child) => selectedFilesCopy.some((selectedFile) => selectedFile.key === child.key));
        if (allChildrenSelected) selectedFilesCopy.splice(selectedFilesCopy.indexOf(file), 1);
      }
    });
    const files = selectedFilesCopy.map((file) => ({
      dataId: file.data?.dataId,
      mimeType: file.data?.mimeType,
      type: file.data?.type,
      name: file.name,
      fileExtension: file.data?.fileExtension,
    }));
    const data = { files, chatBotId: this.currentBotId };
    this.googleDriveService.processData$(data).subscribe({
      next: () => {
        this.isProcessing = false;
        this.selectedDriveFiles = [];
        this.ref.close({ added: true, error: undefined });
        console.log('close');
      },
      error: (e) => {
        this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: e.message });
        this.isProcessing = false;
        this.isSessionActive = false;
      },
    });
  }

  openFile(node: IDriveFiles<IDriveItemData>) {
    window.open(node.data?.webViewLink, '_blank');
  }

  logoutDrive() {
    this.isLoggingOut = true;
    this.googleDriveService.logoutDrive$().subscribe({
      next: () => {
        this.isSessionActive = false;
        this.isLoggingOut = false;
      },
    });
  }

  searchChanged() {
    this.isFiltered = false;
    this.interruptSearch$.next(true);
    this.searchSubject$.next(this.filters.search);
  }
}
