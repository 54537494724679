import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiKeyService } from 'src/app/main/services/api-key.service';
import { ApiKeyTypeEnum } from '../../enums/api-key.enum';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-create-api-key-modal',
  templateUrl: './create-api-key-modal.component.html',
  styleUrl: './create-api-key-modal.component.scss',
})
export class CreateApiKeyModalComponent {
  assistantId: string = '';
  companyId: string = '';
  createKeyForm: FormGroup;

  isCreating: boolean = false;

  constructor(
    private readonly config: DynamicDialogConfig,
    private readonly ref: DynamicDialogRef,
    private readonly fb: FormBuilder,
    private readonly apiKeyService: ApiKeyService
  ) {
    this.assistantId = this.config.data.assistantId;
    this.companyId = this.config.data.companyId;

    this.createKeyForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
    });
  }

  createKey() {
    const body = this.createKeyForm.getRawValue();

    this.isCreating = true;

    this.apiKeyService
      .createApiKey$(this.companyId, {
        type: ApiKeyTypeEnum.ASSISTANT,
        assistantId: this.assistantId,
        name: body.name,
        config: {
          showCloseButtons: true,
          showNewConversationButton: true,
          forceFullMode: false,
          privacyPolicyURL: '',
          termsOfServiceURL: '',
          maxMessagePerConversation: 0,
          tooManyMessageErrorText: '',
        },
      })
      .pipe(finalize(() => (this.isCreating = false)))
      .subscribe({
        next: (v) => {
          this.ref.close({ created: true, id: v.id });
        },
      });
  }
}
