import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recapWizardHeader',
})
export class RecapWizardHeaderPipe implements PipeTransform {
  transform(value: string): string {
    const splitString = value
      .split(/(?=[A-Z])/)
      .join(' ')
      .toUpperCase();

    return splitString;
  }
}
