<p-confirmDialog></p-confirmDialog>

<div class="mt-3">
  <div class="mb-5">
    <div class="w-full flex md:flex-row flex-column justify-content-between gap-3">
      <div class="flex flex-column gap-2 pt-3 md:w-4 w-full">
        <label htmlFor="name">{{ 'private.knowledgePage.filters.name.label' | translate }}</label>
        <input pInputText class="w-full" [(ngModel)]="filterName" (ngModelChange)="onFilterNameChange()" />
      </div>
      <div class="md:w-6 w-full flex align-items-end justify-content-end">
        <span class="flex flex-row justify-content-end align-items-stretch gap-1 w-full">
          @if (selectedSources && selectedSources.length) {
          <div class="col-4 p-0 flex align-items-stretch">
            <button
              pButton
              pRipple
              class="p-button-warning p-button-sm p-2 w-full"
              [label]="'private.knowledgePage.tagsAction' | translate"
              icon="pi pi-tag"
              (click)="$event.stopPropagation(); openTagsMassiveActions()"
            ></button>
          </div>
          <div class="col-4 p-0 flex align-items-stretch">
            <button
              pButton
              pRipple
              class="p-button-danger p-button-sm p-2 w-full"
              [label]="'private.knowledgePage.deleteAll' | translate"
              icon="pi pi-trash"
              (click)="$event.stopPropagation(); deleteAllSourcesConfirm()"
            ></button>
          </div>
          }
          <div class="col-4 p-0 flex align-items-stretch">
            <button
              pButton
              pRipple
              class="p-button-sm p-2 w-full"
              [label]="'private.knowledgePage.addSource' | translate"
              icon="pi pi-plus"
              (click)="$event.stopPropagation(); openAddModal()"
            ></button>
          </div>
        </span>
      </div>
    </div>
    <div class="mt-4">
      <div class="flex flex-row align-items-center gap-2 flex-wrap">
        @if (sourceUrls.length) { @for (tag of availableTags | slice:0:!showAllTags && availableTags.length > 3 ? 3 : undefined; track $index) {
        <p-tag
          class="source-tag cursor-pointer flex"
          (click)="toggleTag(tag)"
          [style]="{ background: '#' + tag.color + (!getIsTagInFilter(tag) ? '50' : '') }"
        >
          <span class="flex gap-2 align-items-center">
            <p class="my-0" style="font-size: 0.6rem">{{ tag.label | translate }}</p>
          </span>
        </p-tag>
        } @if (availableTags.length > 3 && !showAllTags) {
        <div
          class="bg-gray-300 hover:bg-gray-500 transition-all transition-duration-200 flex justify-content-center align-items-center py-1 px-2 cursor-pointer border-round-2xl"
          (click)="showAllTags = !showAllTags"
        >
          <i class="pi pi-ellipsis-h text-gray-700 hover:text-white transition-all transition-duration-200 flex justify-content-center"></i>
        </div>
        } }
      </div>
    </div>
  </div>
  <p-table
    #sourceTable
    [value]="filteredSourceUrls.length || filterTags.length ? filteredSourceUrls : sourceUrls"
    [loading]="tableLoading"
    [paginator]="true"
    [(selection)]="selectedSources"
    [rowsPerPageOptions]="rowsPerPage"
    [first]="page"
    [rows]="items"
    (onPage)="onPageChange($event)"
    (rowsChange)="onRowsChange()"
    [tableStyle]="{ 'min-height': '10rem' }"
    styleClass="p-datatable-gridlines"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem">
          <div class="flex justify-content-center">
            <p-tableHeaderCheckbox />
          </div>
        </th>
        <th>{{ 'private.knowledgePage.table.headers.name' | translate }}</th>
        <th>{{ 'private.knowledgePage.table.headers.details' | translate }}</th>
        <th>{{ 'private.knowledgePage.table.headers.info' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-source let-editing="editing">
      <tr (click)="goToDetailsSource(source.id)" class="cursor-pointer table-row">
        <td class="p-0" (click)="$event.stopPropagation()">
          <div class="flex justify-content-center">
            <p-tableCheckbox [id]="'checkbox-' + source.id" [value]="source" (click)="$event.stopPropagation()" />
          </div>
        </td>
        <td>
          <div class="flex flex-row gap-2 align-items-center">
            <custom-icon
              [type]="'material'"
              [iconName]="(source | crawlingMethod).icon"
              [pTooltip]="(source | crawlingMethod).crawlingMethod! | translate | uppercase"
              [class]="'flex cursor-default'"
            ></custom-icon>
            {{ source.description }}
          </div>
          @if (source.tags.length) {
          <div class="flex mt-3">
            <div class="flex flex-row flex-wrap gap-2">
              @for (tag of source.tags; track $index) {
              <p-tag [value]="tag.label" [style]="{ background: '#' + tag.color, 'font-size': '0.6rem' }"></p-tag>
              }
            </div>
          </div>
          }
        </td>
        <td>
          <div class="flex flex-column justify-content-center">
            <span class="flex flex-column">
              @if (source.fileExtension === 'PDF' || source.type === 'URL') {
              <p class="my-0 text-center">
                {{ (source.type === 'FILE' ? 'private.knowledgePage.table.pageCount' : 'private.knowledgePage.table.linksCount') | translate }}
                {{ source.pageCount || '-' }}
              </p>
              } @if (source.info) {
              <span class="flex justify-content-center">
                (
                <p class="my-0 w-fit font-semibold completed cursor-default" pTooltip="Completed" tooltipPosition="top">
                  {{ source.info.completed }},
                </p>
                <p class="my-0 w-fit font-semibold inprogress cursor-default" pTooltip="In progress" tooltipPosition="top">
                  {{ source.info.inProgress }},
                </p>
                <p class="my-0 w-fit font-semibold pending cursor-default" pTooltip="Pending" tooltipPosition="top">
                  {{ source.info.notInitiated }}
                </p>
                )
              </span>
              }
            </span>
            @if (source.permanent) {
            <p-divider class="my-0"></p-divider>
            <span class="flex gap-3 align-items-center justify-content-center">
              <p class="w-fit my-0">{{ 'private.knowledgePage.permanentSource' | translate }}</p>
              <custom-icon
                class="flex justify-content-center cursor-default text-green-400"
                [type]="'material'"
                iconName="check_circle"
              ></custom-icon>
            </span>
            } @if((source.trainingStatus | trainingStatus).severity !== 'success') {

            <p-divider class="my-0"></p-divider>
            <div class="flex justify-content-center">
              <p-tag
                [value]="(source.trainingStatus | trainingStatus).label | translate"
                [severity]="(source.trainingStatus | trainingStatus).severity"
              ></p-tag>
            </div>
            }
          </div>
        </td>
        <td>
          <div class="flex flex-column gap-2">
            @if (getAccessType(source.dataVisibilities)) {
            <p class="mb-0">
              <span class="font-semibold">{{ 'private.knowledgePage.table.headers.access' | translate }}:</span>
              {{ getAccessType(source.dataVisibilities) || '' | translate }}
            </p>
            } @if (source.validityFrom || source.validityTo) {
            <div>
              <p class="mb-0">
                <span class="font-semibold">{{ 'private.knowledgePage.table.headers.validityPeriod' | translate }}:</span>
              </p>
              <p class="mb-0">{{ source.validityFrom | date : 'longDate' }} -{{ source.validityTo | date : 'longDate' }}</p>
            </div>
            } @if (source.updatePeriod) {
            <p>
              <span class="font-semibold">{{ 'private.knowledgePage.table.headers.refreshPeriod' | translate }}:</span>
              {{
                source.updatePeriod
                  ? source.updatePeriod + ' ' + (source.updatePeriod > 1 ? ('common.days.plural' | translate) : ('common.days.singular' | translate))
                  : '-'
              }}
            </p>
            } @if(!getAccessType(source.dataVisibilities) && !source.validityFrom && !source.validityTo && !source.updatePeriod) {
            <p class="mb-0">{{ 'private.knowledgePage.noInfo' | translate }}</p>
            }
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="999" class="p-4 text-center font-semibold font-italic">
          {{ 'private.knowledgePage.table.noSources' | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
