import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BOT_MODES } from '../../constants/bot.consts';
import { ChatBot } from '../../models/chat-bot.model';
import { ImageUuidService } from 'src/app/main/services/image-uuid.service';
import { AiModelsService } from 'src/app/main/services/ai-models.service';

@Component({
  selector: 'app-bot-details-modal',
  templateUrl: './bot-details-modal.component.html',
  styleUrls: ['./bot-details-modal.component.scss'],
})
export class BotDetailsModalComponent {
  bot?: ChatBot;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    private readonly aiModelService: AiModelsService,
    public readonly imageUuidService: ImageUuidService
  ) {
    this.bot = this.config.data.bot;
  }

  closeModalAndStart() {
    this.ref.close(true);
  }

  getBotMode() {
    return BOT_MODES.find((b) => this.bot?.replyMode === b.value)?.name;
  }

  getBotModel() {
    return this.aiModelService.aiModels.find((b) => this.bot?.model === b.internalId)?.name;
  }
}
