import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Subject, map } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { IDriveItemData, IDriveItems } from '../shared/models/google-drive.model';
import { Store } from '@ngrx/store';
import { IDriveStoreState } from 'src/app/core/store/drive/drive.reducer';
import { selectOrderBy } from 'src/app/core/store/drive/drive.selectors';
import { setOrderByAction } from 'src/app/core/store/drive/drive.actions';

@Injectable({
  providedIn: 'root',
})
export class GoogleDriveService {
  private readonly _orderBy$ = this.store.select(selectOrderBy);
  private readonly _driveLogin$: Subject<boolean> = new Subject();
  private readonly _loading$: Subject<boolean> = new Subject();

  constructor(private readonly apiService: ApiService, private readonly store: Store<{ drive: IDriveStoreState }>) {}

  get driveLogin$$() {
    return this._driveLogin$;
    // return this._driveLogin$.asObservable();
  }

  set driveLogin$(value: boolean) {
    this._driveLogin$.next(value);
  }

  get loading$() {
    return this._loading$.asObservable();
  }

  set loading$$(value: boolean) {
    this._loading$.next(value);
  }

  get orderBy$() {
    return this._orderBy$;
  }

  set orderBy$$(orderBy: string) {
    this.store.dispatch(setOrderByAction({ orderBy }));
  }

  registerDrive$(code: string) {
    return this.apiService.post(`google-api/auth/drive`, { code });
  }

  logoutDrive$() {
    return this.apiService.delete(`google-api/drive/logout`);
  }

  getDriveFiles$(nextPageToken?: string, filters?: { search: string; orderBy: string; desc: boolean }) {
    const queryParams = [];
    if (nextPageToken) queryParams.push(`nextPageToken=${nextPageToken}`);
    if (filters?.search) queryParams.push(`name=${filters.search}`);
    if (filters?.orderBy) queryParams.push(`orderBy=${filters.orderBy}`);
    if (filters?.desc) queryParams.push(`order=${filters.desc ? 'desc' : 'asc'}`);
    let qs = '';
    if (queryParams.length) qs = `?${queryParams.join('&')}`;
    return this.apiService.get<IDriveItems>(`google-api/drive/list-content${qs}`).pipe(map((res) => this.mapDriveFiles(res)));
  }

  getDriveFilesInFolder$(folderId: string, folderKey?: string) {
    return this.apiService
      .get<IDriveItems>(`google-api/drive/list-content?driveId=${folderId}`)
      .pipe(map((res) => this.mapDriveFiles(res, true, folderKey)));
  }

  mapDriveFiles(res: IDriveItems, subItem: boolean = false, folderKey?: string) {
    res.files.forEach((file) => {
      if (folderKey) file.key = `${folderKey}-${file.key}`;
      file.leaf = file.data?.type === 'file';
    });
    return res;
  }

  processData$(body: {
    files: { dataId?: string; mimeType?: string; type?: 'file' | 'folder'; label?: string; fileExtension?: string }[];
    chatBotId?: string;
  }) {
    return this.apiService.post(`google-api/drive/process-content`, body);
  }
}
