<form class="mt-4 flex flex-column gap-5" [formGroup]="createPipelineForm" (ngSubmit)="createPipeline()">
  <span class="w-full">
    <p for="name" class="mb-1">{{ 'private.pipelinesPage.createModal.name' | translate }}</p>
    <input pInputText id="name" class="w-full" formControlName="name" />
  </span>
  <div class="flex justify-content-end">
    <button
      pButton
      type="submit"
      [label]="'private.pipelinesPage.createModal.create' | translate"
      [disabled]="createPipelineForm.invalid"
      [loading]="isCreating"
    ></button>
  </div>
</form>
