import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of, Subject, tap } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { IImageAi, ImageAi, ImageGenerationOptions, ImagePrompt } from '../shared/models/gallery.model';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  private imageGenerationCounter: number = 0;
  /**
   * This subject emits when the images are generated
   */
  private imagesGeneratedSubject = new Subject<any>();
  imagesGenerated$ = this.imagesGeneratedSubject.asObservable();

  constructor(private readonly apiService: ApiService, private readonly http: HttpClient, private readonly cacheService: CacheService) {}

  startedImageCreation$ = new Subject<ImageAi[]>();
  imageCreated$ = new Subject<{ images: ImageAi[]; skeletonImages: ImageAi[] }>();

  /**
   * Retrieves images for exploration, with optional caching.
   * @param quality The quality of the images to retrieve ('O', 'T', or 'F').
   * @param refresh Whether to force a refresh of the cache.
   * @returns An Observable of ImageAi[].
   */
  getImagesExplore(
    quality: 'O' | 'T' | 'F' = 'T',
    items: number = 100,
    page: number = 1,
    style: string = '',
    visibility: string = 'PUBLIC',
    refresh: boolean = false,
    userId: string = ''
  ) {
    let url = `images?style=${style}&quality=${quality}&items=${items}&page=${page}&visibility=${visibility}&group=true`;
    if (userId) {
      url += `&userId=${userId}`;
    }
    const cachedImages = !refresh && this.cacheService.get(url);
    if (cachedImages) {
      this.emitImagesGenerated();
      return of(cachedImages);
    }

    return this.apiService.get(url).pipe(
      map((response: any) => ({
        items: response.items ? response.items : response,
        total: response.total ? response.total : response.length,
      })),
      map((value) => ({
        items: value.items.map((image: IImageAi) => new ImageAi(image)),
        total: value.total,
      })),
      tap((images) => {
        this.cacheService.set(url, images, 3600000); // Cache for 1 hour
        this.emitImagesGenerated();
      })
    );
  }

  /**
   * Retrieves images for exploration, with optional caching.
   * @param quality The quality of the images to retrieve ('O', 'T', or 'F').
   * @param refresh Whether to force a refresh of the cache.
   * @returns An Observable of ImageAi[].
   */
  getMyImagesGallery(quality: 'O' | 'T' | 'F' = 'T', items: number = 100, page: number = 1, style: string = '', refresh: boolean = false) {
    const url = `images/mine?&style=${style}&quality=${quality}&items=${items}&page=${page}&group=true`;
    const cachedImages = !refresh && this.cacheService.get(url);
    if (cachedImages) {
      this.emitImagesGenerated();
      return of(cachedImages);
    }

    return this.apiService.get(url).pipe(
      map((response: any) => ({
        items: response.items ? response.items : response,
        total: response.total ? response.total : response.length,
      })),
      map((value) => ({
        items: value.items.map((image: IImageAi) => new ImageAi(image)),
        total: value.total,
      })),
      tap((images) => {
        this.cacheService.set(url, images, 3600000); // Cache for 1 hour
        this.emitImagesGenerated();
      })
    );
  }

  /**
   * Creates an AI-generated image based on the provided prompt.
   * @param imagePrompt The prompt and configuration for image generation.
   * @returns An Observable of the created images and their skeleton versions.
   */
  createImageAi(imagePrompt: ImagePrompt) {
    const skeletonImages: ImageAi[] = this.getSkeletonImages(imagePrompt);

    this.startedImageCreation$.next(skeletonImages);
    this.emitImagesGenerated();
    console.log(imagePrompt.prepareForCreate());

    return this.apiService.post('images', imagePrompt.prepareForCreate()).pipe(
      map((response: any) => ({
        items: response.items ? response.items : response,
        total: response.total ? response.total : response.length,
      })),
      map((value) => value.items as IImageAi[]),
      map((images) => images.map((image) => new ImageAi(image))),
      tap((images: ImageAi[]) => {
        this.imageCreated$.next({ images, skeletonImages });
        this.emitImagesGenerated();
      }),
      catchError((error: any) => {
        this.startedImageCreation$.error(error);
        return of(error);
      })
    );
  }

  /**
   * Retrieves a specific AI-generated image by its ID.
   * @param id The ID of the image to retrieve.
   * @param quality The quality of the image to retrieve ('O', 'T', or 'F').
   * @returns An Observable of the retrieved ImageAi.
   */
  getImageAi(id: string, quality: 'O' | 'T' | 'F' = 'O') {
    return this.apiService.get(`images/${id}?quality=${quality}`).pipe(
      map((value) => value as IImageAi),
      map((image) => new ImageAi(image))
    );
  }

  /**
   * Retrieves all images associated with a specific group ID.
   * @param groupId The ID of the group to retrieve images for.
   * @param quality The quality of the images to retrieve ('O', 'T', or 'F').
   * @returns An Observable of ImageAi[].
   */
  getImagesByGroupId(groupId: string, quality: 'O' | 'T' | 'F' = 'O') {
    return this.apiService.get(`images?imageGroupId=${groupId}&quality=${quality}`).pipe(
      map((response: any) => ({
        items: response.items ? response.items.map((image: IImageAi) => new ImageAi(image)) : [],
        total: response.total ? response.total : 0,
      }))
    );
  }

  /**
   * Initiates the download of a specific image.
   * @param imageId The ID of the image to download.
   * @returns An Observable that completes when the download is initiated.
   */
  downloadImage(imageId: string) {
    return this.getImageAi(imageId, 'F').pipe(tap((image) => new ImageAi(image).downloadImage()));
  }

  /**
   * Creates skeleton images based on the provided image prompt.
   * @param imagePrompt The prompt and configuration for image generation.
   * @returns An array of skeleton ImageAi objects.
   * @private
   */
  private getSkeletonImages(imagePrompt: ImagePrompt): ImageAi[] {
    const images: ImageAi[] = [];
    for (let index = 0; index < imagePrompt.quantity; index++) {
      const image = new ImageAi({
        id: `fake-${this.imageGenerationCounter++}`,
        imageGenerationOptions: new ImageGenerationOptions({
          prompt: imagePrompt.config.prompt,
          aspectRatio: imagePrompt.config.aspectRatio,
          imageStyle: imagePrompt.config.imageStyle,
          modelId: imagePrompt.config.modelId,
          negativePrompt: imagePrompt.config.negativePrompt,
          vendor: imagePrompt.config.vendor,
        }),
      });
      image.skeleton = true;
      images.push(image);
    }
    return images;
  }

  /**
   * Emits an event to notify that images have been generated.
   */
  public emitImagesGenerated(): void {
    this.imagesGeneratedSubject.next(null);
  }
}
