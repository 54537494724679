export interface IKbTag {
  id: string;
  label: string;
  color: string;
}

export interface IFilterKbTag extends IKbTag {
  sourcesCount: number;
}

export class KbTag {
  id: string = '';
  label: string = '';
  color: string = '';

  constructor(res?: IKbTag) {
    if (res) {
      this.id = res.id;
      this.label = res.label;
      this.color = res.color;
    }
  }
}

export class FilterKbTag extends KbTag {
  sourcesCount: number = 0;

  constructor(res?: IFilterKbTag) {
    super(res);
    if (res) this.sourcesCount = res?.sourcesCount;
  }
}

export interface ICreateTagDTO {
  label: string;
  color: string;
}
