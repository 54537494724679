export interface IDataVisibilityUser {
  id?: string;
  name?: string;
  surname?: string;
  email?: string;
}

export class DataVisibilityUser {
  id: string = '';
  name: string = '';
  surname: string = '';
  email: string = '';

  constructor(res?: IDataVisibilityUser) {
    if (res) {
      if (res.id) this.id = res.id;
      if (res.name) this.name = res.name;
      if (res.surname) this.surname = res.surname;
      if (res.email) this.email = res.email;
    }
  }
}

export interface IDataVisibilityGroup {
  id?: string;
  name?: string;
  companyId?: string;
}

export class DataVisibilityGroup {
  id: string = '';
  name: string = '';
  companyId: string = '';

  constructor(res?: IDataVisibilityGroup) {
    if (res) {
      if (res.id) this.id = res.id;
      if (res.name) this.name = res.name;
      if (res.companyId) this.companyId = res.companyId;
    }
  }
}

export interface IDataVisibility {
  id: string;
  enabled: boolean;
  user: IDataVisibilityUser;
  group: IDataVisibilityGroup;
}

export class DataVisibility {
  id: string = '';
  enabled: boolean = false;
  user: DataVisibilityUser = new DataVisibilityUser();
  group: DataVisibilityGroup = new DataVisibilityGroup();

  constructor(res?: IDataVisibility) {
    if (res) {
      this.id = res.id;
      this.enabled = res.enabled;
      if (res.user.id) this.user = new DataVisibilityUser(res.user);
      if (res.group.id) this.group = new DataVisibilityGroup(res.group);
    }
  }
}
