<p-confirmDialog></p-confirmDialog>

<form class="mt-5 flex flex-column gap-5" [formGroup]="createGroupForm" (ngSubmit)="createGroup()">
  <p-floatLabel>
    <input id="name" pInputText formControlName="name" class="md:w-6 w-full" />
    <label for="name">{{ 'private.accountPage.groupsTab.addGroupModal.name' | translate }}</label>
  </p-floatLabel>

  <p-floatLabel>
    <p-multiSelect
      #usersMultiselect
      id="userIds"
      [options]="users"
      formControlName="userIds"
      optionLabel="fullName"
      optionValue="id"
      appendTo="body"
      styleClass="md:w-6 w-full"
      [loading]="loadingUsers"
      [autofocus]="multiselectHasFocus"
    ></p-multiSelect>
    <label for="userIds">{{ 'private.accountPage.groupsTab.addGroupModal.users' | translate }}</label>
  </p-floatLabel>

  <div class="w-full flex justify-content-end gap-3">
    @if (currentGroup) {
    <button
      pButton
      type="button"
      class="p-button-outlined p-button-danger"
      icon="pi pi-trash"
      [label]="'private.accountPage.groupsTab.addGroupModal.delete' | translate"
      (click)="deleteGroup()"
    ></button>
    }
    <button
      pButton
      type="submit"
      [label]="
        currentGroup
          ? ('private.accountPage.groupsTab.addGroupModal.edit' | translate)
          : ('private.accountPage.groupsTab.addGroupModal.create' | translate)
      "
      [disabled]="createGroupForm.invalid"
      [loading]="loading"
    ></button>
  </div>
</form>
