import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IInfiniteScrollEvent } from 'ngx-infinite-scroll';
import { ConfirmationService, PrimeIcons } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Inplace } from 'primeng/inplace';
import { combineLatest, finalize } from 'rxjs';
import { Conversation, IConversation } from 'src/app/main/private/chat/api/conversation.model';
import { ChatService } from 'src/app/main/private/chat/service/chat.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { inOutAnimation } from '../../animations/buttons-appearance.anim';
import { ChatBot } from '../../models/chat-bot.model';
import { ConfigureChatModalComponent } from '../configure-chat-modal/configure-chat-modal.component';
import { ShareCreationModalComponent } from '../share-creation-modal/share-creation-modal.component';

@Component({
  selector: 'app-chat-configuration-sidebar',
  templateUrl: './chat-configuration-sidebar.component.html',
  styleUrls: ['./chat-configuration-sidebar.component.scss'],
  animations: [inOutAnimation],
})
export class ChatConfigurationSidebarComponent implements OnInit, OnChanges {
  @Input() botId: string = '';
  @Input() bot!: ChatBot;
  @Input() conversations: Conversation[] = [];
  @Input() selectedConversationId?: string = '';
  @Input() isSidebarOpen: boolean = false;

  @Output() onEditedName: EventEmitter<boolean> = new EventEmitter();
  @Output() onChatDelete: EventEmitter<IConversation> = new EventEmitter();
  @Output() onHistoryClickEmitter: EventEmitter<{ conversationId: string; userId: string }> = new EventEmitter();
  @Output() onListScrolled: EventEmitter<boolean> = new EventEmitter();
  @Output() onRefresh: EventEmitter<boolean> = new EventEmitter();

  hoveredIndex: number = -1;
  activeEditingName: number = -1;

  tempNewChatName: string = '';
  loading: boolean = false;

  showSearchPopup: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly chatService: ChatService,
    private readonly toastService: ToastService,
    private readonly dialogService: DialogService,
    private readonly confirmationService: ConfirmationService,
    private readonly mainLanguageService: MainLanguageService
  ) {}

  ngOnInit() {
    combineLatest([this.route.queryParams, this.route.params]).subscribe({
      next: ([qp, p]) => {
        if (qp['cId']) {
          this.selectedConversationId = qp['cId'];
        } else if (p['conversationId'] === 'new') {
          this.selectedConversationId = undefined;
        }
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['conversations']) {
      this.loading = false;
    }
  }

  openConfigurationModal() {
    this.dialogService.open(ConfigureChatModalComponent, {
      header: 'Chat configuration',
      styleClass: 'md:w-6 w-full',
      data: {
        botId: this.botId,
      },
    });
  }

  onHistoryClick(conversation: IConversation) {
    this.onHistoryClickEmitter.emit({ conversationId: conversation.id, userId: conversation.userId });
  }

  onListThreshold(event: IInfiniteScrollEvent) {
    this.onListScrolled.emit(true);
  }

  onEditClick(event: Event, inplace: Inplace, currentValue?: string) {
    event.stopPropagation();
    if (inplace.active) {
      inplace.deactivate(event);
      this.tempNewChatName = '';
    } else {
      inplace.activate(event);
      this.tempNewChatName = currentValue || '';
    }
  }

  editChatName(event: Event, inplace: Inplace, conversation: IConversation) {
    this.loading = true;
    this.chatService
      .editChatName$(conversation, this.tempNewChatName, this.bot.type!)
      .pipe(
        finalize(() => {
          this.tempNewChatName = '';
          this.loading = false;
          this.deactivateInplace(event, inplace);
        })
      )
      .subscribe({
        next: () => {
          this.toastService.addSuccess({
            summary: this.mainLanguageService.instant('common.success'),
            detail: this.mainLanguageService.instant('private.assistantPage.nameModifiedSuccesfully'),
          });
          this.onEditedName.emit(true);
        },
        error: ({ error }) => {
          this.toastService.addError({ summary: this.mainLanguageService.instant('common.success'), detail: error.message });
        },
      });
  }

  onInplaceActivate(index: number) {
    setTimeout(() => {
      const element = document.querySelector(`input#input-inplace-${index}`) as HTMLInputElement;
      element.focus();
    }, 0);
  }

  deactivateInplace(event: Event, inplace: Inplace) {
    event.preventDefault();
    event.stopPropagation();
    inplace.deactivate(event);
  }

  deleteChat(event: Event, conversation: IConversation) {
    event.stopPropagation();
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      header: this.mainLanguageService.instant('common.warning'),
      message: this.mainLanguageService.instant('private.assistantPage.deleteChatModal.alertMessage'),
      acceptButtonStyleClass: 'p-button-danger',
      acceptIcon: PrimeIcons.TRASH,
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.loading = true;
        this.chatService
          .deleteConversation(conversation.id, conversation.chatBotId || conversation.marketplaceId || '', this.bot.type!, this.bot.name)
          .pipe(finalize(() => (this.loading = false)))
          .subscribe({
            next: () => {
              this.toastService.addSuccess({
                summary: this.mainLanguageService.instant('common.success'),
                detail: this.mainLanguageService.instant('private.assistantPage.deleteChatModal.conversationDeletedSuccesfully'),
              });
              this.onChatDelete.emit(conversation);
            },
            error: ({ error }) => {
              this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: error.message });
            },
          });
      },
    });
  }

  onRefreshClick() {
    this.onRefresh.emit(true);
  }

  onNewChatClick() {
    this.router.navigate(['/', 'private', this.bot.type === 'ASSISTANT' ? 'o' : '', 'assistants', this.botId, 'chat', 'new'].filter((v) => !!v));
  }

  openShareModal(event: Event, conversation: IConversation) {
    event.stopPropagation();
    this.dialogService.open(ShareCreationModalComponent, {
      header: 'Share this conversation',
      styleClass: 'md:w-6 w-full',
      data: {
        conversation,
      },
    });
  }
}
