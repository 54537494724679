<div class="card w-full">
  <div class="text-center">
    <h3 class="">No assistant has been created. Yet.</h3>
  </div>
  <div class="flex flex-column mt-8">
    <app-creazione (onCreateClick)="emitClick()"></app-creazione>
    <p-divider></p-divider>
    <app-addestramento></app-addestramento>
    <p-divider></p-divider>
    <app-utilizzo></app-utilizzo>
  </div>
</div>
