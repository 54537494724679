import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ICreateAssistantGroupDTO } from '../shared/models/chat-bot.model';

@Injectable({
  providedIn: 'root',
})
export class AssistantGroupsService {
  constructor(private readonly apiService: ApiService) {}

  createGroup$(body: ICreateAssistantGroupDTO) {
    return this.apiService.post<any>(`chat-groups`, body);
  }

  editGroup$(groupId: string, body: Partial<ICreateAssistantGroupDTO>) {
    return this.apiService.patch<any>(`chat-groups/${groupId}`, body);
  }

  deleteGroup$(groupId: string) {
    return this.apiService.delete(`chat-groups/${groupId}`);
  }
}
