<form [formGroup]="invitationForm" (ngSubmit)="onFormSubmit()">
  <div class="grid">
    <div class="md:col-6 col-12">
      <label for="name">{{ 'private.accountPage.usersTab.inviteUserModal.name' | translate }}</label>
      <input pInputText formControlName="name" class="w-full" />
    </div>
    <div class="md:col-6 col-12">
      <label for="surname">{{ 'private.accountPage.usersTab.inviteUserModal.surname' | translate }}</label>
      <input pInputText formControlName="surname" class="w-full" />
    </div>
    <div class="md:col-6 col-12">
      <label for="email">{{ 'private.accountPage.usersTab.inviteUserModal.email' | translate }}</label>
      <input pInputText formControlName="email" class="w-full" />
    </div>
    <div class="md:col-6 col-12">
      <label for="level">{{ 'private.accountPage.usersTab.inviteUserModal.level' | translate }}</label>
      <p-dropdown [options]="levels" formControlName="level" styleClass="w-full" appendTo="body">
        <ng-template pTemplate="item" let-item>
          {{ item.label | translate }}
        </ng-template>
        <ng-template pTemplate="selectedItem" let-item>
          {{ item.label | translate }}
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="flex justify-content-end mt-3">
    <button
      pButton
      [label]="'private.accountPage.usersTab.inviteUserModal.invite' | translate"
      [disabled]="invitationForm.invalid"
      [loading]="isLoading"
    ></button>
  </div>
</form>
