<span class="flex h-full align-items-center" *ngIf="!creationWizard.type; else stepper">
  <div class="w-full flex">
    <div class="md:col-6 col-12">
      <div class="card h-full cursor-pointer type-select-button" (click)="setType(AssistantType.INTERNAL_KB)">
        <h2 class="text-primary">Esperto di una KB Interna</h2>
        <p class="text-gray-700">
          Questo assistente è pensato per fornire supporto a personale interno su una specifica Knowledge Base e uno specifico set di informazioni.
          Dopo la configurazione è importante aggiungere documenti ed eventuali siti internet di riferimento per la permettere all’assistente di
          fornire risposte di ottima qualità.
        </p>
      </div>
    </div>
    <div class="md:col-6 col-12">
      <div class="card h-full cursor-pointer type-select-button" (click)="setType(AssistantType.COMMERCIAL)">
        <h2 class="text-primary">Commerciale sito Web</h2>
        <p class="text-gray-700">
          Questo assistente è pensato per integrarsi al tuo sito internet commerciale. L’assistente raccoglie come prima cosa i dati dell’utente
          mediante un form automatico (nome e mail required, telefono opzionale) prima di iniziare la conversazione. L’assistente può essere usato
          per: Acquisire nuovi contatti di clienti Aiutare i clienti a comprendere i servizi offerti Raccogliere le esigenze dei clienti per un
          contatto con operatore umano La conversazione con l’assistente ti verrà recapitata via mail e potrai contattare l’utente per approfondimenti
          e concludere l’acquisizione commerciale.
        </p>
      </div>
    </div>
  </div>
</span>

<ng-template #stepper>
  <span class="block h-full mt-3">
    <ng-container *ngIf="creationWizard.type === AssistantType.COMMERCIAL">
      <app-commercial style="height: 100%; display: block"></app-commercial>
    </ng-container>
    <ng-container *ngIf="creationWizard.type === AssistantType.INTERNAL_KB">
      <app-internal-kb></app-internal-kb>
    </ng-container>
  </span>
</ng-template>
