<form class="mt-4 flex flex-column gap-3" [formGroup]="createGroupForm" (ngSubmit)="createGroup()">
  <p-floatLabel>
    <input id="name" class="w-full" pInputText formControlName="name" />
    <label for="name">{{ 'private.dashboardPage.createGroupModal.groupName' | translate }}</label>
  </p-floatLabel>

  <span>
    <label for="bots">{{ 'private.dashboardPage.createGroupModal.selectBots' | translate }}</label>
    <div style="max-height: 15rem" class="overflow-y-scroll flex flex-column gap-3 mt-3 flex-wrap p-2">
      @for (bot of bots; track $index) {
      <p-checkbox [label]="bot.name" [value]="bot" [binary]="true" (onChange)="addBot($event, bot)"></p-checkbox>
      }
    </div>
  </span>

  <span>
    <p for="moderatorIds" class="mb-2">{{ 'private.dashboardPage.createGroupModal.selectModerator' | translate }}</p>
    <p-dropdown id="moderatorIds" [options]="selectedBots" optionLabel="name" formControlName="moderatorIds" styleClass="w-full" appendTo="body">
      <ng-template pTemplate="selectedItem">
        <p>{{ selectedModerator.name }}</p>
      </ng-template>
    </p-dropdown>
  </span>

  <span class="flex justify-content-end">
    <button
      type="submit"
      pButton
      [disabled]="createGroupForm.invalid"
      [label]="'private.dashboardPage.createGroupModal.create' | translate"
      [loading]="loading"
    ></button>
  </span>
</form>
