import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AIModel } from '../../models/ai-models.model';
import { AiModelsService } from 'src/app/main/services/ai-models.service';
import { getGroupedBotModels } from '../../functions/utilities.functions';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'app-ai-model-select',
  templateUrl: './ai-model-select.component.html',
  styleUrls: ['./ai-model-select.component.scss'],
})
export class AiModelSelectComponent {
  botModels: AIModel[] = [];
  loadingAssistants: boolean = false;

  @Input() parentForm: FormGroup = new FormGroup({});
  @Input() formControl: FormControl = new FormControl();

  constructor(private readonly aiModelsService: AiModelsService) {
    this.getModels();
  }
  getModels() {
    this.loadingAssistants = true;
    this.aiModelsService.getAllModels$().subscribe({
      next: (models) => {
        this.botModels = models;
      },
      complete: () => {
        this.loadingAssistants = false;
      },
    });
  }

  getValue() {
    return this.formControl.value as string;
  }

  getGroupedBotModels() {
    return getGroupedBotModels(this.botModels);
  }

  get botModelObject() {
    return this.botModels.find((c) => c.internalId === this.getValue());
  }

  patchVendor(event: DropdownChangeEvent) {
    this.parentForm.patchValue({
      vendor: this.botModels.find((model) => model.internalId === event.value)?.vendor?.internalId,
    });
  }
}
