import { Directive, ElementRef, Input, SimpleChanges, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[maskInput]',
})
export class MaskInputDirective {
  private hasToMask: boolean = false;

  constructor(private readonly elementRef: ElementRef<any>, private viewContainer: ViewContainerRef) {}
  @Input() value: string = '';
  @Input() set maskInput(toMask: boolean) {
    this.hasToMask = toMask;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.hasToMask) {
      const hiddenValue = this.value?.replaceAll(/[a-zA-Z0-9\-]/g, '*')!;
      this.elementRef.nativeElement.textContent = hiddenValue;
      if (this.elementRef.nativeElement.value) this.elementRef.nativeElement.value = hiddenValue;
    } else {
      this.elementRef.nativeElement.textContent = this.value;
      if (this.elementRef.nativeElement.value) this.elementRef.nativeElement.value = this.value;
    }
  }
}
