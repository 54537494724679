import { Injectable } from '@angular/core';
// import { PLACEHOLDER_SUBSCRIPTION, SUBSCRIPTIONS } from '../shared/constants/subscriptions.consts';
import { ApiService } from 'src/app/core/services/api.service';
import { IStripeCheckoutDTO, ISubscription, Subscription } from '../shared/models/subscription.model';
import { map } from 'rxjs';
import { SUBSCRIPTIONS } from '../shared/constants/subscriptions.consts';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  readonly subscriptions = SUBSCRIPTIONS;

  constructor(private readonly apiService: ApiService) {}

  getPersonalPlans() {
    return this.subscriptions.filter((s) => s.planType === 'PERSONAL');
  }

  getBusinessPlans() {
    return this.subscriptions.filter((s) => s.planType === 'BUSINESS');
  }

  getSubscriptions$() {
    return this.apiService.get<ISubscription[]>(`subscriptions`).pipe(map((array) => array.map((s) => new Subscription(s))));
  }

  getSubscriptionById$(subscriptionId: string) {
    return this.apiService.get<ISubscription>(`subscriptions/${subscriptionId}`).pipe(map((s) => new Subscription(s)));
  }

  checkoutStripe$(body: IStripeCheckoutDTO) {
    return this.apiService.post<{ url: string }>(`stripe/subscription/payment/checkout-session`, body);
  }
}
