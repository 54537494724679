import { Component } from '@angular/core';

@Component({
  selector: 'app-addestramento',
  templateUrl: './addestramento.component.html',
  styleUrls: ['./addestramento.component.scss']
})
export class AddestramentoComponent {

}
