import { CharacterComponent } from '../../components/assistant-creation-wizard/common/character/character.component';
import { OrganizationComponent } from '../../components/assistant-creation-wizard/common/organization/organization.component';
import { RecapComponent } from '../../components/assistant-creation-wizard/common/recap/recap.component';
import { TargetAudienceComponent } from '../../components/assistant-creation-wizard/common/target-audience/target-audience.component';
import { InternalExpertiseComponent } from '../../components/assistant-creation-wizard/internal-kb/internal-expertise/internal-expertise.component';
import { InternalReplyExtraDetailsComponent } from '../../components/assistant-creation-wizard/internal-kb/internal-reply-extra-details/internal-reply-extra-details.component';
import { InternalTargetLanguageComponent } from '../../components/assistant-creation-wizard/internal-kb/internal-target-language/internal-target-language.component';
import { AssistantWizardStepsItem } from '../../models/assistant-creation.model';

export const INTERNAL_KB_STEPS: AssistantWizardStepsItem[] = [
  {
    label: 'Character',
    pipeKey: 'character',
    component: CharacterComponent,
  },
  {
    label: 'Organization',
    pipeKey: 'organization',
    component: OrganizationComponent,
  },
  {
    label: 'Expertise',
    pipeKey: 'expertise',
    component: InternalExpertiseComponent,
  },
  {
    label: 'Target Audience',
    pipeKey: 'targetAudience',
    component: TargetAudienceComponent,
  },
  {
    label: 'Target Language',
    pipeKey: 'targetLanguage',
    component: InternalTargetLanguageComponent,
  },
  {
    label: 'Reply extra details',
    pipeKey: 'replyExtraDetails',
    component: InternalReplyExtraDetailsComponent,
  },
  {
    label: 'Recap',
    pipeKey: 'recap',
    component: RecapComponent,
  },
];
