export enum TrainingStatus {
  NOT_INITIATED = 'PENDING',
  IN_PROGRESS = 'IN PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}

export enum TrainingStatusLabel {
  'PENDING' = 'common.trainingStatus.PENDING',
  'IN PROGRESS' = 'common.trainingStatus.IN PROGRESS',
  'COMPLETED' = 'common.trainingStatus.COMPLETED',
  'ERROR' = 'common.trainingStatus.ERROR',
}

export enum TrainingStatusBadgeColor {
  'PENDING' = 'info',
  'IN PROGRESS' = 'warning',
  'COMPLETED' = 'success',
  'ERROR' = 'danger',
  NULL = 'secondary',
}
