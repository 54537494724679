import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OauthService } from 'src/app/core/services/auth/oauth.service';
import { environment } from 'src/environments/environment';
import { AppleSSOEvents } from '../shared/enums/apple-sso-events.enum';
import { AppleSSOEvent } from '../shared/models/sso.model';
import { GoogleDriveService } from './google-drive.service';
import { MainLanguageService } from './main-language.service';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  private readonly isPopupOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentLang?: string;
  // appleInitialized: boolean = false;

  googleClient?: google.accounts.oauth2.CodeClient;
  private readonly appleParams = environment.appleSSOConfig;

  hasAlreadyStartedLoginSSO: boolean = false;

  constructor(
    private readonly oauthService: OauthService,
    private readonly googleDriveService: GoogleDriveService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.mainLanguageService.defaultLanguage$.subscribe({
      next: (l) => (this.currentLang = l?.replace('-', '_')),
    });
  }

  appendAppleScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    script.src = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${this.currentLang}/appleid.auth.js`;

    document.body.appendChild(script);

    script.onload = () => this.initAppleSSO();
  }

  initAppleSSO() {
    // if (!this.appleInitialized) {
    AppleID.auth.init(this.appleParams);
    // this.appleInitialized = true;

    document.addEventListener(AppleSSOEvents.SIGN_IN_SUCCESS, (event: AppleSSOEvent) => {
      if (event.detail?.authorization && !this.hasAlreadyStartedLoginSSO) {
        this.hasAlreadyStartedLoginSSO = true;
        this.oauthService.loginSso({ authCode: event.detail.authorization.code, user: event.detail.user }, 'apple').subscribe({
          next: () => this.isPopupOpen$.next(false),
        });
      }
    });

    document.addEventListener(AppleSSOEvents.SIGN_IN_FAILURE, (event: any) => {
      console.error(event);
      this.isPopupOpen$.next(false);
    });
    // }
  }

  appendGoogleScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    script.src = 'https://accounts.google.com/gsi/client';

    document.body.appendChild(script);

    script.onload = () => this.initGoogleSSO('email openid profile', true);
  }

  initGoogleSSO(scope: string, isLogin: boolean) {
    this.googleClient = undefined;
    this.googleClient = google.accounts.oauth2.initCodeClient({
      client_id: environment.googleSSOConfig.clientId,
      scope,
      redirect_uri: environment.googleSSOConfig.redirectURI,
      ux_mode: 'popup',
      callback: (response) => {
        if (isLogin) {
          this.oauthService.loginSso({ authCode: response.code }, 'google').subscribe({
            next: () => this.isPopupOpen$.next(false),
          });
        } else {
          this.googleDriveService.loading$$ = true;
          this.googleDriveService.registerDrive$(response.code).subscribe({
            next: (v) => (this.googleDriveService.driveLogin$ = true),
            error: () => (this.googleDriveService.loading$$ = false),
          });
        }
      },
      error_callback: (error) => {
        this.isPopupOpen$.next(false);
      },
    });
  }

  appleSignIn() {
    AppleID.auth.signIn();
  }

  getIsPopupOpen$() {
    return this.isPopupOpen$.asObservable();
  }

  setIsPopupOpen$(value: boolean) {
    this.isPopupOpen$.next(value);
  }
}
