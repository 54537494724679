<!-- <div class="p-3 cursor-pointer" (click)="openAgencySelectionModal()">
  <span class="text-lg">{{ selectedAgency.name || "Seleziona un'agenzia" }}</span>
</div> -->

<div class="py-3 px-0 border-round-lg cursor-pointer" (click)="openAgencySelectionModal()">
  <p class="my-0 menu-label">
    {{ selectedAgency?.name || "Seleziona un'agenzia" }}
  </p>
</div>

<p-dialog
  header="Seleziona un'agenzia"
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
  appendTo="body"
>
  <div class="p-fluid">
    <p-listbox
      [options]="agencies"
      [(ngModel)]="selectedAgency"
      [style]="{ width: '100%' }"
      class="border-none"
      (ngModelChange)="onAgencySelected($event)"
    >
      <ng-template let-agency pTemplate="item">
        <div class="agency-item">
          <span>{{ agency.name }}</span>
        </div>
      </ng-template>
    </p-listbox>
  </div>
</p-dialog>
