import { Component, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-creazione',
  templateUrl: './creazione.component.html',
  styleUrls: ['./creazione.component.scss'],
})
export class CreazioneComponent {
  @Output() onCreateClick: EventEmitter<any> = new EventEmitter();
  readonly companyAdminLevel = environment.level.companyAdmin.level;

  onClick() {
    this.onCreateClick.emit();
  }
}
