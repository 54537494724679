export interface ILevel {
  value: number;
  literalValue: string;
  label: string;
}

export const LEVELS: ILevel[] = [
  {
    value: 100,
    literalValue: 'user',
    label: 'common.companyLevels.user',
  },
  {
    value: 500,
    literalValue: 'admin',
    label: 'common.companyLevels.companyAdmin',
  },
  {
    value: 600,
    literalValue: 'agency admin',
    label: 'common.companyLevels.agencyUser',
  },
  {
    value: 700,
    literalValue: 'agency agent',
    label: 'common.companyLevels.agencyAgent',
  },
];
