import { Component } from '@angular/core';
import { AssistantCreationService } from 'src/app/main/services/assistant-creation.service';
import { AssistantCreationWizard } from 'src/app/main/shared/models/assistant-creation.model';

@Component({
  selector: 'app-commercial-we-dont',
  templateUrl: './commercial-we-dont.component.html',
  styleUrls: ['./commercial-we-dont.component.scss'],
})
export class CommercialWeDontComponent {
  creationWizard: AssistantCreationWizard = new AssistantCreationWizard();
  newService: string = '';

  constructor(private readonly assistantCreationService: AssistantCreationService) {
    this.assistantCreationService.getAssistantCreationWizard$().subscribe({
      next: (v) => (this.creationWizard = v),
    });
  }

  setWeDont() {
    this.creationWizard.setWeDont(this.newService);
    this.newService = '';
  }

  setCreationWizard() {
    this.assistantCreationService.setAssistantCreationWizard(this.creationWizard);
    this.assistantCreationService.setNextStep(true);
  }
}
