interface IKbReferences {
  type: string;
  data: string;
}

export class KbReferences {
  type: string = '';
  data: string = '';

  constructor(res?: IKbReferences) {
    if (res) {
      this.type = res.type;
      this.data = res.data;
    }
  }
}

export interface IChatQueryMessage {
  answer: string;
  error: boolean;
  kb: boolean;
  kbReferences: IKbReferences[];
}

export class ChatQueryMessage {
  answer: string = '';
  error: boolean = false;
  kb: boolean = false;
  kbReferences: KbReferences[] = [];

  constructor(res?: IChatQueryMessage) {
    if (res) {
      this.answer = res.answer;
      this.error = res.error;
      this.kb = res.kb;
      this.kbReferences = this.mapKbReferences(res.kbReferences);
    }
  }

  private mapKbReferences(array: IKbReferences[]) {
    return array.map((ref) => new KbReferences(ref));
  }
}
