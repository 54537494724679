import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, PrimeIcons } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription, combineLatest, distinctUntilChanged, finalize } from 'rxjs';
import { Conversation, IConversation, IReaction } from 'src/app/main/private/chat/api/conversation.model';
import { ChatService } from 'src/app/main/private/chat/service/chat.service';
import { BotsService } from 'src/app/main/services/bots.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { ChatBot } from '../../models/chat-bot.model';
import { UsersService } from 'src/app/main/services/users.service';
import { User } from '../../models/user.model';
import { environment } from 'src/environments/environment';
import { ShareCreationModalComponent } from '../share-creation-modal/share-creation-modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Inplace } from 'primeng/inplace';
import { ILastAssistantChat } from 'src/app/core/store/bot';
import { ExportChatService } from 'src/app/main/services/export-chat.service';
import saveAs from 'file-saver';

@Component({
  selector: 'app-chat-topbar',
  templateUrl: './chat-topbar.component.html',
  styleUrl: './chat-topbar.component.scss',
})
export class ChatTopbarComponent implements OnDestroy {
  @ViewChild('titleInput') titleInput?: ElementRef;
  @ViewChild('commentPanel') commentPanel?: OverlayPanel;
  @ViewChild('historyMenu') historyMenu?: OverlayPanel;

  contextMenu: MenuItem[] = [];

  userSubscription?: Subscription;
  botSubscription?: Subscription;
  bot?: ChatBot;
  isGettingConversation: boolean = false;

  user?: User;

  conversationId: string = '';
  conversation?: IConversation;
  conversationTitle: string = '';
  isEditing: boolean = false;

  lastChatsSubscription?: Subscription;
  lastChats: ILastAssistantChat[] = [];

  hasReceivedFirstMessageSubscription?: Subscription;

  conversationReaction: IReaction = {
    rating: 0,
    comment: '',
  };
  isRating: boolean = false;

  isLoadingHistory: boolean = false;
  chatHistory: Conversation[] = [];

  tempNewChatName: string = '';

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly botsService: BotsService,
    private readonly chatService: ChatService,
    private readonly userService: UsersService,
    private readonly toastService: ToastService,
    private readonly dialogService: DialogService,
    private readonly exportChatService: ExportChatService,
    private readonly confirmationService: ConfirmationService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.botSubscription = combineLatest({ bot: this.botsService.currentBot$, params: this.route.params, user: this.userService.getUser$() })
      .pipe(
        distinctUntilChanged(
          (prev, curr) =>
            prev.bot?.id === curr.bot?.id && prev.params?.['conversationId'] === curr.params?.['conversationId'] && prev.user?.id === curr.user?.id
        )
      )
      .subscribe(({ bot, params, user }) => {
        this.bot = bot;
        this.user = user;
        this.contextMenu = this.buildContextMenu();
        this.resetConversation();
        if (this.conversationId !== params['conversationId'] && params['conversationId'] !== 'new') {
          this.conversationId = params['conversationId'];
          this.getConversation();
        }
      });

    this.hasReceivedFirstMessageSubscription = this.chatService.hasReceivedFirstMessage$.subscribe((hasReceivedFirstMessage) => {
      if (hasReceivedFirstMessage) {
        this.conversationId = this.route.snapshot.queryParams['cId'];
        this.isGettingConversation = true;
        setTimeout(() => {
          this.getConversation();
        }, 500);
      }
    });

    this.lastChatsSubscription = this.botsService.getLastChats$().subscribe((lastChats) => {
      this.lastChats = lastChats;
    });
  }

  ngOnDestroy(): void {
    this.botSubscription?.unsubscribe();
    this.resetConversation();
    this.isGettingConversation = false;
    this.lastChatsSubscription?.unsubscribe();
    this.hasReceivedFirstMessageSubscription?.unsubscribe();
  }

  private buildContextMenu(): MenuItem[] {
    return [
      {
        id: 'configure',
        label: 'private.assistantPage.contextMenu.configure',
        icon: 'settings',
        visible: this.bot?.type === 'ASSISTANT' && !(!this.user?.company.level || this.user?.company.level < environment.level.companyAdmin.level),
        routerLink: ['../../configuration'],
      },
      {
        id: 'export',
        label: 'private.assistantPage.contextMenu.export',
        icon: 'download',
        disabled: !this.conversationId || this.conversationId === 'new' || !this.bot?.id,
        command: (event) => {
          this.exportChatService.exportSingleChat$(this.bot!, this.conversationId, this.conversationTitle).subscribe();
        },
      },
      {
        id: 'knowledge',
        label: 'private.assistantPage.contextMenu.knowledge',
        icon: 'book_2',
        visible: this.bot?.type === 'ASSISTANT' && !(!this.user?.company.level || this.user?.company.level < environment.level.companyAdmin.level),
        routerLink: ['../../knowledge'],
      },
    ];
  }

  getConversation() {
    if (this.bot?.id && this.conversationId) {
      this.chatService.getConversationById$(this.bot.id, this.bot.type!, this.conversationId).subscribe({
        next: (conversation) => {
          this.conversation = conversation;
          this.contextMenu = this.buildContextMenu();
          this.conversationTitle = conversation.title;
          this.conversationReaction = {
            rating: this.conversation?.reaction.rating,
            comment: this.conversation?.reaction.comment,
          };
          this.isGettingConversation = false;
        },
        error: (error) => {
          console.error(error);
          this.isGettingConversation = false;
        },
      });
    }
  }

  openCommentPanel(event: any, target: HTMLElement) {
    this.commentPanel?.show(event, target);
  }

  onCancelRating() {
    this.conversationReaction = {
      rating: this.conversation?.reaction.rating,
      comment: this.conversation?.reaction.comment,
    };
    this.commentPanel?.hide();
  }

  saveReaction() {
    this.isRating = true;
    this.chatService
      .reactToConversation(this.conversationId, this.bot?.id!, this.conversationReaction)
      .pipe(finalize(() => (this.isRating = false)))
      .subscribe({
        next: () => {
          this.toastService.addSuccess({
            summary: this.mainLanguageService.instant('common.success'),
            detail: this.mainLanguageService.instant('private.assistantPage.topbar.ratedSuccessfully'),
          });
          this.commentPanel?.hide();
          this.getConversation();
        },
      });
  }

  toggleEdit() {
    this.isEditing = !this.isEditing;
    this.conversationTitle = this.conversation?.title || '';
  }

  onActivate() {
    this.isEditing = true;
    setTimeout(() => {
      if (this.titleInput) this.titleInput.nativeElement.focus();
    });
  }

  editConversationTitle() {
    if (this.conversation && this.bot) {
      this.chatService.editChatName$(this.conversation, this.conversationTitle, this.bot.type!).subscribe({
        next: (conversation) => {
          this.conversation = conversation;
          this.toastService.addSuccess({
            summary: this.mainLanguageService.instant('common.success'),
            detail: this.mainLanguageService.instant('private.assistantPage.nameModifiedSuccesfully'),
          });
          this.toggleEdit();
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
  }

  reloadChat() {
    this.confirmationService.confirm({
      header: this.mainLanguageService.instant('common.warning'),
      message: this.mainLanguageService.instant('private.assistantPage.restartModal.message'),
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.resetConversation();
        const lastChatIndex = this.lastChats.findIndex((chat) => chat.conversationId === this.conversationId);
        if (lastChatIndex !== -1) {
          this.lastChats = [...this.lastChats.slice(0, lastChatIndex), ...this.lastChats.slice(lastChatIndex + 1)];
        }
        this.botsService.setWholeLastChats$(this.lastChats);

        this.router
          .navigate(
            ['/', 'private', this.bot?.type === 'ASSISTANT' ? 'o' : '', 'assistants', this.bot?.id, 'chat', 'new'].filter((v) => !!v),
            {
              onSameUrlNavigation: 'reload',
            }
          )
          .then(() => {
            this.contextMenu = this.buildContextMenu();
          });
      },
    });
  }

  resetConversation() {
    this.conversation = undefined;
    this.conversationTitle = '';
    this.conversationId = 'new';
    this.conversationReaction = {
      rating: 0,
      comment: '',
    };
  }

  getHistory() {
    if (this.bot) {
      this.isLoadingHistory = true;
      this.chatService
        .getAllConversations(this.bot.id, this.bot.type!, 0, true, 5)
        .pipe(finalize(() => (this.isLoadingHistory = false)))
        .subscribe({
          next: (res) => {
            this.chatHistory = res.items;
          },
        });
    }
  }

  onHistoryClick(conversation: Conversation) {
    this.historyMenu?.hide();
    this.router.navigate(['../', conversation.id], { relativeTo: this.route, onSameUrlNavigation: 'reload', queryParams: { noRefresh: true } });
  }

  goToHistory() {
    this.router.navigate(['../../history/general/own'], {
      relativeTo: this.route,
      state: {
        conversationId: this.conversationId,
      },
    });
  }

  openShareModal(event: Event, conversation: IConversation) {
    event.stopPropagation();
    this.dialogService.open(ShareCreationModalComponent, {
      header: 'Share this conversation',
      styleClass: 'md:w-6 w-full',
      data: {
        conversation,
      },
    });
  }

  deleteChat(event: Event, conversation: IConversation) {
    event.stopPropagation();
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      header: this.mainLanguageService.instant('common.warning'),
      message: this.mainLanguageService.instant('private.assistantPage.deleteChatModal.alertMessage'),
      acceptButtonStyleClass: 'p-button-danger',
      acceptIcon: PrimeIcons.TRASH,
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.isLoadingHistory = true;
        this.chatService
          .deleteConversation(conversation.id, conversation.chatBotId || conversation.marketplaceId || '', this.bot!.type!, this.bot!.name)
          .subscribe({
            next: () => this.getHistory(),
            error: ({ error }) => {
              this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: error.message });
            },
          });
      },
    });
  }

  onInplaceActivate(index: number) {
    setTimeout(() => {
      const element = document.querySelector(`input#input-inplace-${index}`) as HTMLInputElement;
      element.focus();
    }, 0);
  }

  deactivateInplace(event: Event, inplace: Inplace) {
    event.preventDefault();
    event.stopPropagation();
    inplace.deactivate(event);
  }

  editChatName(event: Event, inplace: Inplace, conversation: IConversation) {
    this.isLoadingHistory = true;
    this.chatService
      .editChatName$(conversation, this.tempNewChatName, this.bot!.type!)
      .pipe(
        finalize(() => {
          this.tempNewChatName = '';
          this.deactivateInplace(event, inplace);
        })
      )
      .subscribe({
        next: () => this.getHistory(),
        error: ({ error }) => {
          this.toastService.addError({ summary: this.mainLanguageService.instant('common.success'), detail: error.message });
        },
      });
  }

  onEditClick(event: Event, inplace: Inplace, currentValue?: string) {
    event.stopPropagation();
    if (inplace.active) {
      inplace.deactivate(event);
      this.tempNewChatName = '';
    } else {
      inplace.activate(event);
      this.tempNewChatName = currentValue || '';
    }
  }
}
