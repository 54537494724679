import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataVisibility } from '../../models/data-visibility.model';
import { DataVisibilityService } from 'src/app/main/services/data-visibility.service';
import { ConfirmationService, PrimeIcons } from 'primeng/api';
import { combineLatest, finalize } from 'rxjs';
import { MainLanguageService } from 'src/app/main/services/main-language.service';

@Component({
  selector: 'app-access-recap',
  templateUrl: './access-recap.component.html',
  styleUrl: './access-recap.component.scss',
})
export class AccessRecapComponent {
  @Input() sourceId: string | string[] = '';
  @Input() visibilities: DataVisibility[] | null = null;

  @Output() onDelete: EventEmitter<boolean> = new EventEmitter();
  @Output() onAdd: EventEmitter<boolean> = new EventEmitter();

  isDeleting: boolean = false;

  constructor(
    private readonly dataVisibilityService: DataVisibilityService,
    private readonly confirmationService: ConfirmationService,
    private readonly mainLanguageService: MainLanguageService
  ) {}

  deleteVisibility(id: string) {
    this.confirmationService.confirm({
      header: this.mainLanguageService.instant('common.warning'),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      message: this.mainLanguageService.instant('private.sourceDetailsPage.createAccess.deleteMessage'),
      acceptIcon: PrimeIcons.TRASH,
      acceptButtonStyleClass: 'p-button-danger',
      rejectIcon: PrimeIcons.TIMES,
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.isDeleting = true;
        this.getVisibilityObservable(id)
          .pipe(finalize(() => (this.isDeleting = false)))
          .subscribe({
            next: () => this.onDelete.emit(true),
          });
      },
    });
  }

  private getVisibilityObservable(visibilityId: string) {
    if (!Array.isArray(this.sourceId)) {
      return this.dataVisibilityService.deleteVisibility$(this.sourceId, visibilityId);
    } else {
      return combineLatest(this.sourceId.map((id) => this.dataVisibilityService.deleteVisibility$(id, visibilityId)));
    }
  }
}
