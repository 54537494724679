<span class="h-full flex flex-column justify-content-between">
  <span>
    <h3>Chi è il beneficiario dell’assistente? Chi contatterà l’assistente?</h3>
    <textarea
      pInputTextarea
      class="w-full"
      [(ngModel)]="creationWizard.targetAudience"
      placeholder="Testo libero, ad esempio:
      - Clienti
      - Associati
      - Prospect 
    "
      [autoResize]="true"
    ></textarea>
  </span>
  <div class="flex justify-content-end gap-3">
    <button pButton [disabled]="!creationWizard.targetAudience" label="Next" (click)="setTargetAudience()"></button>
  </div>
</span>
