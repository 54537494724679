@if(isInConfiguration) {
<p class="my-0 px-5 pt-5 font-semibold">
  {{ botId ? ('private.configurationPage.editAssistant' | translate) : ('private.configurationPage.createAssistant' | translate) }}
</p>
} @if(title) {
<p class="my-0 px-5 pt-5 font-semibold">
  {{ title | translate }}
</p>
}
<div class="flex flex-column justify-content-between p-4 h-full">
  <span class="flex flex-column gap-2">
    @for (item of topItems; track $index; let last = $last) {
    <div
      class="py-3 px-2 border-round-lg cursor-pointer config-panel"
      [routerLink]="item.routerLink || undefined"
      [routerLinkActive]="'config-panel-active'"
      (click)="handleItemClick(item)"
    >
      <span class="flex gap-2 align-items-center">
        @if(item.icon) {
        <custom-icon [type]="'material'" [iconName]="item.icon"></custom-icon>
        }
        <p class="my-0 menu-label">
          {{ item.label || '' | translate }}
        </p>
      </span>
    </div>
    }
  </span>
  <span class="flex flex-column gap-2">
    @for (item of bottomItems; track $index; let last = $last) {
    <div
      class="py-3 px-2 border-round-lg cursor-pointer config-panel"
      [ngClass]="item.styleClass"
      [routerLink]="item.routerLink || undefined"
      [routerLinkActive]="'config-panel-active'"
      (click)="handleItemClick(item)"
    >
      <span class="flex gap-2 align-items-center">
        @if(item.icon) {
        <custom-icon [type]="'material'" [iconName]="item.icon"></custom-icon>
        }
        <p class="my-0 menu-label">
          {{ item.label || '' | translate }}
        </p>
      </span>
    </div>
    }
  </span>
</div>
