<h4 class="text-center">Creazione</h4>
<div class="flex sm:flex-row flex-column">
  <div class="flex md:col-2 sm:col-4 col-12">
    <img class="w-full" src="assets/imgs/starting-guide/creazione.png" />
  </div>
  <div class="flex flex-column gap-3 md:col-10 sm:col-8 col-12 text-justify">
    <p class="text-lg">
      In pochi secondi è possibile creare un nuovo assistente configurando l’aspetto, il suo carattere, chi può accedervi e da quali piattaforme
    </p>
    <button
      *level="companyAdminLevel"
      pButton
      label="Crea il tuo primo assistente"
      icon="pi pi-plus"
      class="md:w-6 sm:w-9 w-full p-button-primary p-button-outlined p-button-rounded"
      (click)="onClick()"
    ></button>
  </div>
</div>
