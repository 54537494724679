import * as i0 from '@angular/core';
import { forwardRef, EventEmitter, Component, Inject, Input, Output, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';
const GlobalEditorOptions = {
  autofocus: false,
  disabledButtons: [],
  dropZoneOptions: null,
  enableDropDataUri: false,
  footer: '',
  height: 'inherit',
  hiddenButtons: [],
  hideable: false,
  iconlibrary: 'fa',
  initialstate: 'editor',
  language: 'fr',
  additionalButtons: [[{
    name: 'groupFont',
    data: [{
      name: 'cmdStrikethrough',
      toggle: false,
      title: 'Strikethrough',
      icon: {
        fa: 'fa fa-strikethrough'
      },
      callback: e => {
        // Give/remove ~~ surround the selection
        let chunk;
        let cursor;
        const selected = e.getSelection();
        const content = e.getContent();
        if (selected.length === 0) {
          // Give extra word
          chunk = e.__localize('strikethrough');
        } else {
          chunk = selected.text;
        }
        // transform selection and set the cursor into chunked text
        if (content.substr(selected.start - 2, 2) === '~~' && content.substr(selected.end, 2) === '~~') {
          e.setSelection(selected.start - 2, selected.end + 2);
          e.replaceSelection(chunk);
          cursor = selected.start - 2;
        } else {
          e.replaceSelection('~~' + chunk + '~~');
          cursor = selected.start + 2;
        }
        // Set the cursor
        e.setSelection(cursor, cursor + chunk.length);
      }
    }]
  }, {
    name: 'groupMisc',
    data: [{
      name: 'cmdTable',
      toggle: false,
      title: 'Table',
      icon: {
        fa: 'fa fa-table'
      },
      callback: e => {
        // Replace selection with some drinks
        let chunk;
        let cursor;
        const selected = e.getSelection();
        chunk = '\n| Tables        | Are           | Cool  | \n' + '| ------------- |:-------------:| -----:| \n' + '| col 3 is      | right-aligned | $1600 | \n' + '| col 2 is      | centered      |   $12 | \n' + '| zebra stripes | are neat      |    $1 |';
        // transform selection and set the cursor into chunked text
        e.replaceSelection(chunk);
        cursor = selected.start;
        // Set the cursor
        e.setSelection(cursor, cursor + chunk.length);
      }
    }]
  }]]
};
class MarkdownEditorConfig {
  constructor() {
    this.options = GlobalEditorOptions;
  }
}
const MARKDOWN_EDITOR_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AngularMarkdownEditorComponent),
  multi: true
};
class AngularMarkdownEditorComponent {
  constructor(elm, forRootConfig) {
    this.elm = elm;
    this.forRootConfig = forRootConfig;
    /** Id of the textarea DOM element used by the lib */
    this.textareaId = '';
    /** Number of rows for the textarea */
    this.rows = 10;
    /** These do not actually ever emit, since bootstrap-markdown already emits the event. This is simply just for typings **/
    this._onShow = new EventEmitter();
    this._onPreview = new EventEmitter();
    this._onPreviewEnd = new EventEmitter();
    this._onSave = new EventEmitter();
    this._onBlur = new EventEmitter();
    this._onFocus = new EventEmitter();
    this._onChange = new EventEmitter();
    this._onFullscreen = new EventEmitter();
    this._onFullscreenExit = new EventEmitter();
    this._onSelect = new EventEmitter();
    this.onModelChange = () => {};
    this.onModelTouched = () => {};
  }
  /** Locale set that has a language and dictionary that can be added as an alternative language. Can be 1 or more dictionaries */
  set locale(locale) {
    this.addLocaleSet(locale);
  }
  ngAfterViewInit() {
    this.initialization();
  }
  addLocaleSet(editorLocale) {
    if (!editorLocale) {
      return;
    }
    if (Array.isArray(editorLocale)) {
      editorLocale.forEach(locale => $.fn.markdown.messages[locale.language] = locale.dictionary);
    } else {
      $.fn.markdown.messages[editorLocale.language] = editorLocale.dictionary;
    }
  }
  initialization() {
    // get all default options to get the entire list of onEvent so that we can attach Dispatch Custom Event to them
    // we also merge these with the options, and pass these merged options to the hookToEditorEvents() method to cover all onEvent callbacks
    const markdownDefaultOptions = $.fn.markdown.defaults;
    // re-hook new events that were optionally defined in the options
    // merge the options, the order matters (last options on the right have higher priorities)
    const options = {
      ...markdownDefaultOptions,
      ...GlobalEditorOptions,
      ...this.forRootConfig,
      ...this.options
    };
    // hook all events to respective callbacks
    // 1- could be coming from a Dispatched Event in the View:: (onX)="do()"
    // 2- or from editor option callback in the Component:: const options = { onX: () => do() }
    this.hookToEditorEvents(options);
    // hook to the onChange event to update our model
    // however we don't want to override the previous callback, so we will run that one to if exists
    const previousOnChange = options.onChange;
    options.onChange = e => {
      this.onModelChange(e?.getContent());
      if (typeof previousOnChange === 'function') {
        previousOnChange(e);
      }
    };
    // finally create the editor
    $(`#${this.textareaId}`).markdown(options);
  }
  /**
   * Hook any of the editor event(s) to Dispatch Custom Event so that we can use them in Angular with (onX)="doSomething($event.detail.eventData)"
   * @param editor options
   */
  hookToEditorEvents(options) {
    for (const prop in options) {
      if (options.hasOwnProperty(prop) && prop.startsWith('on')) {
        const previousEvent = options[prop];
        // on Callback triggered
        options[prop] = e => {
          // Dispatch a Custom Event, so that the (onX)="do()" from the View works
          this.dispatchCustomEvent(prop, {
            eventData: e
          });
          // if an event was passed through the options (instead of dispatch), and is not empty function, then we need to run it as well
          // basically we don't want the Dispatch Custom Event (onX)="do()" to override the ones passed directly in the editor option callbacks
          if (typeof previousEvent === 'function') {
            previousEvent(e);
          }
        };
      }
    }
  }
  /**
   * Write value to the native element
   * @param value string
   */
  writeValue(value) {
    this.value = value;
    // preset values in the DOM element
    if (this.value) {
      this.elm.nativeElement.querySelector('textarea').value = this.value;
    }
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  /** Dispatch of Custom Event, which by default will bubble & is cancelable */
  dispatchCustomEvent(eventName, data, isBubbling = true, isCancelable = true) {
    const eventInit = {
      bubbles: isBubbling,
      cancelable: isCancelable
    };
    if (data) {
      eventInit.detail = data;
    }
    return this.elm.nativeElement.dispatchEvent(new CustomEvent(eventName, eventInit));
  }
}
AngularMarkdownEditorComponent.ɵfac = function AngularMarkdownEditorComponent_Factory(t) {
  return new (t || AngularMarkdownEditorComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject('config'));
};
AngularMarkdownEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AngularMarkdownEditorComponent,
  selectors: [["angular-markdown-editor"]],
  inputs: {
    locale: "locale",
    textareaId: "textareaId",
    options: "options",
    rows: "rows"
  },
  outputs: {
    _onShow: "onShow",
    _onPreview: "onPreview",
    _onPreviewEnd: "onPreviewEnd",
    _onSave: "onSave",
    _onBlur: "onBlur",
    _onFocus: "onFocus",
    _onChange: "onChange",
    _onFullscreen: "onFullscreen",
    _onFullscreenExit: "onFullscreenExit",
    _onSelect: "onSelect"
  },
  features: [i0.ɵɵProvidersFeature([MARKDOWN_EDITOR_VALUE_ACCESSOR])],
  decls: 1,
  vars: 3,
  consts: [["data-provide", "markdown", 3, "id", "name", "rows"]],
  template: function AngularMarkdownEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "textarea", 0);
    }
    if (rf & 2) {
      i0.ɵɵpropertyInterpolate("id", ctx.textareaId);
      i0.ɵɵpropertyInterpolate("name", ctx.textareaId);
      i0.ɵɵpropertyInterpolate("rows", ctx.rows);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularMarkdownEditorComponent, [{
    type: Component,
    args: [{
      moduleId: 'angulaMarkdownEditor',
      selector: 'angular-markdown-editor',
      template: '<textarea id="{{textareaId}}" name="{{textareaId}}" data-provide="markdown" rows="{{rows}}"></textarea>',
      providers: [MARKDOWN_EDITOR_VALUE_ACCESSOR]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: ['config']
      }]
    }];
  }, {
    locale: [{
      type: Input
    }],
    textareaId: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    rows: [{
      type: Input
    }],
    _onShow: [{
      type: Output,
      args: ['onShow']
    }],
    _onPreview: [{
      type: Output,
      args: ['onPreview']
    }],
    _onPreviewEnd: [{
      type: Output,
      args: ['onPreviewEnd']
    }],
    _onSave: [{
      type: Output,
      args: ['onSave']
    }],
    _onBlur: [{
      type: Output,
      args: ['onBlur']
    }],
    _onFocus: [{
      type: Output,
      args: ['onFocus']
    }],
    _onChange: [{
      type: Output,
      args: ['onChange']
    }],
    _onFullscreen: [{
      type: Output,
      args: ['onFullscreen']
    }],
    _onFullscreenExit: [{
      type: Output,
      args: ['onFullscreenExit']
    }],
    _onSelect: [{
      type: Output,
      args: ['onSelect']
    }]
  });
})();
class AngularMarkdownEditorModule {
  static forRoot(config = {}) {
    return {
      ngModule: AngularMarkdownEditorModule,
      providers: [{
        provide: 'config',
        useValue: config
      }]
    };
  }
}
AngularMarkdownEditorModule.ɵfac = function AngularMarkdownEditorModule_Factory(t) {
  return new (t || AngularMarkdownEditorModule)();
};
AngularMarkdownEditorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AngularMarkdownEditorModule
});
AngularMarkdownEditorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularMarkdownEditorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [AngularMarkdownEditorComponent],
      exports: [AngularMarkdownEditorComponent]
    }]
  }], null, null);
})();

// Public classes.

/**
 * Generated bundle index. Do not edit.
 */

export { AngularMarkdownEditorComponent, AngularMarkdownEditorModule, MarkdownEditorConfig };
