@if (!isDialog) {

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 800" class="fixed left-0 top-0 min-h-screen min-w-screen" preserveAspectRatio="none">
  <rect [attr.fill]="'var(--primary-500)'" width="1600" height="800" />
  <path
    [attr.fill]="'var(--primary-400)'"
    d="M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z"
  />
  <path
    [attr.fill]="'var(--primary-300)'"
    d="M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z"
  />
  <path
    [attr.fill]="'var(--primary-200)'"
    d="M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z"
  />
  <path
    [attr.fill]="'var(--primary-100)'"
    d="M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z"
  />
</svg>
}

<div [ngClass]="{ 'flex justify-content-center h-screen p-0': !isDialog, 'h-full': isDialog }">
  <div class="flex justify-content-center relative h-full" [ngClass]="{ 'mt-4': isDialog, 'md:w-8 w-full': !isDialog }">
    @if (sharedConversation) {
    <div
      #sharedContainer
      class="shared-container w-full overflow-y-scroll bg-white"
      [ngClass]="{
        'border-1 shadow-1 border-gray-200 border-round-lg': isDialog,
        'md:border-1 border-0 md:border-round-lg border-noround border-gray-300': !isDialog
      }"
    >
      <div class="relative bg-primary text-white p-4 flex md:flex-row flex-column justify-content-between md:gap-0 gap-3">
        <span class="flex md:flex-row flex-column gap-2 align-items-center justify-content-start">
          <img class="w-2 bot-image" [src]="sharedConversation.assistant?.avatar" />
          <div class="flex flex-column justify-content-center align-items-start">
            <p class="font-semibold my-0">{{ sharedConversation.assistant?.name }}</p>
            <p class="text-start">Powered by Pupau.ai</p>
          </div>
        </span>
        <div>
          <h2 class="text-white md:text-right text-center conversation-title">{{ sharedConversation.title }}</h2>
          <p class="text-white md:text-right text-center">
            Visualizzazioni: <span class="font-semibold">{{ sharedConversation.views }}</span> - Conversazione creata il
            <span class="font-semibold">{{ sharedConversation.createdAt.toJSDate() | date : 'longDate' }}</span>
          </p>
        </div>
      </div>
      <div class="flex flex-column">
        @if (sharedConversation.assistant) {
        <div class="p-3 flex align-items-start flex-column">
          <div
            class="border-1 border-round-md border-gray-300 p-3 bg-blue-100 max-w-11"
            [innerHTML]="sharedConversation.assistant.welcomeMessage | markdownParser"
          ></div>
          <p class="text-gray-500">{{ sharedConversation.createdAt.toJSDate() | date : 'hh:MM a' }}</p>
        </div>
        }
      </div>
      @for (message of sharedConversation.messages; track $index) {
      <div class="flex flex-column gap-4 mb-4">
        <div class="p-3 flex align-items-end flex-column">
          <div class="border-1 border-round-md border-gray-300 p-3 max-w-11" [innerHTML]="message.query | markdownParser"></div>
          <p class="text-gray-500">{{ message.createdAt.toJSDate() | date : 'hh:MM a' }}</p>
        </div>
        <div class="p-3 flex align-items-start flex-column">
          <div class="border-1 border-round-md border-gray-300 p-3 bg-blue-100 max-w-11" [innerHTML]="message.answer | markdownParser"></div>
          <p class="text-gray-500">{{ message.createdAt.toJSDate() | date : 'hh:MM a' }}</p>
        </div>
      </div>
      }
      <div class="button-div w-full text-center p-4">
        @if (sharedConversation.enableReuse) {
        <button pButton (click)="continueConversation()" class="p-button-rounded" label="Continua questa chat"></button>
        }
      </div>
    </div>
    } @if(showScrollToTopButton) {
    <div class="absolute bottom-0 right-0 p-5 z-3">
      <button pButton class="p-button-rounded p-button-secondary p-button-raised" icon="pi pi-arrow-up" (click)="scrollToTop()"></button>
    </div>
    }
  </div>
</div>
