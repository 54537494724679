import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectButtonOptionClickEvent } from 'primeng/selectbutton';
import { finalize } from 'rxjs';
import { ExportChatService } from 'src/app/main/services/export-chat.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { EXPORT_FORMATS, EXPORT_PERIODS } from '../../constants/export-chat.consts';
import { IExportChatDTO } from '../../models/export-chat.model';
import { ExportFormats, ExportPeriods } from '../../types/export-chat.types';

@Component({
  selector: 'app-export-chat-modal',
  templateUrl: './export-chat-modal.component.html',
  styleUrl: './export-chat-modal.component.scss',
})
export class ExportChatModalComponent {
  readonly exportFormats = EXPORT_FORMATS;
  readonly exportPeriods = EXPORT_PERIODS;

  minStartDate: Date | null = null;
  maxEndDate: Date | null = null;

  section: 'general' | 'ratings';
  botId: string;
  botName: string;
  exportForm: FormGroup;

  isCustomPeriod: boolean = false;
  isExporting: boolean = false;

  period: {
    start: Date | null;
    end: Date | null;
  } = {
    start: DateTime.now().minus({ month: 1 }).toJSDate(),
    end: DateTime.now().toJSDate(),
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly ref: DynamicDialogRef,
    private readonly toastService: ToastService,
    private readonly config: DynamicDialogConfig,
    private readonly exportChatService: ExportChatService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.section = this.config.data.currentURLSegment;
    this.botId = this.config.data.botId;
    this.botName = this.config.data.botName;

    this.exportForm = this.fb.group({
      period: new FormControl(<ExportPeriods>'currentMonth', [Validators.required]),
      exportChat: new FormControl(false),
      format: new FormControl(<ExportFormats>'csv', [Validators.required]),
    });

    this.periodFormControl?.valueChanges.subscribe({
      next: (v) => {
        const period = this.exportPeriods.find(({ value }) => value === v);
        if (period) {
          this.period = {
            start: period.start,
            end: period.end,
          };

          this.isCustomPeriod = period.value === 'custom';
        }
      },
    });
  }

  get periodFormControl() {
    return this.exportForm.get('period') as FormControl<ExportPeriods>;
  }

  updateDates() {
    if (this.period.start) {
      this.minStartDate = DateTime.fromJSDate(this.period.start).minus({ months: 3 }).toJSDate();
    }

    if (this.period.end) {
      this.maxEndDate = DateTime.fromJSDate(this.period.end).plus({ months: 3 }).toJSDate();
    }
  }

  onFormSubmit() {
    if (!this.isMoreThanThreeMonths()) {
      const body = this.buildBody();

      this.isExporting = true;
      this.exportChatService
        .exportChat$(this.botId, this.botName, body)
        .pipe(finalize(() => (this.isExporting = false)))
        .subscribe({
          next: () => this.ref.close({ result: true }),
          error: ({ error }) => {
            this.toastService.addError({
              summary: this.mainLanguageService.instant('common.error'),
              detail: JSON.parse(error).message,
            });
          },
        });
    } else {
      this.toastService.addError({
        summary: this.mainLanguageService.instant('common.error'),
        detail: this.mainLanguageService.instant('private.historyPage.exportModal.moreThanThreeMonths'),
      });
    }
  }

  private buildBody(): IExportChatDTO {
    const rawBody = this.exportForm.getRawValue();

    return {
      evaluation: this.section === 'ratings',
      from: DateTime.fromJSDate(this.period.start!).toFormat('yyyy-MM-dd'),
      to: DateTime.fromJSDate(this.period.end!).toFormat('yyyy-MM-dd'),
      messages: rawBody.exportChat,
      format: rawBody.format.toUpperCase(),
    };
  }

  isMoreThanThreeMonths() {
    return DateTime.fromJSDate(this.period.end!) > DateTime.fromJSDate(this.period.start!).plus({ months: 3 });
  }
}
