import { Component, OnInit } from '@angular/core';
import { COMMERCIAL_STEPS } from '../../../constants/assistant-creation-wizard/commercial-steps.const';
import { AssistantCreationWizard, AssistantWizardStepsItem } from '../../../models/assistant-creation.model';
import { AssistantCreationService } from 'src/app/main/services/assistant-creation.service';

@Component({
  selector: 'app-commercial',
  templateUrl: './commercial.component.html',
  styleUrls: ['./commercial.component.scss'],
})
export class CommercialComponent implements OnInit {
  readonly steps = COMMERCIAL_STEPS;
  creationWizard?: AssistantCreationWizard;

  activeItem?: AssistantWizardStepsItem;

  activeIndex: number = 0;

  constructor(private readonly assistantCreationService: AssistantCreationService) {
    assistantCreationService.getNextStep$().subscribe({
      next: (v) => {
        if (v) {
          this.activeIndex++;
        } else {
          this.activeIndex--;
        }
      },
    });
  }

  ngOnInit(): void {
    this.setActiveItem();
  }

  setActiveItem() {
    this.activeItem = this.steps[this.activeIndex];
  }
}
