import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, Subject, tap, throwError } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { BotsService } from 'src/app/main/services/bots.service';
import { DEFAULT_BOT_PLACEHOLDER } from 'src/app/main/shared/constants/bot.consts';
import { ChatQueryMessage, IChatQueryMessage, KbReferences } from 'src/app/main/shared/models/chat-message.model';
import { v4 } from 'uuid';
import { Conversation, IConversation, IReactionDTO } from '../api/conversation.model';
import { Message } from '../api/message.model';
import { HUNDRED } from 'src/app/main/shared/constants/chat.consts';
import { AssistantType } from 'src/app/main/shared/models/chat-bot.model';
import { Store } from '@ngrx/store';
import { IBotStoreState, removeLastChatAction } from 'src/app/core/store/bot';

@Injectable()
export class ChatService {
  private readonly selectedSourceInPreview$ = new BehaviorSubject<KbReferences | undefined>(undefined);

  private _searchSubject$: Subject<string> = new Subject();

  // FIRST_MESSAGE_COMPLETE
  private readonly _hasReceivedFirstMessage$ = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService, private botsService: BotsService, private readonly store: Store<{ bot: IBotStoreState }>) {}

  getSelectedSource$() {
    return this.selectedSourceInPreview$.asObservable();
  }

  setSelectedSource$(value?: KbReferences) {
    this.selectedSourceInPreview$.next(value);
  }

  getAllConversations(
    botId: string,
    type: AssistantType,
    page: number = 0,
    own: boolean = false,
    items: number = 10,
    onlyRatings: boolean = false,
    searchTerm: string = ''
  ) {
    // /chat-bots/2/conversations
    return this.apiService
      .get<{ items: IConversation[]; total: number }>(
        `${type === 'ASSISTANT' ? 'chat-bots' : 'marketplace'}/${botId}/conversations${onlyRatings ? '/reactions' : ''}?items=${items}&page=${page}${
          own ? '&own=true' : ''
        }${searchTerm ? '&search=' + searchTerm : ''}`
      )
      .pipe(map((data) => ({ total: data.total, items: data.items.map((item) => new Conversation(item)) })));
  }

  getConversationById$(botId: string, type: AssistantType, conversationId: string): Observable<IConversation> {
    return this.apiService
      .get(`${type === 'ASSISTANT' ? 'chat-bots' : 'marketplace'}/${botId}/conversations/${conversationId}`)
      .pipe(map((data: any) => data as IConversation));
  }

  getAllMessagesFromIdConversations(botId: string, idConversation: string) {
    return this.apiService.get(`chat-bots/${botId}/conversations/${idConversation}/queries`).pipe(
      map((data: any) =>
        data.items
          ?.map((item: any) => {
            return [
              <Message>{
                text: item.query,
                ownerId: '123',
                createdAt: item.createdAt,
                isResponding: true,
              },
              <Message>{
                text: item.answer,
                ownerId: '1',
                createdAt: item.createdAt,
                isResponding: false,
              },
            ];
          })
          ?.flat()
      )
    );
  }

  editChatName$(conversation: IConversation, name: string, type: AssistantType) {
    return this.apiService.patch<IConversation>(
      `${type === 'ASSISTANT' ? 'chat-bots' : 'marketplace'}/${conversation.chatBotId}/conversations/${conversation.id}`,
      {
        title: name,
      }
    );
  }

  sendMessage(message: Message, tokenConversation: any, override?: boolean) {
    return this.askQuestionFromApi(message, tokenConversation, override).pipe(catchError(() => throwError(() => 'ERROR')));
  }

  askQuestionFromApi(message: Message, tokenConversation: any, override?: boolean) {
    const currentBot = this.botsService.getCurrentBot();
    let headers = {
      'Conversation-Token': tokenConversation.token,
    };

    let queries = 'queries';
    if (override) queries = queries + '?override=true';

    return this.apiService
      .post<IChatQueryMessage>(
        `chat-bots/${currentBot?.id}/conversations/${tokenConversation.id}/${queries}`,
        {
          request: message.text,
        },
        headers
      )
      .pipe(map((chatQueryMessage) => new ChatQueryMessage(chatQueryMessage)));
  }

  deleteConversation(id: string, chatBotId: string, type: AssistantType, botName: string) {
    return this.apiService
      .delete<any>(`${type === 'ASSISTANT' ? 'chat-bots' : 'marketplace'}/${chatBotId}/conversations/${id}`)
      .pipe(tap(() => this.store.dispatch(removeLastChatAction({ bot: { id: chatBotId, name: botName, type } }))));
  }

  reactToConversation(idConversation: string, idBot: string, reaction: IReactionDTO) {
    return this.apiService.post(`chat-bots/${idBot}/conversations/${idConversation}/reactions`, reaction);
  }

  get searchSubject$() {
    return this._searchSubject$.asObservable();
  }

  set searchSubject$$(term: string) {
    this._searchSubject$.next(term);
  }

  get hasReceivedFirstMessage$(): Observable<boolean> {
    return this._hasReceivedFirstMessage$.asObservable();
  }

  set hasReceivedFirstMessage(value: boolean) {
    this._hasReceivedFirstMessage$.next(value);
  }
}
