import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'presets',
})
export class PresetsPipe implements PipeTransform {
  transform(value: string): string {
    return `private.analyticsPage.filters.presets.${value}`;
  }
}
