import { Pipe, PipeTransform } from '@angular/core';
import { SourceType } from '../../private/chat/api/conversation.model';

@Pipe({
  name: 'conversationSource',
})
export class ConversationSourcePipe implements PipeTransform {
  transform(value: SourceType | null): string {
    switch (value) {
      case 'WEB':
      case null:
        return 'Web';
      case 'ANDROID':
        return 'Android';
      case 'IOS':
        return 'iOS';
    }
  }
}
