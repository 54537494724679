export interface ICreateUserGroupDTO {
  name: string;
  userIds: string[];
}

export interface ICompanyGroupUser {
  id: string;
  image: string | null;
  isEnable: boolean;
  kind: string;
  name: string;
  surname: string;
  username: string;
  groups?: ICompanyGroup[];
}

export class CompanyGroupUser {
  id: string = '';
  image: string | null = null;
  isEnable: boolean = false;
  kind: string = '';
  username: string = '';
  name: string = '';
  surname: string = '';
  fullName: string = '';
  groups?: CompanyGroup[];

  constructor(res?: ICompanyGroupUser) {
    if (res) {
      this.id = res.id;
      this.image = res.image;
      this.isEnable = res.isEnable;
      this.kind = res.kind;
      this.username = res.username;
      this.name = res.name;
      this.surname = res.surname;
      this.fullName = `${res.name} ${res.surname}`;
      if (res.groups) this.groups = res.groups.map((g) => new CompanyGroup(g));
    }
  }
}

export interface ICompanyGroup {
  id: string;
  name: string;
  users?: ICompanyGroupUser[];
}

export class CompanyGroup {
  id: string = '';
  name: string = '';
  users?: CompanyGroupUser[];

  constructor(res?: ICompanyGroup) {
    if (res) {
      this.id = res.id;
      this.name = res.name;
      if (res.users) this.users = res.users.map((u) => new CompanyGroupUser(u));
    }
  }
}
