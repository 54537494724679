import { FilterKbTag } from '../models/kb-tags.model';

export const NO_TAG_FILTER: FilterKbTag = new FilterKbTag({
  id: 'no-tag',
  color: 'b2b2b2',
  label: 'private.knowledgePage.filters.tags.byNoTag',
  sourcesCount: 0,
});

export const REFRESH_PERIODS = [
  {
    label: 'private.sourceDetailsPage.refreshPeriods.7',
    value: 7,
  },
  {
    label: 'private.sourceDetailsPage.refreshPeriods.14',
    value: 14,
  },
  {
    label: 'private.sourceDetailsPage.refreshPeriods.30',
    value: 30,
  },
  {
    label: 'private.sourceDetailsPage.refreshPeriods.90',
    value: 90,
  },
  {
    label: 'private.sourceDetailsPage.refreshPeriods.180',
    value: 180,
  },
];

export const getRandomTags = (array: any[], n: number) => {
  return array.sort(() => Math.random() - Math.random()).slice(0, n);
};
