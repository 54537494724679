import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { CustomMenuItem } from '../../models/custom-menu-item.model';

@Component({
  selector: 'app-configuration-sidebar',
  templateUrl: './configuration-sidebar.component.html',
  styleUrl: './configuration-sidebar.component.scss',
})
export class ConfigurationSidebarComponent implements OnChanges {
  @Input() menuItems: CustomMenuItem[] = [];
  @Input() title?: string;
  @Input() isInConfiguration: boolean = true;
  @Input() botId?: string;
  @Input() activeItem?: CustomMenuItem;
  @Output() activeItemChange: EventEmitter<CustomMenuItem> = new EventEmitter();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['menuItems']) {
      this.menuItems = this.menuItems.filter((item) => item.visible !== false);
    }
  }

  handleItemClick(item: any): void {
    if (item.command) {
      item.command();
    } else {
      this.onItemClick(item);
    }
  }

  onItemClick(item: CustomMenuItem) {
    this.activeItemChange.emit(item);
  }

  get topItems(): CustomMenuItem[] {
    return this.menuItems.filter((item) => item.isBottomOfMenu !== true);
  }

  get bottomItems(): CustomMenuItem[] {
    return this.menuItems.filter((item) => item.isBottomOfMenu === true);
  }
}
