import { Component, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

export interface Breadcrumb {
  label: string;
  url?: string;
  routerLink?: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
})
export class AppBreadcrumbComponent implements OnDestroy {
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router)
      )
      .subscribe({
        next: (event) => {
          const root = this.router.routerState.snapshot.root;
          const breadcrumbs: Breadcrumb[] = [];
          this.addBreadcrumb(root, [], breadcrumbs);

          this._breadcrumbs$.next(breadcrumbs);
        },
      });
  }

  ngOnDestroy(): void {
    this._breadcrumbs$.next([]);
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
    const routeUrl = parentUrl.concat(route.url.map((url) => url.path));
    const breadcrumb = route.data['breadcrumb'];
    const parentBreadcrumb = route.parent && route.parent.data ? route.parent.data['breadcrumb'] : null;

    if (breadcrumb && breadcrumb !== parentBreadcrumb) {
      if (Array.isArray(breadcrumb)) {
        breadcrumb.forEach((crumb) => {
          breadcrumbs.push({
            label: typeof crumb === 'string' ? crumb : crumb.label,
            routerLink: typeof crumb === 'string' ? undefined : crumb.route,
            url: '/' + routeUrl.join('/'),
          });
        });
      } else {
        breadcrumbs.push({
          label: breadcrumb,
          url: '/' + routeUrl.join('/'),
        });
      }
    }

    if (route.firstChild) {
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }
}
