import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssistantCreationService } from 'src/app/main/services/assistant-creation.service';
import { COMMERCIAL_RECAP_KEYS, INTERNAL_KB_RECAP_KEYS } from 'src/app/main/shared/constants/assistant-creation-wizard/recaps.const';
import { AssistantCreationWizard } from 'src/app/main/shared/models/assistant-creation.model';

@Component({
  selector: 'app-recap',
  templateUrl: './recap.component.html',
  styleUrls: ['./recap.component.scss'],
})
export class RecapComponent {
  private creationWizard: AssistantCreationWizard = new AssistantCreationWizard();
  recap: { [key: string]: any } = {};

  constructor(private readonly assistantCreationService: AssistantCreationService, private readonly ref: DynamicDialogRef) {
    this.assistantCreationService.getAssistantCreationWizard$().subscribe({
      next: (v) => {
        this.creationWizard = v;

        switch (this.creationWizard.type) {
          case 'internalKb':
            this.recap = this.creationWizard.getRecap(INTERNAL_KB_RECAP_KEYS);
            break;
          case 'commercial':
            this.recap = this.creationWizard.getRecap(COMMERCIAL_RECAP_KEYS);
            break;
        }
      },
    });
  }

  closeModal() {
    this.ref.close();
  }
}
