import { Pipe, PipeTransform } from '@angular/core';
import { SingleLinkType } from '../models/chat-bot-source.model';

@Pipe({
  name: 'availableLink',
})
export class AvailableLinkPipe implements PipeTransform {
  transform(value: SingleLinkType): string {
    switch (value) {
      case 'SELECTED':
        return 'enabled';
      case 'AVAILABLE':
        return 'disabled';
    }
  }
}
