import { DateTime } from 'luxon';
import { ApiKeyRestrictionTypeEnum, ApiKeyTypeEnum } from '../enums/api-key.enum';

export interface IApiKeyConfig {
  showCloseButtons?: boolean;
  showNewConversationButton?: boolean;
  forceFullMode?: boolean;
  privacyPolicyURL?: string;
  termsOfServiceURL?: string;
  maxMessagePerConversation?: number;
  tooManyMessageErrorText?: string;
}

export class ApiKeyConfig {
  showCloseButtons: boolean = false;
  showNewConversationButton: boolean = false;
  forceFullMode: boolean = false;
  privacyPolicyURL: string = '';
  termsOfServiceURL: string = '';
  maxMessagePerConversation: number = 20;
  tooManyMessageErrorText: string = '';

  constructor(res?: IApiKeyConfig) {
    if (res) {
      if (res.showCloseButtons !== undefined) this.showCloseButtons = res.showCloseButtons;
      if (res.showNewConversationButton !== undefined) this.showNewConversationButton = res.showNewConversationButton;
      if (res.forceFullMode !== undefined) this.forceFullMode = res.forceFullMode;
      if (res.privacyPolicyURL !== undefined) this.privacyPolicyURL = res.privacyPolicyURL;
      if (res.termsOfServiceURL !== undefined) this.termsOfServiceURL = res.termsOfServiceURL;
      if (res.maxMessagePerConversation !== undefined) this.maxMessagePerConversation = res.maxMessagePerConversation;
      if (res.tooManyMessageErrorText !== undefined) this.tooManyMessageErrorText = res.tooManyMessageErrorText;
    }
  }
}

export interface ICreateAPIKeyDTO {
  name: string;
  type: ApiKeyTypeEnum;
  assistantId: string;
  config: IApiKeyConfig | null;
  restrictionType: ApiKeyRestrictionTypeEnum;
  restrictionDetails: string | null;
  system: boolean;
}

export interface IAPIKey {
  id: string;
  companyId: string;
  assistantId: string;
  keyId: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  type: ApiKeyTypeEnum;
  config: string | IApiKeyConfig | null;
  restrictionType: ApiKeyRestrictionTypeEnum;
  restrictionDetails: string;
  system: boolean;
}

export class APIKey {
  id: string = '';
  companyId: string = '';
  assistantId: string = '';
  keyId: string = '';
  createdAt: DateTime = DateTime.now();
  updatedAt: DateTime = DateTime.now();
  name: string = '';
  type?: ApiKeyTypeEnum;
  config: ApiKeyConfig = new ApiKeyConfig();
  restrictionType?: ApiKeyRestrictionTypeEnum;
  restrictionDetails: string[] = [];
  system: boolean = false;

  constructor(res?: IAPIKey) {
    if (res) {
      this.id = res.id;
      this.companyId = res.companyId;
      this.assistantId = res.assistantId;
      this.keyId = res.keyId;
      this.createdAt = DateTime.fromISO(res.createdAt);
      this.updatedAt = DateTime.fromISO(res.updatedAt);
      this.name = res.name;
      this.type = res.type;
      if (res.config)
        this.config = typeof res.config === 'string' ? new ApiKeyConfig(JSON.parse(res.config) as IApiKeyConfig) : new ApiKeyConfig(res.config);
      this.restrictionType = res.restrictionType;
      this.restrictionDetails = res.restrictionDetails ? res.restrictionDetails.split(',') : [];
      this.system = res.system;
    }
  }
}
