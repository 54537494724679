<form class="mt-4 flex flex-column gap-4" [formGroup]="exportForm" (ngSubmit)="onFormSubmit()">
  <span class="w-full flex flex-column gap-2">
    <label class="text-sm text-gray-600">{{ 'private.historyPage.exportModal.period' | translate }}</label>
    <p-selectButton [options]="exportPeriods" formControlName="period" [allowEmpty]="false">
      <ng-template pTemplate="item" let-item>
        <p>{{ 'private.historyPage.exportModal.periods.' + item.label | translate }}</p>
      </ng-template>
    </p-selectButton>

    @if (isCustomPeriod) {
    <span class="mt-2 flex flex-column gap-2">
      <label class="text-sm text-gray-600">{{ 'private.historyPage.exportModal.insertCustomRange' | translate }}</label>
      <span class="flex gap-2">
        <p-calendar
          [(ngModel)]="period.start"
          [ngModelOptions]="{ standalone: true }"
          [minDate]="minStartDate"
          [maxDate]="period.end"
          (onSelect)="updateDates()"
          appendTo="body"
          class="w-full"
          styleClass="w-full"
        ></p-calendar>
        <p-calendar
          [(ngModel)]="period.end"
          [ngModelOptions]="{ standalone: true }"
          [minDate]="period.start"
          [maxDate]="maxEndDate"
          (onSelect)="updateDates()"
          appendTo="body"
          class="w-full"
          styleClass="w-full"
        ></p-calendar>
      </span>
    </span>
    }
  </span>
  <span class="w-full flex flex-column gap-2">
    <label class="text-sm text-gray-600">{{ 'private.historyPage.exportModal.exportChats' | translate }}</label>
    <span class="flex align-items-center gap-3">
      <p class="my-0">{{ 'common.no' | translate | uppercase }}</p>
      <p-inputSwitch formControlName="exportChat" class="flex"></p-inputSwitch>
      <p class="my-0">{{ 'common.yes' | translate | uppercase }}</p>
    </span>
  </span>
  <span class="w-full flex flex-column gap-2">
    <label class="text-sm text-gray-600">{{ 'private.historyPage.exportModal.format' | translate }}</label>
    <p-selectButton [options]="exportFormats" formControlName="format" [allowEmpty]="false"></p-selectButton>
  </span>

  <div class="flex justify-content-end">
    <button
      pButton
      type="submit"
      [label]="'private.historyPage.exportModal.button' | translate"
      [disabled]="exportForm.invalid"
      [loading]="isExporting"
    ></button>
  </div>
</form>
