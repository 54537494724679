import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { ChatBotHistoryService } from 'src/app/main/services/chat-bot-history.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { StarterMessageHistory } from '../../models/chat-bot-history.model';

@Component({
  selector: 'app-starter-message-history-modal',
  templateUrl: './starter-message-history-modal.component.html',
  styleUrl: './starter-message-history-modal.component.scss',
})
export class StarterMessageHistoryModalComponent implements OnInit, OnDestroy {
  botId: string;
  history: StarterMessageHistory[] = [];
  isHistoryOpen: boolean = true;

  selectedHistory?: StarterMessageHistory;

  isApplying: boolean = false;
  hasEdited: boolean = false;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly toastService: ToastService,
    private readonly config: DynamicDialogConfig,
    private readonly mainLanguageService: MainLanguageService,
    private readonly chatBotHistoryService: ChatBotHistoryService
  ) {
    this.botId = this.config.data.id;
  }

  ngOnInit(): void {
    this.chatBotHistoryService.getChatBotHistory$(this.botId).subscribe({
      next: (v) => (this.history = v),
    });
  }

  ngOnDestroy(): void {
    this.ref.close({ starterMessage: this.hasEdited ? this.selectedHistory?.starterMessage : undefined });
  }

  setSelected(history: StarterMessageHistory) {
    this.selectedHistory = history;
  }

  applyStarterMessage() {
    this.isApplying = true;
    this.chatBotHistoryService
      .applyStarterMessage$(this.botId, this.selectedHistory?.starterMessage!)
      .pipe(finalize(() => (this.isApplying = false)))
      .subscribe({
        next: () => {
          this.toastService.addSuccess({
            summary: this.mainLanguageService.instant('common.success'),
            detail: this.mainLanguageService.instant('private.configurationPage.assistantPage.programmingHistoryModal.programmingEdited'),
          });
          this.hasEdited = true;
        },
        error: ({ error }) => this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: error.message }),
      });
  }
}
