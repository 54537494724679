<div class="w-full h-full flex flex-column">
  <div class="flex md:flex-row flex-column col-12">
    <div class="md:col-4 col-12">
      @if (bot) {
      <img
        class="w-full border-circle"
        fallbackImage="https://cdn.pupau.ai/assets/logo.webp"
        [src]="
          bot.type === 'ASSISTANT'
            ? imageUuidService.getImageUrlFromUUID(bot, bot.imageUuid, 'H')
            : imageUuidService.getMarketplaceImageUrlFromUUID(bot, 'H')
        "
      />
      }
    </div>
    <div class="md:col-8 col-12 flex flex-column gap-4">
      <h3>{{ bot?.name }}</h3>
      <button pButton class="p-button-primary w-6" label="Start" (click)="closeModalAndStart()"></button>
    </div>
  </div>
  <div class="flex flex-column col-12">
    <span>
      <label class="font-semibold text-lg">{{ 'private.dashboardPage.detailsModal.description' | translate }}</label>
      <p>{{ bot?.description }}</p>
    </span>
    <p-divider></p-divider>
    <span>
      <label class="font-semibold text-lg">{{ 'private.dashboardPage.detailsModal.replyMode' | translate }}</label>
      <p>{{ getBotMode() }}</p>
    </span>
  </div>
</div>
