<div class="flex flex-column">
  <p-tabMenu [model]="tabMenuItem" [(activeItem)]="activeItem" styleClass="full-width-tabmenu">
    <ng-template pTemplate="item" let-item>
      <a class="p-menuitem-link p-3">{{ item.label | translate }}</a>
    </ng-template>
  </p-tabMenu>

  @switch (activeItem.tabindex) { @case ('product') {
  <div class="grid mt-3">
    <div class="md:col-4 col-12">
      <img [src]="imageUuidService.getMarketplaceImageUrlFromUUID(item, 'H')" class="w-full border-circle" />
    </div>
    <div class="md:col-8 col-12 flex flex-column gap-2">
      <span class="mb-2">
        <h3 class="mt-0">{{ item.name }}</h3>
        <div class="flex flex-wrap gap-2 my-2" style="min-height: 20px">
          @if (item.official) {
          <p-tag [value]="'private.dashboardPage.marketplaceSection.tags.using' | translate" styleClass="bg-green-400"></p-tag>
          } @if (item.legacy) {
          <p-tag [value]="'private.dashboardPage.marketplaceSection.tags.legacy.singular' | translate" styleClass="bg-orange-400"></p-tag>
          } @if (item.promo) {
          <p-tag [value]="'private.dashboardPage.marketplaceSection.tags.promo' | translate" styleClass="bg-red-400"></p-tag>
          }
        </div>
      </span>
      <p class="my-0">
        <span class="font-semibold">{{ 'private.dashboardPage.marketplaceSection.productDetailsModal.version' | translate }} </span>{{ item.version }}
      </p>
      <p class="my-0">
        <span class="font-semibold">{{ 'private.dashboardPage.marketplaceSection.productDetailsModal.createdAt' | translate }} </span
        >{{ item.updatedAt | dateTime : 'DDD' }}
      </p>
      <p class="my-3">
        <span class="font-semibold">{{ 'private.dashboardPage.marketplaceSection.productDetailsModal.description' | translate }} </span
        >{{ item.storeCard?.longDescription }}
      </p>
      <p class="mb-0">
        <span class="font-semibold">{{ 'private.configurationPage.assistantPage.cost' | translate }}: </span
        >{{
          item.cost?.message !== 1
            ? ('private.configurationPage.assistantPage.costDescription1.plural'
              | translate : { price: item.cost?.message, includedK: item.cost?.includedK })
            : ('private.configurationPage.assistantPage.costDescription1.singular'
              | translate : { price: item.cost?.message, includedK: item.cost?.includedK })
        }}
        <sup class="pi pi-info-circle text-blue-400 cursor-pointer text-xs"></sup>.
        {{
          item.cost?.extraK !== 1
            ? ('private.configurationPage.assistantPage.costDescription2.plural'
              | translate : { extraPPC: item.cost?.extraK, includedK: item.cost?.includedK })
            : ('private.configurationPage.assistantPage.costDescription2.singular'
              | translate : { extraPPC: item.cost?.extraK, includedK: item.cost?.includedK })
        }}
      </p>
    </div>
  </div>
  } @case ('author') {
  <div class="flex mt-3">
    <div class="md:col-4 col-12">
      <img [src]="item.creator?.image" class="w-full border-circle" style="aspect-ratio: 1" />
    </div>
    <div class="md:col-8 col-12">
      <h3>{{ item.creator?.name }}</h3>
      <p class="my-0">
        <span class="font-semibold">{{ 'private.dashboardPage.marketplaceSection.productDetailsModal.creatorId' | translate }} </span
        >{{ item.creator?.id }}
      </p>
    </div>
  </div>
  } @case ('previews') {
  <div class="flex flex-wrap gap-3 justify-content-center mt-3">
    @if (item.storeCard?.previewImages?.length) { @for (preview of item.storeCard?.previewImages; track $index) {
    <p-image class="md:w-3 sm:w-6 w-full" styleClass="image-preview" [src]="preview" [preview]="true" appendTo="body"></p-image>
    } } @else {
    <div class="h-15rem flex justify-content-center align-items-center">
      <h4 class="my-0 text-gray-400 font-italic">{{ 'private.dashboardPage.marketplaceSection.productDetailsModal.noPreview' | translate }}</h4>
    </div>
    }
  </div>
  } }
  <div class="flex justify-content-end gap-2 mt-3">
    <p-button
      [severity]="item.using ? 'success' : 'help'"
      size="small"
      styleClass="w-full"
      [loading]="item.loaders.isAdding"
      [label]="
        item.using
          ? ('private.dashboardPage.marketplaceSection.alreadyUsing' | translate)
          : ('private.dashboardPage.marketplaceSection.use' | translate)
      "
      (onClick)="closeModal('useNow')"
    ></p-button>
    <p-button
      severity="info"
      size="small"
      styleClass="w-full"
      [label]="
        item.using
          ? ('private.dashboardPage.marketplaceSection.assistantAdded' | translate)
          : ('private.dashboardPage.marketplaceSection.addAssistant' | translate)
      "
      [loading]="item.loaders.isAdding"
      [disabled]="item.using"
      (onClick)="closeModal('add')"
    ></p-button>
  </div>
</div>
