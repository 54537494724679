import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AgencyService } from 'src/app/main/services/agency.service';

@Component({
  selector: 'app-select-agency-modal',
  templateUrl: './select-agency-modal.component.html',
  styleUrl: './select-agency-modal.component.scss',
})
export class SelectAgencyModalComponent implements OnInit {
  @Output() agencySelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() idAgency: string = '';
  displayModal: boolean = false;
  selectedAgency: any;
  agencies: any[] = [];

  constructor(private readonly agencyService: AgencyService, private readonly router: Router) {}

  ngOnInit(): void {
    this.agencyService.getAgencyList().subscribe((agencies) => {
      this.agencies = agencies.items;
      this.selectedAgency = this.agencies[0];
      this.agencySelected.emit(this.selectedAgency);
    });
  }

  openAgencySelectionModal() {
    this.displayModal = true;
  }

  onAgencySelected(event: any) {
    this.displayModal = false;
    this.agencySelected.emit(event);
  }
}
