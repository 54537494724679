<div class="flex pb-2 mt-4" [class]="buttonAlignment">
  <button
    pButton
    pRipple
    type="button"
    [label]="'private.configurationPage.marketingToolsPage.leadGenerationFormModal.addControl' | translate"
    class="p-button-outlined"
    [disabled]="!isEnabled"
    (click)="showDialogForAddNewControl()"
  ></button>
</div>
<p-table
  [value]="inputsForTable"
  styleClass="p-datatable-gridlines p-datatable-sm mt-2"
  [ngClass]="{ disabled: !isEnabled }"
  [tableStyle]="{ 'min-width': '50rem' }"
>
  <!-- <ng-template pTemplate="caption"> Header </ng-template> -->
  <ng-template pTemplate="header">
    <tr>
      <th>{{ 'private.configurationPage.marketingToolsPage.leadGenerationFormModal.headers.label' | translate }}</th>
      <th>{{ 'private.configurationPage.marketingToolsPage.leadGenerationFormModal.headers.type' | translate }}</th>
      <th>{{ 'private.configurationPage.marketingToolsPage.leadGenerationFormModal.headers.required' | translate }}</th>
      <th>{{ 'private.configurationPage.marketingToolsPage.leadGenerationFormModal.headers.actions' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-control>
    <tr>
      <td>{{ control.label | titlecase }}</td>
      <td>{{ control.type | titlecase }}</td>
      <td>{{ (isRequired(control) ? 'common.yes' : 'common.no') | translate | uppercase }}</td>
      <td>
        <!-- <button
          pButton
          pRipple
          type="button"
          size="small"
          icon="pi pi-check"
          class="p-button-rounded p-button-outlined"
          [ngStyle]="{ color: control.enabled ? '#06b6d4' : '#64748b' }"
          (click)="enabledInput(control.id)"
        ></button> -->
        <button
          pButton
          pRipple
          type="button"
          size="small"
          icon="pi pi-trash"
          class="p-button-danger p-button-sm"
          [pTooltip]="'private.configurationPage.marketingToolsPage.leadGenerationFormModal.removeControl' | translate"
          tooltipPosition="top"
          [disabled]="!isEnabled"
          (click)="removeControl(control.label)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="999" class="p-4 text-center font-semibold font-italic">
        {{ 'private.configurationPage.marketingToolsPage.leadGenerationFormModal.noControls' | translate }}
      </td>
    </tr>
  </ng-template>
  <!-- <ng-template pTemplate="summary"> Footer </ng-template> -->
</p-table>
