<div class="flex gap-3 align-items-center">
  @if (tags.length) { @for (tag of tags; track $index) {
  <p-tag class="source-tag" [style]="{ background: '#' + tag.color }">
    <span class="flex gap-2 align-items-center p-2">
      <p class="my-0">{{ tag.label }}</p>
      @if (showDelete) {
      <span class="flex justify-content-end align-items-center">
        <i
          class="pi pi-times-circle cursor-pointer w-fit"
          pTooltip="Elimina tag"
          tooltipPosition="bottom"
          (click)="deleteTag(tag)"
          style="font-size: 1rem"
        ></i>
      </span>
      }
    </span>
  </p-tag>
  } } @else {
  <div>{{ 'private.sourceDetailsPage.document.noTags' | translate }}</div>
  } @if (showAdd) {
  <i class="pi pi-plus-circle text-xl flex align-items-center w-fit cursor-pointer" (click)="addTag()" tooltipPosition="bottom"></i>
  }
  <!-- <p-inplace [active]="inplaceActive" (onActivate)="onInplaceActivate()" (onDeactivate)="onInplaceDeactivate()" class="flex align-items-center p-0">
    <ng-template pTemplate="display">
    </ng-template>
    <ng-template pTemplate="content">
      <p-inputGroup>
        <input pInputText #tagAdd class="border-noround-right" placeholder="Tag" [(ngModel)]="newTag" (keydown.escape)="onInplaceDeactivate()" />
        <p-inputGroupAddon class="input-group-colorpicker">
          <p-colorPicker styleClass="color-picker" [(ngModel)]="tagColor"></p-colorPicker>
        </p-inputGroupAddon>
      </p-inputGroup>
      <button pButton class="p-button-success" icon="pi pi-check" [disabled]="!newTag || !tagColor"></button>
    </ng-template>
  </p-inplace> -->
</div>
