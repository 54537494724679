import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ThemeService } from 'src/app/main/services/theme.service';
import { IBotThemes } from '../../models/bot-themes.model';

@Component({
  selector: 'app-configure-chat-modal',
  templateUrl: './configure-chat-modal.component.html',
  styleUrls: ['./configure-chat-modal.component.scss'],
})
export class ConfigureChatModalComponent {
  id: string = '';
  currentTheme?: IBotThemes;

  constructor(private readonly ref: DynamicDialogRef, private readonly config: DynamicDialogConfig, private readonly themeService: ThemeService) {
    this.id = this.config.data.botId;
    this.currentTheme = this.themeService.getTheme(this.id);
  }
}
