<div class="pt-2">
  <span class="flex flex-column gap-2">
    <label htmlFor="dropdown">{{ 'private.accountPage.subscriptionsTab.buyCreditsModal.dropdownLabel' | translate }}</label>
    <p-dropdown id="dropdown" [options]="credits" [(ngModel)]="currentCredits" optionValue="subscriptionId" styleClass="w-full" appendTo="body">
      <ng-template pTemplate="item" let-item>
        <span>{{
          'private.accountPage.subscriptionsTab.buyCreditsModal.credits'
            | translate
              : {
                  monthlyCredits: item.creditsNumber,
                  price: item.price | currency : 'USD' : '$'
                }
        }}</span>
      </ng-template>
      <ng-template pTemplate="selectedItem" let-item>
        <span>{{
          'private.accountPage.subscriptionsTab.buyCreditsModal.credits'
            | translate
              : {
                  monthlyCredits: item.creditsNumber,
                  price: item.price | currency : 'USD' : '$'
                }
        }}</span>
      </ng-template>
    </p-dropdown>
  </span>
  <div class="mt-4 flex justify-content-end gap-3">
    <button
      pButton
      [label]="'common.cancel' | translate"
      class="p-button-outlined p-button-danger"
      [loading]="isLoading"
      (click)="ref.close()"
    ></button>
    <button
      pButton
      [label]="'private.accountPage.subscriptionsTab.buyCreditsModal.purchase' | translate"
      class="p-button-success"
      [disabled]="!currentCredits"
      [loading]="isLoading"
      (click)="buyCredits()"
    ></button>
  </div>
</div>
