<span class="h-full flex flex-column justify-content-between">
  <span>
    <h3>In cosa è esperto l’assistente?</h3>
    <textarea
      pInputTextarea
      class="w-full"
      [(ngModel)]="creationWizard.expertise"
      placeholder="Testo libero dove si può descrivere in cosa è esperto l’assistente, es: Legge, Normativa Aziendale, Regolamenti Interni es:

      - Diritto italiano
      - Specializzato in ambiti civile
      - Penale e amministrativo
      "
      [autoResize]="true"
    ></textarea>
  </span>
  <div class="flex justify-content-end gap-3">
    <button pButton [disabled]="!creationWizard.expertise" label="Next" (click)="setExpertise()"></button>
  </div>
</span>
