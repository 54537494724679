import { Component } from '@angular/core';
import { AssistantCreationService } from 'src/app/main/services/assistant-creation.service';
import { AssistantCreationWizard } from 'src/app/main/shared/models/assistant-creation.model';

@Component({
  selector: 'app-internal-target-language',
  templateUrl: './internal-target-language.component.html',
  styleUrls: ['./internal-target-language.component.scss'],
})
export class InternalTargetLanguageComponent {
  creationWizard: AssistantCreationWizard = new AssistantCreationWizard();

  constructor(private readonly assistantCreationService: AssistantCreationService) {
    assistantCreationService.getAssistantCreationWizard$().subscribe({
      next: (v) => (this.creationWizard = v),
    });
  }

  setTargetLanguage() {
    this.assistantCreationService.setAssistantCreationWizard(this.creationWizard);
    this.assistantCreationService.setNextStep(true);
  }
}
