<span class="h-full flex flex-column justify-content-between">
  <span class="z-1" style="padding-bottom: 10rem">
    <h3>Riassunto:</h3>
    <p-accordion [multiple]="true">
      <p-accordionTab *ngFor="let recapProperty of recap | keyvalue" [header]="recapProperty.key | recapWizardHeader">
        {{ recapProperty.value | json }}
      </p-accordionTab>
    </p-accordion>
  </span>
  <div class="w-full flex justify-content-end absolute bottom-0 right-0 bg-white z-2" style="padding: 0.5rem 1.5rem 2rem 1.5rem">
    <button pButton label="Close wizard" class="p-button-success" (click)="closeModal()"></button>
  </div>
</span>
