import { Component, OnDestroy } from '@angular/core';
import { TabViewChangeEvent } from 'primeng/tabview';
import { ADD_KNOWLEDGE_BASE_TABS } from 'src/app/main/shared/constants/add-knowledge-base.consts';

@Component({
  selector: 'app-add-knowledge-modal',
  templateUrl: './add-knowledge-modal.component.html',
  styleUrls: ['./add-knowledge-modal.component.scss'],
})
export class AddKnowledgeModalComponent implements OnDestroy {
  readonly knowledgeBaseTabs = ADD_KNOWLEDGE_BASE_TABS;
  selectedTabIndex: number = 0;

  ngOnDestroy(): void {
    this.updateTabActiveState(this.selectedTabIndex, false);
  }

  tabChange(event: TabViewChangeEvent) {
    this.updateTabActiveState(this.selectedTabIndex, false);
    this.selectedTabIndex = event.index;
    this.updateTabActiveState(this.selectedTabIndex, true);
  }

  private updateTabActiveState(index: number, isActive: boolean) {
    if (this.knowledgeBaseTabs[index]?.inputs?.hasOwnProperty('active')) {
      this.knowledgeBaseTabs[index].inputs!['active'] = isActive;
    }
  }
}
