import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { intersection } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TablePageEvent } from 'primeng/table';
import { Observable, Subscription, distinctUntilChanged, finalize, forkJoin } from 'rxjs';
import { BotsService } from 'src/app/main/services/bots.service';
import { DataSourcesService } from 'src/app/main/services/data-sources.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { TagsService } from 'src/app/main/services/tags.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { MassiveTagsActionsModalComponent } from 'src/app/main/shared/components/massive-tags-actions-modal/massive-tags-actions-modal.component';
import { CRAWLING_METHODS } from 'src/app/main/shared/constants/crawling-methods.consts';
import { ACCESS_TYPES } from 'src/app/main/shared/constants/data-sources.consts';
import { ROWS_PER_PAGE_OPTIONS } from 'src/app/main/shared/constants/generic.consts';
import { NO_TAG_FILTER, REFRESH_PERIODS } from 'src/app/main/shared/constants/kb.consts';
import { ChatBotSource } from 'src/app/main/shared/models/chat-bot-source.model';
import { ChatBot } from 'src/app/main/shared/models/chat-bot.model';
import { DataVisibility } from 'src/app/main/shared/models/data-visibility.model';
import { FilterKbTag } from 'src/app/main/shared/models/kb-tags.model';
import { CrawlingMethod } from 'src/app/main/shared/types/crawling-method.type';
import { AddKnowledgeModalComponent } from './add-knowledge-modal/add-knowledge-modal.component';

@Component({
  selector: 'app-knowledge-base-table',
  templateUrl: './knowledge-base-table.component.html',
  styleUrls: ['./knowledge-base-table.component.scss'],
})
export class KnowledgeBaseTableComponent implements OnInit, OnDestroy {
  private botSubscription?: Subscription;
  readonly refreshPeriods = REFRESH_PERIODS;
  readonly accessTypes = ACCESS_TYPES;

  visibleAddWebsiteDialog: boolean = false;
  isEditingWebsite: boolean = false;
  editingSourceId: string = '';

  visibleAddDocumentDialog: boolean = false;
  isEditingDocument: boolean = false;
  showAllTags: boolean = false;

  idBot: string = '';
  sourceUrls: ChatBotSource[] = [];
  filteredSourceUrls: ChatBotSource[] = [];
  selectedSources: ChatBotSource[] = [];

  availableTags: FilterKbTag[] = [];
  filterTags: FilterKbTag[] = [];
  filterName: string = '';

  tableLoading: boolean = false;
  addLoading: boolean = false;
  hasFiltered: boolean = false;

  page: number = 0;
  rowsPerPage = ROWS_PER_PAGE_OPTIONS;
  items: number = this.rowsPerPage[0];

  constructor(
    private readonly router: Router,
    private readonly tagsService: TagsService,
    private readonly botsService: BotsService,
    private readonly toastService: ToastService,
    private readonly dialogService: DialogService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dataSourceService: DataSourcesService,
    private readonly confirmationService: ConfirmationService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.tableLoading = true;
    this.botSubscription = this.botsService.currentBot$.pipe(distinctUntilChanged((a, b) => a?.id === b?.id)).subscribe({
      next: (bot: ChatBot | undefined) => {
        if (bot?.id) {
          this.idBot = bot.id;
          this.getSources();
        }
      },
      error: () => (this.tableLoading = false),
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.botSubscription?.unsubscribe();
  }

  getSources() {
    this.tableLoading = true;
    this.dataSourceService.getAllDataSources(this.idBot).subscribe({
      next: (v) => {
        this.sourceUrls = v;
        this.tableLoading = false;
        this.getAvailableTags();
      },
      error: () => (this.tableLoading = false),
    });
  }

  goToDetailsSource(id: string) {
    this.router.navigate([id, 'data-sources'], { relativeTo: this.activatedRoute });
  }

  deleteSource(source: ChatBotSource) {
    this.confirmationService.confirm({
      header: this.mainLanguageService.instant('common.warning'),
      message: this.mainLanguageService.instant('private.knowledgePage.deleteModal.message'),
      accept: () => {
        this.tableLoading = true;
        source.setLoading(true);
        this.dataSourceService
          .deleteSource(this.idBot, source.id)
          .pipe(
            finalize(() => {
              source.setLoading(false);
              this.tableLoading = false;
            })
          )
          .subscribe({
            next: () => this.getSources(),
            error: ({ error }) => this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: error.message }),
          });
      },
      acceptButtonStyleClass: 'p-button-danger',
      acceptLabel: this.mainLanguageService.instant('private.knowledgePage.deleteModal.delete'),
      rejectButtonStyleClass: 'p-button-outlined',
      rejectLabel: this.mainLanguageService.instant('private.knowledgePage.deleteModal.keep'),
    });
  }

  openAddModal() {
    const modal = this.dialogService.open(AddKnowledgeModalComponent, {
      styleClass: 'md:w-10 w-full',
      header: this.mainLanguageService.instant('private.knowledgePage.addSourceModal.header'),
      data: {
        id: this.idBot,
      },
    });

    modal.onClose.subscribe({
      next: (res) => {
        if (res) {
          if (res.added) {
            this.toastService.addSuccess({
              summary: this.mainLanguageService.instant('common.success'),
              detail: this.mainLanguageService.instant('private.knowledgePage.addSourceModal.sourceAdded'),
            });
            this.getSources();
          } else if (res.error) {
            this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: res.error.message });
          }
        }
      },
    });
  }

  editSource(source: ChatBotSource) {
    if (source.description) {
      this.tableLoading = true;
      this.dataSourceService
        .updateSource(this.idBot, source.id, {
          description: source.description,
        })
        .pipe(finalize(() => (this.tableLoading = false)))
        .subscribe({
          next: () =>
            this.toastService.addSuccess({
              summary: this.mainLanguageService.instant('common.success'),
              detail: this.mainLanguageService.instant('private.knowledgePage.addSourceModal.sourceEdited'),
            }),
          error: ({ error }) => this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: error.message }),
        });
    }
  }

  getCrawlingMethod(method: CrawlingMethod) {
    return CRAWLING_METHODS.find((m) => m.value === method)?.label;
  }

  getAvailableTags() {
    this.tagsService.getAllTagsForBot$(this.idBot).subscribe({
      next: (v) => {
        const tags = v.map(
          (tag) => new FilterKbTag({ ...tag, sourcesCount: this.sourceUrls.flatMap((s) => s.tags).filter((t) => t.id === tag.id).length })
        );
        const noTag = NO_TAG_FILTER;
        noTag.sourcesCount = this.sourceUrls.map((s) => s.tags).filter((t) => !t.length).length;
        this.availableTags = [noTag, ...tags];
      },
    });
  }

  onPageChange(event: TablePageEvent) {
    this.page = event.first / event.rows;
    this.getSources();
  }

  onRowsChange() {
    this.page = 0;
    this.getSources();
  }

  onFilterTagChange() {
    this.checkIfNoFilter();
    this.page = 0;
    if (this.filterTags === null || !this.filterTags.length) {
      this.filterTags = [];
      this.filteredSourceUrls = this.sourceUrls;
    } else {
      const hasNoTag = this.filterTags.some((t) => t.id === 'no-tag');
      const otherTags = this.filterTags.filter((t) => t.id !== 'no-tag');

      this.filteredSourceUrls = this.sourceUrls.filter((source) => {
        const matchesNoTag = hasNoTag && source.tags.length === 0;
        const matchesOtherTags =
          intersection(
            source.tags.map((s) => s.id),
            otherTags.map((t) => t.id)
          ).length > 0;

        return matchesNoTag || matchesOtherTags;
      });
    }

    // Applica anche il filtro per nome
    this.applyNameFilter();
  }

  onFilterNameChange() {
    this.checkIfNoFilter();
    this.applyNameFilter();
  }

  applyNameFilter() {
    this.filteredSourceUrls = (this.filteredSourceUrls.length || this.filterTags.length ? this.filteredSourceUrls : this.sourceUrls).filter((s) =>
      s.description?.toLowerCase().includes(this.filterName.toLowerCase())
    );
  }

  checkIfNoFilter() {
    if ((this.filterTags === null || !this.filterTags.length) && !this.filterName) {
      this.filteredSourceUrls = [];
      this.getSources();
    }
  }

  deleteAllSourcesConfirm() {
    this.confirmationService.confirm({
      header: this.mainLanguageService.instant('common.warning'),
      message: this.mainLanguageService.instant('private.knowledgePage.deleteAllMessage'),
      acceptLabel: this.mainLanguageService.instant('common.delete'),
      rejectButtonStyleClass: 'p-button-outlined',
      rejectLabel: this.mainLanguageService.instant('common.keep'),
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => this.deleteSources(),
    });
  }

  deleteSources() {
    this.tableLoading = true;
    const calls: Observable<any>[] = [];
    this.selectedSources.forEach((s) => calls.push(this.dataSourceService.deleteSource(this.idBot, s.id)));

    forkJoin(calls)
      .pipe(
        finalize(() => {
          this.selectedSources = [];
          this.getSources();
        })
      )
      .subscribe();
  }

  openTagsMassiveActions() {
    this.dialogService
      .open(MassiveTagsActionsModalComponent, {
        showHeader: false,
        styleClass: 'md:w-7 w-full massive-dialog',
        data: {
          dataSources: this.selectedSources,
          botId: this.idBot,
        },
      })
      .onClose.subscribe({
        next: (v) => {
          if (v && v.updated) {
            this.selectedSources = [];
            this.getSources();
          }
        },
      });
  }

  getAccessType(visibilities: DataVisibility[] | null) {
    return visibilities?.length ? ACCESS_TYPES.find((a) => a['value'] === 'PRIVATE')?.label : '';
  }

  addSourceToSelected(source: ChatBotSource) {
    const existingSource = this.selectedSources.findIndex((s) => s.id === source.id);
    if (existingSource >= 0) {
      this.selectedSources.splice(existingSource, 1);
    } else {
      this.selectedSources.push(source);
    }
  }

  toggleTag(tag: FilterKbTag) {
    if (this.filterTags.includes(tag)) {
      this.filterTags.splice(this.filterTags.indexOf(tag), 1);
    } else {
      this.filterTags.push(tag);
    }

    this.onFilterTagChange();
  }

  getIsTagInFilter(tag: FilterKbTag) {
    return this.filterTags.includes(tag);
  }
}
