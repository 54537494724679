import { Component, OnDestroy } from '@angular/core';
import { intersection, uniqBy } from 'lodash';
import { ConfirmationService, MenuItem, PrimeIcons } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { Observable, combineLatest, finalize, iif } from 'rxjs';
import { DataSourcesService } from 'src/app/main/services/data-sources.service';
import { DataVisibilityService } from 'src/app/main/services/data-visibility.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { TagsService } from 'src/app/main/services/tags.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { ACCESS_TYPES } from '../../constants/data-sources.consts';
import { ChatBotSource, IUpdateSourceDTO } from '../../models/chat-bot-source.model';
import { DataVisibility } from '../../models/data-visibility.model';
import { KbTag } from '../../models/kb-tags.model';
import { ManageSourcePermissionsModalComponent } from '../manage-source-permissions-modal/manage-source-permissions-modal.component';

@Component({
  selector: 'app-massive-tags-actions-modal',
  templateUrl: './massive-tags-actions-modal.component.html',
  styleUrl: './massive-tags-actions-modal.component.scss',
})
export class MassiveTagsActionsModalComponent implements OnDestroy {
  readonly tabMenuItems: MenuItem[] = [
    {
      tabindex: 'tags',
      label: 'private.knowledgePage.massiveTagsActionsModal.tabs.tags',
    },
    {
      tabindex: 'access',
      label: 'private.knowledgePage.massiveTagsActionsModal.tabs.access',
    },
    {
      tabindex: 'validity',
      label: 'private.knowledgePage.massiveTagsActionsModal.tabs.validity',
    },
  ];
  readonly accessTypes = ACCESS_TYPES;
  activeMenuItem = this.tabMenuItems[0];

  validityPeriod: {
    from: Date | null;
    to: Date | null;
  } = {
    from: null,
    to: null,
  };

  botId: string;
  sources: ChatBotSource[];
  allTags: KbTag[] = [];
  commonTags: KbTag[] = [];
  currentTags: KbTag[] = [];

  commonVisibilities: DataVisibility[] = [];

  dataSourcesIds: string[] = [];

  loading: boolean = false;
  updated: boolean = false;
  updatingValidityFrom: boolean = false;
  isDeletingAllVisibilities: boolean = false;

  accessType: 'PUBLIC' | 'PRIVATE' = 'PUBLIC';

  constructor(
    public readonly ref: DynamicDialogRef,
    private readonly tagsService: TagsService,
    private readonly toastService: ToastService,
    private readonly config: DynamicDialogConfig,
    private readonly dialogService: DialogService,
    private readonly dataSourcesService: DataSourcesService,
    private readonly mainLanguageService: MainLanguageService,
    private readonly confirmationService: ConfirmationService,
    private readonly dataVisibilityService: DataVisibilityService
  ) {
    this.botId = this.config.data.botId;
    this.sources = this.config.data.dataSources;
    this.dataSourcesIds = this.sources.map((s) => s.id);

    this.commonVisibilities = this.getCommonVisibilities();
    this.accessType = this.sources.flatMap((s) => s.dataVisibilities).length ? 'PRIVATE' : 'PUBLIC';
    this.getBotTags();
  }

  ngOnDestroy(): void {
    this.ref.close({ updated: this.updated });
  }

  getBotTags(tag?: KbTag, deleted?: boolean) {
    this.loading = true;
    this.tagsService
      .getAllTagsForBot$(this.botId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (tags) => {
          this.allTags = uniqBy(tags, 'label');
          this.currentTags = this.getCommonTags();
          if (tag) {
            if (!deleted) this.currentTags.push(tag);
            else
              this.currentTags.splice(
                this.currentTags.findIndex((v) => v.id === tag.id),
                1
              );
            this.updated = true;
          }
          this.commonTags = intersection(tags, this.currentTags);
        },
      });
  }

  private getCommonTags(): KbTag[] {
    return this.sources
      .map((s) => s.tags)
      .reduce((elementiComuni, arrayCorrente) => {
        const idSet = new Set(arrayCorrente.map((obj) => obj.id));
        return elementiComuni.filter((obj) => idSet.has(obj.id));
      });
  }

  private getCommonVisibilities(): DataVisibility[] {
    // console.log(this.sources);
    // return (
    //   this.sources
    //     .map((s) => s.dataVisibilities)
    //     .reduce((elementiComuni, arrayCorrente) => {
    //       const idSet = new Set((arrayCorrente || []).map((obj) => obj.id));
    //       return (elementiComuni || []).filter((obj) => idSet.has(obj.id));
    //     }) || []
    // );
    const validElements = this.sources.filter((e) => e.dataVisibilities && e.dataVisibilities.length);
    if (validElements.length === 0) return [];

    return (
      validElements.reduce((common, element) => {
        return (common || []).filter((comune) => element!.dataVisibilities!.some((dv) => this.sameValue(comune, dv)));
      }, validElements[0].dataVisibilities) || []
    );
  }

  sameValue(dv1: DataVisibility, dv2: DataVisibility) {
    return dv1.user?.id === dv2.user?.id && dv1.group?.id === dv2.group?.id;
  }

  onChange(event: MultiSelectChangeEvent) {
    iif(
      () => !!(event.value as KbTag[]).find((t) => t.id === event.itemValue.id),
      this.tagsService.associateTag$(this.botId, event.itemValue.id, this.dataSourcesIds),
      this.tagsService.deAssociateTag$(this.botId, event.itemValue.id, this.dataSourcesIds)
    ).subscribe({
      next: () => {
        this.updated = true;

        this.getBotTags(event.itemValue);
      },
    });
  }

  updateValidityPeriod(remove: boolean = false) {
    const body: Partial<IUpdateSourceDTO> = {
      validityFrom: remove ? null : this.validityPeriod.from?.toISOString(),
      validityTo: remove ? null : this.validityPeriod.to?.toISOString(),
    };

    const calls: Observable<any>[] = [];

    this.updatingValidityFrom = true;

    this.sources.forEach((s) => calls.push(this.dataSourcesService.updateSource(this.botId, s.id, body)));

    combineLatest(calls)
      .pipe(finalize(() => (this.updatingValidityFrom = false)))
      .subscribe({
        next: () => {
          this.toastService.addSuccess({
            summary: this.mainLanguageService.instant('common.success'),
            detail: this.mainLanguageService.instant('private.knowledgePage.massiveTagsActionsModal.updatedValidity'),
          });
          this.updated = true;
        },
      });
  }

  getAccessType(value: 'PUBLIC' | 'PRIVATE') {
    return this.accessTypes.find((a) => a.value === value)!;
  }

  handleAccessButtonClick(event: MouseEvent, clickedValue: 'PUBLIC' | 'PRIVATE') {
    event.preventDefault();

    if (clickedValue === 'PUBLIC' && this.accessType === 'PRIVATE' && this.sources.flatMap((s) => s.dataVisibilities).length) {
      this.confirmationService.confirm({
        header: this.mainLanguageService.instant('common.warning'),
        message: this.mainLanguageService.instant('private.sourceDetailsPage.createAccess.changeTypeAlert'),
        acceptButtonStyleClass: 'p-button-danger',
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.isDeletingAllVisibilities = true;
          this.deleteAllGroups(clickedValue);
        },
      });
    } else {
      this.accessType = clickedValue;
    }
  }

  private deleteAllGroups(clickedValue: 'PUBLIC' | 'PRIVATE') {
    const calls: Observable<any>[] = [];
    this.sources.forEach((s) => s.dataVisibilities?.forEach((dv) => calls.push(this.dataVisibilityService.deleteVisibility$(s.id, dv.id))));
    combineLatest(calls)
      .pipe(
        finalize(() => {
          this.accessType = clickedValue;
          this.isDeletingAllVisibilities = false;
          this.updated = true;
        })
      )
      .subscribe();
  }

  openPermissionModal() {
    this.dialogService
      .open(ManageSourcePermissionsModalComponent, {
        showHeader: false,
        styleClass: 'md:w-6 w-full massive-dialog',
        header: this.mainLanguageService.instant('private.sourceDetailsPage.createAccess.header'),
        data: {
          source: this.sources,
        },
      })
      .onClose.subscribe({
        next: (v) => {
          if (v) {
            if (v.created)
              this.toastService.addSuccess({
                summary: this.mainLanguageService.instant('common.success'),
                detail: this.mainLanguageService.instant('private.sourceDetailsPage.createAccess.visibilityCreated'),
              });

            this.updated = true;
          }
        },
      });
  }

  deleteVisibility(id: string, visibilityId: string) {
    this.confirmationService.confirm({
      header: this.mainLanguageService.instant('common.warning'),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      message: this.mainLanguageService.instant('private.sourceDetailsPage.createAccess.deleteMessage'),
      acceptIcon: PrimeIcons.TRASH,
      acceptButtonStyleClass: 'p-button-danger',
      rejectIcon: PrimeIcons.TIMES,
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.isDeletingAllVisibilities = true;
        this.getVisibilityObservable(id)
          .pipe(finalize(() => (this.isDeletingAllVisibilities = false)))
          .subscribe({
            // next: () => this.onDelete.emit(true),
            next: () => {
              this.updated = true;
              this.commonVisibilities.splice(
                this.commonVisibilities.findIndex((v) => v.id === visibilityId),
                1
              );
            },
          });
      },
    });
  }

  private getVisibilityObservable(visibilityId: string) {
    return combineLatest(
      this.sources.map((source) => this.dataVisibilityService.deleteVisibility$(source.id, this.getVisibilityId(visibilityId, source)))
    );
  }

  getVisibilityId(id: string, source: ChatBotSource) {
    const type = source.dataVisibilities?.find((v) => v.group.id === id) ? 'group' : 'user';
    return type === 'group'
      ? source.dataVisibilities?.find((v) => v.group.id === id)?.id!
      : source.dataVisibilities?.find((v) => v.user.id === id)?.id!;
  }
}
