import { AddestramentoComponent } from '../components/starting-guide/addestramento/addestramento.component';
import { CreazioneComponent } from '../components/starting-guide/creazione/creazione.component';
import { UtilizzoComponent } from '../components/starting-guide/utilizzo/utilizzo.component';
import { IStartingGuideSteps } from '../models/starting-guide-steps.model';

export const STARTING_GUIDE_STEPS: IStartingGuideSteps[] = [
  {
    itemId: 0,
    label: 'Creazione',
    component: CreazioneComponent,
  },
  {
    itemId: 1,
    label: 'Addestramento',
    component: AddestramentoComponent,
  },
  {
    itemId: 2,
    label: 'Utilizzo',
    component: UtilizzoComponent,
  },
];
