import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BotsService } from 'src/app/main/services/bots.service';
import { ChatBot } from '../../models/chat-bot.model';
import { ApiKeyService } from 'src/app/main/services/api-key.service';
import { APIKey } from '../../models/api-key.model';
import { Subscription, distinctUntilChanged, finalize } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateApiKeyModalComponent } from '../create-api-key-modal/create-api-key-modal.component';
import { MainLanguageService } from 'src/app/main/services/main-language.service';

@Component({
  selector: 'app-api-key-table',
  templateUrl: './api-key-table.component.html',
  styleUrls: ['./api-key-table.component.scss'],
})
export class ApiKeyTableComponent implements OnDestroy {
  private bot: ChatBot = new ChatBot();
  apiKeys: APIKey[] = [];

  loading: boolean = true;

  botSubscription?: Subscription;

  constructor(
    private readonly router: Router,
    public apiKeyService: ApiKeyService,
    private readonly botsService: BotsService,
    private readonly dialogService: DialogService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.botSubscription = this.botsService.currentBot$.pipe(distinctUntilChanged((b, c) => b?.id === c?.id)).subscribe({
      next: (bot) => {
        if (bot) {
          this.bot = bot;
          this.getApiKeys();
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.botSubscription?.unsubscribe();
  }

  getApiKeys() {
    this.apiKeyService
      .getApiKeys$(this.bot.companyId, this.bot.id)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (v) => (this.apiKeys = v),
      });
  }

  createApiKey() {
    this.dialogService
      .open(CreateApiKeyModalComponent, {
        header: this.mainLanguageService.instant('private.configurationPage.integrationsPage.header'),
        styleClass: 'md:w-6 w-full',
        data: {
          assistantId: this.bot.id,
          companyId: this.bot.company.id,
        },
      })
      .onClose.subscribe({
        next: (v) => {
          if (v && v.created) {
            this.openIntegrationPage(v.id);
          }
        },
      });
  }

  openIntegrationPage(id?: string) {
    const path = ['/', 'private', this.bot.type === 'ASSISTANT' ? 'o' : '', 'assistants', this.bot?.id, 'api-key'];
    if (id) path.push(id);
    this.router.navigate(path, {
      queryParams: {
        companyId: this.bot.company.id,
      },
    });
  }
}
