import { Component } from '@angular/core';

@Component({
  selector: 'app-training-status-badge',
  templateUrl: './training-status-badge.component.html',
  styleUrls: ['./training-status-badge.component.scss']
})
export class TrainingStatusBadgeComponent {

}
