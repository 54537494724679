import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { tap } from 'rxjs';
import { MainLanguageService } from '../../services/main-language.service';

@Directive({
  selector: '[highlightWord]',
})
export class HighightWordDirective implements OnChanges {
  @Input('highlightWord') word?: string = '';
  @Input() textContent: string = '';
  @Input() searched: boolean = true;
  @Input() isAssistant?: boolean;

  private assistantWord: string = '';
  private youWord: string = '';

  constructor(private el: ElementRef<HTMLElement>, private readonly mainLanguageService: MainLanguageService) {
    this.mainLanguageService.defaultLanguage$
      .pipe(
        tap(() => {
          this.assistantWord = this.mainLanguageService.instant('private.dashboardPage.assistant');
          this.youWord = this.mainLanguageService.instant('private.dashboardPage.you');
        })
      )
      .subscribe({
        next: () => this.highlightWord(),
      });
  }

  ngOnChanges(): void {
    if (this.searched) {
      this.highlightWord();
    }
  }

  private highlightWord() {
    this.word = this.word?.trim();
    let classes = this.el.nativeElement.className;
    classes = classes.replace('px-3', '').replace('pt-3', '').replace('pb-3', '');

    if (this.textContent && this.word) {
      let newText = this.textContent.trim();

      newText = this.getMention(newText);

      (this.word as string).split(' ').forEach((word) => {
        const highlightRegex = new RegExp(this.escapeRegExp(word), 'gi');
        newText = newText.replace(highlightRegex, (match) => {
          return match.toLowerCase() === word.toLowerCase() ? `<span class="highlight ${classes}">${match}</span>` : match;
        });
      });

      this.el.nativeElement.innerHTML = `${this.isAssistant !== undefined ? (this.isAssistant ? this.assistantWord : this.youWord) : ''}${newText}`;
    }
  }

  private getMention(string: string) {
    const regex = /<assistant\s+id="([^"]*)"\s+type="([^"]*)">([^<]+)<\/assistant>/g;
    string = string.replace(regex, (match, assistantId, assistantType, assistantName) => {
      return `@${assistantName}`;
    });
    return string;
  }

  private escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}
