<p-dropdown
  [options]="languages"
  (onChange)="onValueChange($event)"
  [(ngModel)]="defaultLanguage"
  styleClass="lang-dropdown w-full"
  optionDisabled="disabled"
>
  <ng-template pTemplate="item" let-item>
    <div class="flex justify-content-start gap-3 align-items-center">
      <img [src]="item.flag" class="flag" />
      <p>{{ item.label }}</p>
    </div>
  </ng-template>
  <ng-template pTemplate="selectedItem" let-item>
    <div class="flex justify-content-start gap-3 align-items-center">
      <img [src]="item.flag" class="flag" />
      <p>{{ item.label }}</p>
    </div>
  </ng-template>
</p-dropdown>
