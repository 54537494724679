import { Directive, ElementRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appAppendToBody]',
})
export class AppendToBodyDirective {
  constructor(private readonly elementRef: ElementRef<HTMLDivElement>, private viewContainer: ViewContainerRef) {
    // document.body.append(elementRef.nativeElement);
  }
}
