<div class="m-0">
  <form [formGroup]="botForm" (ngSubmit)="createBot()">
    <div class="flex flex-column gap-2 mb-3">
      <label htmlFor="name">{{ 'private.dashboardPage.addBotModal.name' | translate }}</label>
      <input pInputText id="name" formControlName="name" />
    </div>
    <div class="flex flex-column gap-2 mb-3">
      <label htmlFor="description">{{ 'private.dashboardPage.addBotModal.description' | translate }}</label>
      <input pInputText id="description" formControlName="description" />
    </div>

    <div class="flex flex-column gap-2 mb-3">
      <label htmlFor="model">{{ 'private.dashboardPage.addBotModal.model' | translate }}</label>

      <p-dropdown
        ngDefaultControl
        formControlName="model"
        name="model"
        appendTo="body"
        styleClass="w-full"
        [group]="true"
        [lazy]="true"
        [options]="groupedBotModels"
        optionLabel="name"
        optionValue="internalId"
        optionDisabled="disabled"
      >
        <ng-template let-group pTemplate="group">
          <span>{{ group.label }}</span>
        </ng-template>
      </p-dropdown>
    </div>

    <!-- <div class="flex flex-column gap-2">
        <label htmlFor="status">Status</label>
        <input pInputText id="status" formControlName="status" />
      </div> -->
    <!-- <div class="flex flex-column gap-2 mb-3">
      <label htmlFor="domain">Domain</label>
      <input pInputText id="domain" formControlName="domain" />
    </div> -->
    <div class="text-right mt-5">
      <p-button
        type="submit"
        class="large-button"
        [label]="'private.dashboardPage.addBotModal.create' | translate"
        [disabled]="botForm.invalid"
        [loading]="loading"
      ></p-button>
    </div>
  </form>
</div>
