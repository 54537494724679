import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { BotsService } from 'src/app/main/services/bots.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { STARTER_MESSAGE_DEFAULT_VALUE } from '../../constants/bot.consts';
import { LanguagesEnum } from '../../enums/app-languages.enum';
import { IChatBot, ICreateBotDTO } from '../../models/chat-bot.model';
import { AIModel } from '../../models/ai-models.model';
import { AiModelsService } from 'src/app/main/services/ai-models.service';
import { groupBy } from 'lodash';
import { getGroupedBotModels } from '../../functions/utilities.functions';

@Component({
  selector: 'app-add-bot-dialog',
  templateUrl: './add-bot-dialog.component.html',
  styleUrls: ['./add-bot-dialog.component.scss'],
})
export class AddBotDialogComponent {
  botModels: AIModel[] = [];

  botForm: FormGroup = new FormGroup({});
  botVendor?: string = '';
  language: string = LanguagesEnum.EN.toUpperCase();
  loading: boolean = false;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly formBuilder: FormBuilder,
    private readonly botsService: BotsService,
    private readonly toastService: ToastService,
    private readonly aiModelService: AiModelsService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.aiModelService.getAllModels$().subscribe({
      next: (models) => {
        this.botModels = models;
        this.botVendor = this.botModels[0].vendor?.internalId || '';
        this.buildForm();
      },
    });
  }

  buildForm() {
    this.botForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      model: new FormControl(this.botModels[0].internalId, [Validators.required]),
      // domain: new FormControl(''),
    });

    this.botForm.get('model')?.valueChanges.subscribe({
      next: (v: string) => {
        const model = this.getBotModelObject(v);
        this.botVendor = model?.vendor?.internalId;
      },
    });
  }

  createBot() {
    const body: Partial<ICreateBotDTO> = {
      name: this.botForm.value.name,
      description: this.botForm.value.description,
      language: this.language,
      status: 'NEW',
      companyId: '1',
      vendor: this.botVendor,
      model: this.botForm.value.model,
      replyMode: 'open',
      // domain: this.botForm.value.domain,
      starterMessage: this.mainLanguageService.instant(STARTER_MESSAGE_DEFAULT_VALUE),
    };
    this.loading = true;
    this.botsService
      .createBot(body)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.emitClose(res);
        },
        error: ({ error }) => {
          this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: error.message });
        },
      });
  }

  emitClose(chatBot?: IChatBot) {
    this.ref.close(chatBot);
  }

  getBotModelObject(v: string) {
    return this.botModels.find((c) => c.internalId === v);
  }

  get groupedBotModels() {
    return getGroupedBotModels(this.botModels);
  }
}
