import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { IStarterMessageHistory, StarterMessageHistory } from '../shared/models/chat-bot-history.model';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatBotHistoryService {
  constructor(private readonly apiService: ApiService) {}

  getChatBotHistory$(botId: string) {
    return this.apiService
      .get<IStarterMessageHistory[]>(`chat-bots/${botId}/history`)
      .pipe(map((array) => array.map((message) => new StarterMessageHistory(message))));
  }

  applyStarterMessage$(botId: string, starterMessage: string) {
    return this.apiService.patch<any>(`chat-bots/${botId}`, { starterMessage });
  }
}
