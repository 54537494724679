import { DateTime } from 'luxon';

interface IExportPeriods {
  label: string;
  value: string;
  start: Date;
  end: Date;
}

export const EXPORT_PERIODS: IExportPeriods[] = [
  {
    label: 'currentMonth',
    value: 'currentMonth',
    start: DateTime.now().minus({ months: 1 }).toJSDate(),
    end: DateTime.now().toJSDate(),
  },
  {
    label: 'lastMonth',
    value: 'lastMonth',
    start: DateTime.now().minus({ months: 2 }).toJSDate(),
    end: DateTime.now().minus({ months: 1 }).toJSDate(),
  },
  {
    label: 'custom',
    value: 'custom',
    start: DateTime.now().minus({ month: 1 }).toJSDate(),
    end: DateTime.now().toJSDate(),
  },
];

export const EXPORT_FORMATS = [
  {
    label: 'CSV',
    value: 'csv',
  },
  {
    label: 'XLSX',
    value: 'xlsx',
  },
  {
    label: 'JSON',
    value: 'json',
  },
];
