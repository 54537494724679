<div class="flex justify-content-between align-items-center mb-4">
  <h4 class="my-0">{{ 'private.configurationPage.integrationsPage.title' | translate }}</h4>
  <button pButton icon="pi pi-plus" [label]="'private.configurationPage.integrationsPage.addButton' | translate" (click)="createApiKey()"></button>
</div>

<p-table [value]="apiKeys" styleClass="p-datatable-gridlines" [loading]="loading">
  <ng-template pTemplate="header">
    <tr>
      <th>{{ 'private.configurationPage.integrationsPage.headers.name' | translate }}</th>
      <th>{{ 'private.configurationPage.integrationsPage.headers.creationDate' | translate }}</th>
      <th>{{ 'private.configurationPage.integrationsPage.headers.limitations' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-integration>
    <tr class="cursor-pointer integration-row" (click)="openIntegrationPage(integration.id)">
      <td>{{ integration.name }}</td>
      <td>{{ integration.createdAt.toJSDate() | date : 'longDate' }}</td>
      <td>
        {{
          'private.configurationPage.integrationsPage.limitations.' + integration.restrictionType.toLowerCase() + '.plural' | translate | uppercase
        }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="999" class="p-4 text-center font-semibold font-italic">{{ 'private.configurationPage.integrationsPage.noKeys' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
