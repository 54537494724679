import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { CompanyGroup, CompanyGroupUser, ICompanyGroup, ICompanyGroupUser, ICreateUserGroupDTO } from '../shared/models/users-and-groups.model';

@Injectable({
  providedIn: 'root',
})
export class UsersAndGroupsService {
  constructor(private readonly apiService: ApiService) {}

  getGroups$(page: number, items: number, searchTerm: string) {
    return this.apiService
      .get<{ items: ICompanyGroup[]; total: number }>(`manage/groups?page=${page}&items=${items}${searchTerm ? '&search=' + searchTerm : ''}`)
      .pipe(map(({ items, total }) => ({ items: items.map((group) => new CompanyGroup(group)), total })));
  }

  getUsers$(page: number, items: number, searchTerm: string) {
    return this.apiService
      .get<{ items: ICompanyGroupUser[]; total: number }>(`manage/users?page=${page}&items=${items}${searchTerm ? '&search=' + searchTerm : ''}`)
      .pipe(map(({ items, total }) => ({ items: items.map((user) => new CompanyGroupUser(user)), total })));
  }

  createGroup$(body: ICreateUserGroupDTO) {
    return this.apiService.post<any>(`manage/groups`, body);
  }

  updateGroup$(groupId: string, body: Partial<ICreateUserGroupDTO>) {
    return this.apiService.patch<any>(`manage/groups/${groupId}`, body);
  }

  deleteGroup$(groupId: string) {
    return this.apiService.delete(`manage/groups/${groupId}`);
  }

  deleteUserFromGroup$(group: CompanyGroup, userId: string) {
    const ids = group.users?.map((u) => u.id);
    ids?.splice(
      ids.findIndex((v) => v === userId),
      1
    );
    if (ids?.length) {
      return this.updateGroup$(group.id, { userIds: ids });
    } else {
      return this.deleteGroup$(group.id);
    }
  }
}
