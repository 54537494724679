import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BOT_USER_PERMISSIONS_OPTIONS } from '../../models/users-and-access.model';
import { AssistantUse, AssistantUsePermission } from '../../models/assistant-use.model';
import { AssistantUsersService } from 'src/app/main/services/assistant-users.service';
import { Observable, finalize, forkJoin } from 'rxjs';

@Component({
  selector: 'app-change-users-permission-modal',
  templateUrl: './change-users-permission-modal.component.html',
  styleUrl: './change-users-permission-modal.component.scss',
})
export class ChangeUsersPermissionModalComponent {
  readonly permissionOptions = BOT_USER_PERMISSIONS_OPTIONS;
  newPermission?: AssistantUsePermission;

  isEditing: boolean = false;
  botId: string;
  users: AssistantUse[] = [];

  constructor(
    private readonly config: DynamicDialogConfig,
    private readonly ref: DynamicDialogRef,
    private readonly assistantUseService: AssistantUsersService
  ) {
    this.botId = this.config.data.botId;
    this.users = this.config.data.users;
  }

  updatePermissions() {
    const calls: Observable<any>[] = [];
    this.users.forEach((au) =>
      calls.push(this.assistantUseService.editAssistantUse$(this.botId, au.userId, au.id, { permission: this.newPermission, archived: false }))
    );

    this.isEditing = true;
    forkJoin(calls)
      .pipe(finalize(() => (this.isEditing = false)))
      .subscribe({
        next: () => this.ref.close({ edited: true }),
      });
  }
}
