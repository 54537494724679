<div class="mt-5">
  <p class="text-gray-400">{{ 'private.configurationPage.usersAndAccessPage.changePermissionsModal.selectPermission' | translate }}</p>
  <p-dropdown [options]="permissionOptions" optionValue="value" [(ngModel)]="newPermission" styleClass="w-full">
    <ng-template pTemplate="item" let-item>
      {{ 'private.configurationPage.usersAndAccessPage.permissionsType.' + item.label | translate }}
    </ng-template>
    <ng-template pTemplate="selectedItem" let-item>
      {{ 'private.configurationPage.usersAndAccessPage.permissionsType.' + item.label | translate }}
    </ng-template>
  </p-dropdown>
  <p class="text-gray-400 mt-4">{{ 'private.configurationPage.usersAndAccessPage.changePermissionsModal.interestedUsers' | translate }}</p>
  <ul>
    @for (user of users; track $index) {
    <li>{{ user.firstName + ' ' + user.lastName }}</li>
    }
  </ul>
  <div class="col-12 flex justify-content-end">
    <button pButton [label]="'common.update' | translate" (click)="updatePermissions()" [loading]="isEditing" [disabled]="!newPermission"></button>
  </div>
</div>
