import { Type } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AssistantType } from '../enums/assistant-creation-type.enum';
import { AssistantOrganization } from './assistant-organization.model';

export interface AssistantWizardStepsItem extends MenuItem {
  pipeKey: keyof AssistantCreationWizard | 'recap';
  component: Type<any>;
}

export class AssistantCreationWizard {
  type?: AssistantType;
  character: string = '';
  organization: AssistantOrganization = new AssistantOrganization();
  expertise?: string;
  targetAudience: string = '';
  targetLanguage?: string;
  replyExtraDetails?: any;
  weDo: Set<string> = new Set();
  weDont: Set<string> = new Set();

  constructor() {}

  setType(type: AssistantType) {
    this.type = type;
  }

  setWeDo(newItem: string) {
    this.weDo.add(newItem);
  }

  removeWeDo(item: string) {
    this.weDo.delete(item);
  }

  setWeDont(newItem: string) {
    this.weDont.add(newItem);
  }

  removeWeDont(item: string) {
    this.weDont.delete(item);
  }

  getRecap(keys: (keyof AssistantCreationWizard)[]) {
    let recapObject: {
      [key: string]: any;
    } = {};
    keys.forEach((k) => {
      recapObject[k] = this[k];
    });

    return recapObject;
  }
}
