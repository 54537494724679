<div
  class="flex flex-column latest-assistants-sidebar justify-content-between align-items-center h-full max-w-20rem"
  [@sidebarExpand]="sidebarState"
  [ngClass]="{ 'sidebar-collapsed': !isExpanded, 'sidebar-expanded': isExpanded }"
>
  <span class="flex flex-column align-items-center assistant-list overflow-x-hidden">
    <div class="w-full p-3">
      <p-iconField class="sidebar-assistant-search">
        <p-inputIcon styleClass="pi pi-search" />
        <input
          pInputText
          class="w-full"
          [placeholder]="'common.search' | translate"
          [(ngModel)]="searchTerm"
          (ngModelChange)="onSearchTermChange()"
        />
      </p-iconField>
    </div>

    @if(!isLoadingAssistants) { @if (!hasSearched) { @for (assistant of assistants | slice: 0: BOT_SHOW_LIMIT; track $index) {
    <div
      (click)="onAssistantClick(assistant)"
      class="assistant-item"
      [pTooltip]="getIsALastChat(assistant) ? 'You\'re back to your previous conversation' : 'Start a new chat now!'"
      [ngClass]="{ 'active-item': getIsActiveAssistant(assistant) }"
      tooltipPosition="right"
    >
      <div class="assistant-image-container">
        <img
          fallbackImage
          class="border-circle border-1"
          [src]="
            assistant.type === 'MARKETPLACE'
              ? imageUuidService.getMarketplaceImageUrlFromUUID(assistant, 'H')
              : imageUuidService.getImageUrlFromUUID(assistant, assistant.imageUuid, 'H')
          "
        />
      </div>
      @if (isExpanded) {
      <div class="assistant-info" [@infoExpand]="sidebarState">
        <span class="flex align-items-center">
          <span class="col-9 p-0">
            <p class="font-bold">{{ assistant.name }}</p>
          </span>
          <span class="col-3 p-0 flex h-full justify-content-end align-items-start">
            <p class="text-xs">{{ assistant.lastUseTimestamp | lastMessage : true | async }}</p>
          </span>
        </span>
        <p class="text-sm assistant-desc">{{ assistant.description }}</p>
      </div>
      }
    </div>
    } } @else { @if (filteredAssistants.length) { @for (assistant of filteredAssistants | slice: 0: BOT_SHOW_LIMIT; track $index) {
    <div
      (click)="onAssistantClick(assistant)"
      class="assistant-item"
      [pTooltip]="getIsALastChat(assistant) ? 'You\'re back to your previous conversation' : 'Start a new chat now!'"
      [ngClass]="{ 'active-item': getIsActiveAssistant(assistant) }"
      tooltipPosition="right"
    >
      <div class="assistant-image-container">
        <img
          fallbackImage
          class="border-circle border-1"
          [src]="
            assistant.type === 'MARKETPLACE'
              ? imageUuidService.getMarketplaceImageUrlFromUUID(assistant, 'H')
              : imageUuidService.getImageUrlFromUUID(assistant, assistant.imageUuid, 'H')
          "
        />
      </div>
      @if (isExpanded) {
      <div class="assistant-info" [@infoExpand]="sidebarState">
        <span class="flex align-items-center">
          <span class="col-9 p-0">
            <p class="font-bold">{{ assistant.name }}</p>
          </span>
          <span class="col-3 p-0 flex h-full justify-content-end align-items-start">
            <p class="text-xs">{{ assistant.lastUseTimestamp | lastMessage : true | async }}</p>
          </span>
        </span>
        <p class="text-sm assistant-desc">{{ assistant.description }}</p>
      </div>
      }
    </div>
    } } @else {
    <div class="w-full text-center flex align-items-center justify-content-center" style="height: 92%">
      {{ 'private.dashboardPage.noAssistant' | translate }}
    </div>
    } } } @else { @for (skeleton of SKELETONS_ARRAY; track $index) {
    <div class="assistant-item">
      <div class="assistant-image-container">
        <p-skeleton shape="circle" size="3rem"></p-skeleton>
      </div>
    </div>
    } }
    <div [ngClass]="{ 'w-full flex justify-content-end': isExpanded }">
      @if (hasSearched ? filteredAssistants.length > BOT_SHOW_LIMIT : assistants.length > BOT_SHOW_LIMIT) { @if (!isExpanded) {

      <div class="more-assistants-indicator">
        <span class="circular-badge cursor-pointer" (click)="onSeeAllClick()">
          +{{ hasSearched ? filteredAssistants.length - BOT_SHOW_LIMIT : assistants.length - BOT_SHOW_LIMIT }}
        </span>
      </div>
      } @else {
      <div class="flex w-full mt-4 gap-2 align-items-center font-semibold cursor-pointer see-all-button" (click)="onSeeAllClick()">
        <p class="my-0">
          {{ 'private.assistantPage.seeAllAssistants' | translate }}
        </p>
        <i class="pi pi-arrow-right text-sm w-auto"></i>
      </div>
      } }
    </div>
  </span>
  <div class="px-3 pb-5 expand-div">
    <custom-icon
      [type]="'material'"
      [iconName]="!isExpanded ? 'left_panel_open' : 'left_panel_close'"
      class="flex cursor-pointer"
      (click)="toggleExpand()"
    ></custom-icon>
  </div>
</div>
