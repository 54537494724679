import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-leave-edited-page-alert',
  templateUrl: './leave-edited-page-alert.component.html',
  styleUrl: './leave-edited-page-alert.component.scss',
})
export class LeaveEditedPageAlertComponent {
  constructor(public readonly ref: DynamicDialogRef) {}
}
