<form [formGroup]="websiteForm" class="grid gap-5 my-4 mx-2" (ngSubmit)="addWebsite()">
  <p-radioButton
    *ngFor="let method of crawlingMethods"
    [value]="method"
    [label]="method.label | translate"
    formControlName="crawlingMethod"
  ></p-radioButton>
  <span class="w-full">
    <p class="text-gray-400 font-italic">{{ getCrawlingMethodDescription() | translate }}</p>
  </span>
  <span class="w-full">
    <label for="url" class="mb-2">Url</label>
    <input pInputText id="url" class="w-full" [pattern]="regUrl" formControlName="url" />
  </span>
  <span *ngIf="getIsSitemapCrawling()" class="w-full">
    <label for="sitemap" class="mb-2">Sitemap</label>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon" style="min-width: fit-content" [ngClass]="{ 'w-fit px-2': getUrl() }">{{ getUrl() + '/' }}</span>
      <input pInputText id="sitemap" class="w-full" [pattern]="notUrlReg" formControlName="sitemap" />
    </div>
  </span>
  <div class="flex w-full justify-content-center gap-5">
    <button
      pButton
      pRipple
      type="button"
      class="p-button-warning"
      *ngIf="getIsSitemapCrawling()"
      [loading]="testLoading"
      [disabled]="websiteForm.invalid"
      (click)="addWebsite(true)"
      [label]="'private.knowledgePage.addSourceModal.url.testSitemap' | translate"
    ></button>
    <button
      pButton
      pRipple
      type="submit"
      [loading]="addLoading"
      [disabled]="websiteForm.invalid"
      [label]="'private.knowledgePage.addSourceModal.url.add' | translate"
    ></button>
  </div>
</form>
