import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ChatBot } from '../shared/models/chat-bot.model';
import { MarketplaceItem } from '../shared/models/marketplace.model';

export type ImageFormat = 'L' | 'M' | 'H';

@Injectable({
  providedIn: 'root',
})
export class ImageUuidService {
  // private todayTimestamp = DateTime.now().toMillis();
  /* @todo - Riabilitare quando il backend funziona con imageUuid */
  private urlEnv: 'prod' | 'dev' = environment.production ? 'prod' : 'dev';

  constructor() {
    // interval(60000)
    //   .pipe(tap(() => (this.todayTimestamp = DateTime.now().toMillis())))
    //   .subscribe();
  }

  getImageUrlFromUUID(bot: ChatBot, uuid: string | null, format: ImageFormat) {
    if (uuid) return `https://cdn.pupau.ai/assistants/${this.urlEnv}/${bot.id}/${uuid}-${format}.jpg`;
    else return '';
  }

  getMarketplaceImageUrlFromUUID(marketplaceBot: any, format: ImageFormat) {
    if (marketplaceBot.imageUuid)
      return `https://cdn.pupau.ai/marketplace/assistants/${this.urlEnv}/${marketplaceBot.id}/${marketplaceBot.imageUuid}-${format}.jpg`;
    else return '';
  }
}
