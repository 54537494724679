import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { PipelinesService } from 'src/app/main/services/pipelines.service';
import { ToastService } from 'src/app/main/services/toast.service';

@Component({
  selector: 'app-create-pipeline-modal',
  templateUrl: './create-pipeline-modal.component.html',
  styleUrl: './create-pipeline-modal.component.scss',
})
export class CreatePipelineModalComponent {
  createPipelineForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  isCreating: boolean = false;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly toastService: ToastService,
    private readonly pipelinesService: PipelinesService,
    private readonly mainLanguageService: MainLanguageService
  ) {}

  createPipeline() {
    const name = this.createPipelineForm.getRawValue().name;

    this.isCreating = true;

    this.pipelinesService
      .createPipeline$(name!)
      .pipe(finalize(() => (this.isCreating = false)))
      .subscribe({
        next: (id) => {
          this.ref.close({ id });
        },
        error: ({ error }) => {
          this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: error.message });
        },
      });
  }
}
