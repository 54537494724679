import { Input, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[fallbackImage]',
})
export class FallbackImageDirective {
  @Input() fallbackImage: string = 'https://cdn.pupau.ai/assets/logo.webp';
  constructor(private readonly ref: ElementRef) {}

  @HostListener('error')
  handleImageError() {
    const element: HTMLImageElement = this.ref.nativeElement as HTMLImageElement;
    element.src = 'https://cdn.pupau.ai/assets/logo.webp';
  }
}
