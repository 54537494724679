import { Component, Input } from '@angular/core';
import { AssistantCreationWizard, AssistantWizardStepsItem } from '../../../models/assistant-creation.model';
import { INTERNAL_KB_STEPS } from '../../../constants/assistant-creation-wizard/internal-kb-steps.const';
import { AssistantCreationService } from 'src/app/main/services/assistant-creation.service';

@Component({
  selector: 'app-internal-kb',
  templateUrl: './internal-kb.component.html',
  styleUrls: ['./internal-kb.component.scss'],
})
export class InternalKbComponent {
  readonly steps = INTERNAL_KB_STEPS;
  @Input() creationWizard?: AssistantCreationWizard;

  activeItem?: AssistantWizardStepsItem;

  activeIndex: number = 0;

  constructor(private readonly assistantCreationService: AssistantCreationService) {
    assistantCreationService.getNextStep$().subscribe({
      next: (v) => {
        if (v) {
          this.activeIndex++;
        } else {
          this.activeIndex--;
        }
      },
    });
  }

  ngOnInit(): void {
    this.setActiveItem();
  }

  setActiveItem() {
    this.activeItem = this.steps[this.activeIndex];
  }
}
