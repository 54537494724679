import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'datestringToDate',
})
export class DatestringToDatePipe implements PipeTransform {
  transform(value: string, format: string): string {
    const date = DateTime.fromISO(value);
    return date.toFormat(format);
  }
}
