<nav class="layout-breadcrumb">
  <ol>
    @for (item of breadcrumbs$ | async; track $index; let last = $last) {
    <span>
      <li [routerLink]="item.routerLink || undefined" [ngClass]="{ 'cursor-pointer': item.routerLink }">{{ item.label | translate }}</li>
    </span>
    @if (!last) {
    <li class="layout-breadcrumb-chevron">
      <custom-icon type="material" iconName="chevron_right"></custom-icon>
    </li>
    } }
  </ol>
</nav>
