import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, iif, of, switchMap } from 'rxjs';
import { AuthState, selectUser } from 'src/app/core/store/auth';
import { environment } from 'src/environments/environment';
import { BotsService } from '../../services/bots.service';

@Directive({
  selector: '[hideIfNoBot]',
})
export class HideIfNoBotDirective implements OnDestroy {
  private subscription?: Subscription;
  private elseTemplateRef: TemplateRef<any> | null = null;
  private hasPrimaryView: boolean = false;
  private hasElseView: boolean = false;
  private levelUser = environment.level.user.level;

  private readonly user$ = this.store.select(selectUser);

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly botsService: BotsService,
    private readonly store: Store<{ auth: AuthState }>
  ) {}

  @Input() set hideIfNoBot(role: number) {
    this.setupSubscription(role);
  }

  @Input() set hideIfNoBotElse(templateRef: TemplateRef<any> | null) {
    this.elseTemplateRef = templateRef;
    this.showCorrectView();
  }

  private setupSubscription(role: number) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.user$
      .pipe(switchMap((user) => iif(() => (user?.company?.level ?? this.levelUser) >= role, this.botsService.bots$, of(null))))
      .subscribe((bots) => {
        this.updateView(bots);
      });
  }

  private updateView(bots: any) {
    if (bots && bots.length > 0) {
      this.showPrimaryView();
    } else {
      this.showElseView();
    }
  }

  private showPrimaryView() {
    if (!this.hasPrimaryView) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasPrimaryView = true;
      this.hasElseView = false;
    }
  }

  private showElseView() {
    if (!this.hasElseView && this.elseTemplateRef) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.elseTemplateRef);
      this.hasPrimaryView = false;
      this.hasElseView = true;
    }
  }

  private showCorrectView() {
    if (this.hasPrimaryView) {
      this.showPrimaryView();
    } else {
      this.showElseView();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.viewContainer.clear();
  }
}
