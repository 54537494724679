import { animate, state, style, transition, trigger } from '@angular/animations';

export const latestAssistantAnim = trigger('sidebarExpand', [
  state(
    'collapsed',
    style({
      width: '5rem',
    })
  ),
  state(
    'expanded',
    style({
      width: '20rem',
    })
  ),
  transition('collapsed <=> expanded', [animate('0.3s ease-in-out')]),
]);

export const infoExpandAnim = trigger('infoExpand', [
  state(
    'collapsed',
    style({
      opacity: 0,
      transform: 'translateX(-20px)',
    })
  ),
  state(
    'expanded',
    style({
      opacity: 1,
      transform: 'translateX(0)',
    })
  ),
  transition('collapsed <=> expanded', [animate('0.2s ease-in-out')]),
]);
