import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, characters: number = 50): string {
    if (value) {
      return value.length <= 50 ? value : `${value.slice(0, characters)}...`;
    } else {
      return '';
    }
  }
}
