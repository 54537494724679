<form *ngIf="formControl" [formGroup]="parentForm" class="w-full flex justify-content-between md:flex-row flex-column md:gap-0 gap-4">
  <div class="flex md:w-5 w-full flex flex-column">
    @if (!loadingAssistants) {
    <h4>{{ 'private.configurationPage.assistantPage.aiModel' | translate }}</h4>
    <p>{{ 'private.configurationPage.assistantPage.aiModelDescription' | translate }}</p>
    <p-dropdown
      ngDefaultControl
      formControlName="model"
      name="model"
      appendTo="body"
      styleClass="w-full"
      [formControl]="formControl"
      [group]="true"
      [options]="getGroupedBotModels()"
      [lazy]="true"
      optionLabel="name"
      optionValue="internalId"
      optionDisabled="disabled"
      (onChange)="patchVendor($event)"
    >
      <ng-template let-group pTemplate="group">
        <span>
          <p class="my-0">{{ group.label }}</p>
        </span>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <span [ngClass]="{ 'flex align-items-center justify-content-between': item.disabled }">
          <p class="my-0" [ngClass]="{ 'p-disabled': item.disabled }">{{ item.name }}</p>
          @if (item.disabled) {
          <p-tag [value]="'Coming soon...'" severity="warning"></p-tag>
          }
        </span>
      </ng-template>
      <ng-template let-item pTemplate="selectedItem">
        <span>
          <p class="my-0" [ngClass]="{ 'p-disabled': item.disabled }">{{ item.name }}</p>
        </span>
      </ng-template>
    </p-dropdown>
    } @else {
    <span class="flex flex-column left-skeleton">
      <p-skeleton styleClass="text-skeleton"></p-skeleton>
      <p-skeleton styleClass="text-skeleton"></p-skeleton>
      <p-skeleton styleClass="dropdown-skeleton"></p-skeleton>
    </span>
    }
  </div>
  <div class="flex md:w-5 w-full flex-column gap-4">
    @if (!loadingAssistants) {
    <div class="flex w-full flex-row">
      <div class="md:w-9 w-full flex flex-column text-gray-700">
        <h5 class="mb-0 text-gray-700">{{ botModelObject?.name }}</h5>
        <p class="font-semibold mb-0">{{ botModelObject?.vendor?.name | uppercase }}</p>
        <!-- <p class="font-semibold mb-0">
          {{ 'private.configurationPage.assistantPage.context' | translate }}: {{ botModelObject?.content | uppercase }}
        </p> -->
        <!-- <p class="font-semibold mb-0">
          {{ 'private.configurationPage.assistantPage.output' | translate }}: {{ botModelObject?.maxOutput | uppercase }}
        </p> -->
      </div>
      <div class="md:w-2 w-4">
        <img class="w-full" [src]="botModelObject?.vendor?.logo" />
      </div>
    </div>
    <p class="mb-0">{{ botModelObject?.description }}</p>
    <p class="mb-0">
      <span class="font-semibold text-gray-700">{{ 'private.configurationPage.assistantPage.cost' | translate }}: </span
      >{{
        botModelObject?.cost !== 1
          ? ('private.configurationPage.assistantPage.costDescription1.plural'
            | translate : { price: botModelObject?.cost, includedK: botModelObject?.includedK })
          : ('private.configurationPage.assistantPage.costDescription1.singular'
            | translate : { price: botModelObject?.cost, includedK: botModelObject?.includedK })
      }}
      <sup class="pi pi-info-circle text-blue-400 cursor-pointer text-xs"></sup>.
      {{
        botModelObject?.costExtraK !== 1
          ? ('private.configurationPage.assistantPage.costDescription2.plural'
            | translate : { extraPPC: botModelObject?.costExtraK, includedK: botModelObject?.includedK })
          : ('private.configurationPage.assistantPage.costDescription2.singular'
            | translate : { extraPPC: botModelObject?.costExtraK, includedK: botModelObject?.includedK })
      }}
    </p>
    } @else {
    <span class="flex flex-column right-skeleton">
      <div class="flex w-full flex-row justify-content-between">
        <div class="md:w-9 w-full flex flex-column gap-2 text-gray-700 mb-4">
          <p-skeleton styleClass="text-skeleton"></p-skeleton>
          <p-skeleton styleClass="text-skeleton"></p-skeleton>
          <!-- <p class="font-semibold mb-0">
            {{ 'private.configurationPage.assistantPage.context' | translate }}: {{ botModelObject?.content | uppercase }}
          </p> -->
          <!-- <p class="font-semibold mb-0">
            {{ 'private.configurationPage.assistantPage.output' | translate }}: {{ botModelObject?.maxOutput | uppercase }}
          </p> -->
        </div>
        <div class="flex align-items-center">
          <p-skeleton shape="circle" styleClass="img-skeleton"></p-skeleton>
        </div>
      </div>
      <p-skeleton styleClass="text-skeleton mb-4"></p-skeleton>
      <p-skeleton styleClass="text-skeleton mb-4"></p-skeleton>
    </span>
    }
  </div>
</form>
