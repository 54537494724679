import { Directive, ElementRef, HostListener } from '@angular/core';
import { InputTextarea } from 'primeng/inputtextarea';

@Directive({
  selector: '[ctrlEnterNewline]',
})
export class CtrlEnterNewlineDirective {
  constructor(private readonly element: ElementRef<InputTextarea>) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
    } else if (event.key === 'Enter' && event.shiftKey) {
      const textarea = event.target as HTMLTextAreaElement;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const value = textarea.value;

      textarea.value = value.substring(0, startPos) + '\n' + value.substring(endPos);
      textarea.selectionStart = textarea.selectionEnd = startPos + 1;

      textarea.dispatchEvent(new Event('input'));

      event.preventDefault();
    }
  }
}
