import { Directive, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[blockCopyPaste]',
})
export class BlockCopyPasteDirective {
  private readonly production = environment.production;
  constructor() {}

  @HostListener('paste', ['$event'])
  @HostListener('copy', ['$event'])
  @HostListener('cut', ['$event'])
  blockCutCopyPaste(e: KeyboardEvent) {
    // if (this.production) e.preventDefault();
  }
}
