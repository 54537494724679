import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import localeIt from '@angular/common/locales/it';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import localePl from '@angular/common/locales/pl';
import localeSq from '@angular/common/locales/sq';
import localeTr from '@angular/common/locales/tr';

@Injectable({
  providedIn: 'root',
})
export class DynamicLocaleService {
  private readonly angularLocales = [localeIt, localeEn, localeFr, localeEs, localePl, localeSq, localeTr];

  private localeSubject$ = new BehaviorSubject<string>('en-US');

  constructor() {
    this.registerAllLocales();
  }

  get locale$() {
    return this.localeSubject$.asObservable();
  }

  set locale(locale: string) {
    this.localeSubject$.next(locale);
  }

  get locale(): string {
    return this.localeSubject$.getValue();
  }

  private registerAllLocales() {
    this.angularLocales.forEach((locale) => {
      registerLocaleData(locale);
    });
  }
}
