import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, PrimeIcons } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { finalize, tap } from 'rxjs';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { UsersAndGroupsService } from 'src/app/main/services/users-and-groups.service';
import { CompanyGroup, CompanyGroupUser, ICreateUserGroupDTO } from '../../models/users-and-groups.model';
import { minArrayLengthValidator } from '../../validators/array-min-length.validator';

@Component({
  selector: 'app-create-users-group-modal',
  templateUrl: './create-users-group-modal.component.html',
  styleUrl: './create-users-group-modal.component.scss',
})
export class CreateUsersGroupModalComponent {
  @ViewChild('usersMultiselect') multiselect?: MultiSelect;
  users: CompanyGroupUser[] = [];
  createGroupForm: FormGroup;

  currentGroup?: CompanyGroup;

  loadingUsers: boolean = false;
  loading: boolean = false;

  multiselectHasFocus: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly ref: DynamicDialogRef,
    private readonly toastService: ToastService,
    private readonly config: DynamicDialogConfig,
    private readonly confirmationService: ConfirmationService,
    private readonly mainLanguageService: MainLanguageService,
    private readonly usersAndGroupsService: UsersAndGroupsService
  ) {
    this.currentGroup = this.config.data.group;
    this.multiselectHasFocus = this.config.data.focusDropdown;

    this.createGroupForm = this.fb.group({
      name: new FormControl(this.currentGroup?.name, [Validators.required]),
      userIds: new FormControl(<string[]>[], [minArrayLengthValidator(1)]),
    });

    this.getUsers();
  }

  getUsers() {
    this.loadingUsers = true;
    this.usersAndGroupsService
      .getUsers$(0, 1000, '')
      .pipe(
        tap(({ items }) => (this.users = items)),
        tap(() => {
          if (this.currentGroup) {
            this.createGroupForm.patchValue({
              name: this.currentGroup.name,
              userIds: this.currentGroup.users?.map((u) => u.id),
            });
            if (this.multiselectHasFocus) this.multiselect?.show();
          }
        }),
        finalize(() => (this.loadingUsers = false))
      )
      .subscribe();
  }

  createGroup() {
    const body = this.createGroupForm.getRawValue() as ICreateUserGroupDTO;

    this.getCorrectAPICall(body)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => this.ref.close({ created: !!!this.currentGroup, edited: !!this.currentGroup }),
        error: ({ error }) => this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: error.message }),
      });
  }

  private getCorrectAPICall(body: ICreateUserGroupDTO) {
    if (!!this.currentGroup) {
      return this.usersAndGroupsService.updateGroup$(this.currentGroup!.id, body);
    } else {
      return this.usersAndGroupsService.createGroup$(body);
    }
  }

  deleteGroup() {
    if (this.currentGroup)
      this.confirmationService.confirm({
        header: this.mainLanguageService.instant('common.warning'),
        icon: PrimeIcons.EXCLAMATION_TRIANGLE,
        message: this.mainLanguageService.instant('private.accountPage.groupsTab.addGroupModal.deleteConfirmation'),
        acceptButtonStyleClass: 'p-button-danger',
        acceptIcon: PrimeIcons.TRASH,
        rejectIcon: PrimeIcons.TIMES,
        accept: () =>
          this.usersAndGroupsService.deleteGroup$(this.currentGroup!.id).subscribe({
            next: () => this.ref.close({ deleted: true }),
          }),
      });
  }
}
