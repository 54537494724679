import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[stripHtml]',
})
export class StripHtmlDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('input') onInput() {
    const textarea = this.elementRef.nativeElement;
    textarea.value = textarea.value.replace(/<(?:.|\n)*?>/gm, '');
  }
}
