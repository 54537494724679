import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { convertToCSV, downloadCSV } from '../shared/functions/utilities.functions';
import { IExportChatDTO } from '../shared/models/export-chat.model';
import { ChatBot } from '../shared/models/chat-bot.model';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class ExportChatService {
  constructor(private readonly http: HttpClient, private readonly apiService: ApiService) {}

  exportChat$(botId: string, botName: string, body: IExportChatDTO) {
    return this.http
      .get<any>(
        `${environment.BASE_URL}/chat-bots/${botId}/conversations/export?evaluation=${body.evaluation}&from=${body.from}&to=${body.to}&messages=${body.messages}&format=${body.format}`,
        { responseType: body.format?.toUpperCase() === 'CSV' ? ('text' as any) : ('blob' as any) }
      )
      .pipe(
        tap((value) => {
          console.log(value);
          switch (body.format?.toUpperCase()) {
            case 'CSV':
              const csv = convertToCSV(value);
              downloadCSV(csv, `${botName}-${DateTime.now().toLocaleString()}`);
              break;
            case 'XLSX':
              const blobXlsx = new Blob([value], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              saveAs(blobXlsx, `${botName}-${DateTime.now().toLocaleString()}.xlsx`);
              break;
            case 'JSON':
              const blobJson = new Blob([value], { type: 'application/json' });
              saveAs(blobJson, `${botName}-${DateTime.now().toLocaleString()}.json`);
          }
        })
      );
  }

  exportSingleChat$(bot: ChatBot, conversationId: string, conversationTitle: string) {
    return this.http
      .get(`${environment.BASE_URL}/${bot.type === 'ASSISTANT' ? 'chat-bots' : 'marketplace'}/${bot.id}/conversations/${conversationId}`, {
        headers: {
          accept: 'application/pdf',
        },
        responseType: 'blob' as any,
      })
      .pipe(
        tap((value) => {
          console.log(value);
          const blob = new Blob([value], { type: 'application/pdf' });
          saveAs(blob, `${bot.name}-${conversationTitle}.pdf`);
        })
      );
  }
}
