import { Component } from '@angular/core';

@Component({
  selector: 'app-functionality-not-available',
  templateUrl: './functionality-not-available.component.html',
  styleUrls: ['./functionality-not-available.component.scss']
})
export class FunctionalityNotAvailableComponent {

}
