<form
  *ngIf="element"
  [formGroup]="parentForm"
  class="flex flex-column w-full gap-3 base-input-element"
  [ngClass]="{ 'custom-text': customTextClass }"
>
  <div
    class="w-full flex"
    [ngClass]="{
      'flex-column gap-3': FULL_WIDTH_ELEMENTS.includes(element.type),
      'flex-row align-items-center': !FULL_WIDTH_ELEMENTS.includes(element.type)
    }"
  >
    <ng-container *ngIf="element.type === 'image'">
      <img
        class="cursor-pointer my-2 mr-4 md:w-2 w-full img-selector border-circle"
        [src]="getImgUrl()"
        (click)="fileupload.click()"
        [pTooltip]="element.tooltip?.message ?? '' | translate"
        style="aspect-ratio: 1"
        [tooltipPosition]="element.tooltip?.position"
      />
      <input #fileupload type="file" class="hidden" accept="image/*" id="profileImage" (change)="updateImage($event)" />
    </ng-container>
    <div class="flex flex-row p-0" [ngClass]="{ 'col-10': element.type !== 'image', 'w-6': element.type === 'image' }">
      <div *ngIf="element.enabled !== undefined && !element.enabled" class="flex disabled-feature align-items-center justify-content-start">
        <custom-icon
          [type]="'material'"
          [iconName]="'unpublished'"
          [class]="'unpublished-icon text-md cursor-default'"
          [tooltip]="'private.baseInputElement.notEnabled' | translate"
          [tooltipPosition]="'bottom'"
        ></custom-icon>
      </div>
      <div class="col-12 p-0 flex-column" [ngClass]="{ 'col-11': element.enabled !== undefined && !element.enabled }">
        <h4 class="input-title font-semibold mb-2">{{ element.title | translate }}</h4>
        <p *ngIf="!HIDE_DESCRIPTION_ELEMENTS.includes(element.type)" class="mb-1">{{ element.description | translate }}</p>
        <p *ngIf="element.component && getValue()" class="configure w-fit" (click)="emitClick()">
          {{ 'private.baseInputElement.configure' | translate }} {{ element.title | translate | lowercase }}
        </p>
        <p *ngIf="element.type === 'select-button'" class="mt-2 font-italic text-gray-400">
          {{ getFormControlValue().description | translate }}
        </p>
      </div>
    </div>
    <div
      class="flex p-0"
      [ngClass]="{
        'w-full align-items-center': FULL_WIDTH_ELEMENTS.includes(element.type),
        'justify-content-end align-items-center col-2': !FULL_WIDTH_ELEMENTS.includes(element.type),
        'md:flex-row flex-column gap-3 align-items-start': element.type === 'radio'
      }"
    >
      <ng-container [ngSwitch]="element.type">
        <p-inputSwitch
          *ngSwitchCase="'switch'"
          [name]="element.formControlName"
          [formControl]="formControl"
          ngDefaultControl
          [pTooltip]="element.tooltip?.message ?? '' | translate"
          (onChange)="onValueChange.emit($event)"
          [tooltipPosition]="element.tooltip?.position"
        ></p-inputSwitch>
        <input
          *ngSwitchCase="'input'"
          class="w-full"
          pInputTextarea
          [name]="element.formControlName"
          [formControl]="formControl"
          ngDefaultControl
          [pTooltip]="element.tooltip?.message ?? '' | translate"
          [tooltipPosition]="element.tooltip?.position"
        />
        <textarea
          *ngSwitchCase="'textarea'"
          class="w-full"
          pInputTextarea
          [name]="element.formControlName"
          [formControl]="formControl"
          [autoResize]="true"
          ngDefaultControl
          [pTooltip]="element.tooltip?.message ?? '' | translate"
          [tooltipPosition]="element.tooltip?.position"
        ></textarea>
        <p-dropdown
          *ngSwitchCase="'dropdown'"
          ngDefaultControl
          [name]="element.formControlName"
          [formControl]="formControl"
          [options]="element.options"
          appendTo="body"
          optionValue="value"
          class="w-6"
          styleClass="w-full"
          [placeholder]="placeholder | translate"
          [pTooltip]="element.tooltip?.message ?? '' | translate"
          [tooltipPosition]="element.tooltip && element.tooltip.position ? element.tooltip.position : 'top'"
        >
          <ng-template pTemplate="item" let-item>{{ item.label | translate }}</ng-template>
          <ng-template pTemplate="selectedItem" let-item>{{ item.label | translate }}</ng-template>
        </p-dropdown>
        <p-selectButton
          *ngSwitchCase="'select-button'"
          ngDefaultControl
          [options]="element.options"
          [formControl]="formControl"
          optionLabel="name"
          [pTooltip]="element.tooltip?.message ?? '' | translate"
          [tooltipPosition]="element.tooltip?.position"
        ></p-selectButton>
        <ng-container *ngSwitchCase="'radio'">
          <div
            *ngFor="let option of element.options"
            [pTooltip]="element.tooltip?.message ?? '' | translate"
            [tooltipPosition]="element.tooltip?.position"
            class="mr-4 flex align-items-center gap-2"
          >
            <p-radioButton
              [inputId]="option.label"
              [value]="option.value"
              [name]="element.formControlName"
              [formControl]="formControl"
            ></p-radioButton>
            <label [for]="option.label">{{ option.label | translate }}</label>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'checkbox'">
          <div class="flex align-items-center gap-3">
            <p-checkbox
              [id]="element.id"
              [name]="element.formControlName"
              ngDefaultControl
              [formControlName]="element.formControlName"
              [formControl]="formControl"
              [binary]="true"
              [label]="element.description | translate"
              [pTooltip]="element.tooltip?.message ?? '' | translate"
              [tooltipPosition]="element.tooltip?.position"
            ></p-checkbox>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</form>
