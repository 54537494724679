<div class="flex flex-column gap-3">
  <span>
    <p class="text-gray-400">{{ 'private.configurationPage.usersAndAccessPage.changePermissionsModal.selectPermission' | translate }}</p>
    <p-dropdown [options]="permissionOptions" optionValue="value" [(ngModel)]="permission" styleClass="w-full" appendTo="body">
      <ng-template pTemplate="item" let-item>
        {{ 'private.configurationPage.usersAndAccessPage.permissionsType.' + item.label | translate }}
      </ng-template>
      <ng-template pTemplate="selectedItem" let-item>
        {{ 'private.configurationPage.usersAndAccessPage.permissionsType.' + item.label | translate }}
      </ng-template>
    </p-dropdown>
  </span>
  <span>
    <p class="text-gray-400">{{ 'private.configurationPage.usersAndAccessPage.changePermissionsModal.selectUsers' | translate }}</p>
    <div class="h-20rem">
      @if(!isLoadingUsers) { @if(!getEveryUserAlreadyAdded()) {
      <p class="text-red-400">{{ 'private.configurationPage.usersAndAccessPage.changePermissionsModal.everyUserAlreadyAdded' | translate }}</p>
      }
      <p-iconField iconPosition="right" styleClass="w-full">
        <input
          pInputText
          [(ngModel)]="searchTerm"
          (ngModelChange)="filterUsers()"
          class="w-full"
          [placeholder]="'private.configurationPage.usersAndAccessPage.changePermissionsModal.searchPlaceholder' | translate"
        />
        @if(hasFiltered) {
        <p-inputIcon styleClass="pi pi-times cursor-pointer" (click)="searchTerm = ''; filterUsers()"></p-inputIcon>
        }
      </p-iconField>
      <div class="flex flex-column flex-wrap gap-2 overflow-x-scroll h-full mt-3">
        @if(hasFiltered && !filteredUsers.length) {
        <div class="flex h-full justify-content-center align-items-center">
          <p class="my-0 font-semibold text-gray-400 font-italic">
            {{ 'private.configurationPage.usersAndAccessPage.changePermissionsModal.noUserFound' | translate }}
          </p>
        </div>
        } @for (user of hasFiltered ? filteredUsers : users; track $index) {
        <div class="md:w-7 w-full">
          <p-checkbox [label]="user.fullName" [value]="user" [(ngModel)]="selectedUsers" [disabled]="getIsUserAlreadyAdded(user)"></p-checkbox>
        </div>
        }
      </div>
      } @else {
      <div class="h-full flex justify-content-center align-items-center">
        <p-progressSpinner></p-progressSpinner>
      </div>
      }
    </div>
  </span>
  <div class="flex justify-content-end">
    <button
      pButton
      [label]="'common.add' | translate"
      [loading]="isCreating"
      [disabled]="!permission || !selectedUsers.length"
      (click)="createAssistantUse()"
    ></button>
  </div>
</div>
