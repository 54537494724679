import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-starting-modal',
  templateUrl: './starting-modal.component.html',
  styleUrls: ['./starting-modal.component.scss'],
})
export class StartingModalComponent {
  constructor(private readonly ref: DynamicDialogRef) {}

  closeModal() {
    this.ref.close();
  }
}
