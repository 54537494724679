import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function minArrayLengthValidator(minLength: number): ValidatorFn {
  return (control: AbstractControl<any[]>): ValidationErrors | null => {
    if (!control.value || control.value.length < minLength) {
      return { minArrayLength: { requiredLength: minLength, actualLength: control.value ? control.value.length : 0 } };
    }
    return null;
  };
}
