import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { InvitationsService } from 'src/app/main/services/invitations.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { ILevel, LEVELS } from '../../constants/levels.consts';
import { ICreateInvitationDTO } from '../../models/invitation.model';

@Component({
  selector: 'app-invite-users-modal',
  templateUrl: './invite-users-modal.component.html',
  styleUrl: './invite-users-modal.component.scss',
})
export class InviteUsersModalComponent {
  readonly levels = LEVELS;
  invitationForm: FormGroup;

  isLoading: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly ref: DynamicDialogRef,
    private readonly toastService: ToastService,
    private readonly invitationService: InvitationsService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.invitationForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      level: new FormControl(<ILevel | null>null, [Validators.required]),
    });
  }

  onFormSubmit() {
    const body = this.buildBody();
    this.isLoading = true;
    this.invitationService
      .createInvitation$(body)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.ref.close(true);
        },
        error: ({ error }) => this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: error.message }),
      });
  }

  private buildBody(): ICreateInvitationDTO {
    const rawValue = this.invitationForm.getRawValue();
    return {
      name: rawValue.name,
      surname: rawValue.surname,
      email: rawValue.email,
      level: rawValue.level,
    };
  }
}
