<span class="h-full flex flex-column justify-content-between">
  <span>
    <h3>Il tuo assistente avrà un carattere:</h3>

    <span class="flex gap-3 flex-column">
      <p-radioButton
        *ngFor="let character of characters"
        [value]="character.value"
        [label]="character.label"
        [(ngModel)]="creationWizard.character"
        (keydown.enter)="nextStep()"
      ></p-radioButton>
    </span>
  </span>

  <span class="flex justify-content-end">
    <button pButton label="Next" (click)="nextStep()" [disabled]="!creationWizard.character"></button>
  </span>
</span>
