<div class="flex flex-column">
  <p>{{ 'private.buySubscriptionPage.promoCodeModal.message' | translate }}</p>
  <input pInputText [(ngModel)]="promoCode" [placeholder]="'private.buySubscriptionPage.promoCodeModal.promoCode' | translate" />
  <div class="flex justify-content-end gap-3 mt-5">
    <button
      pButton
      [label]="'common.cancel' | translate"
      class="p-button-outlined p-button-danger"
      icon="pi pi-times"
      [loading]="isLoading"
      (click)="ref.close()"
    ></button>
    <button
      pButton
      [label]="'private.buySubscriptionPage.promoCodeModal.confirm' | translate"
      class="p-button-success"
      icon="pi pi-check"
      [loading]="isLoading"
      (click)="stripeCheckout()"
    ></button>
  </div>
</div>
