<span class="h-full flex flex-column justify-content-between">
  <span [ngSwitch]="activeOrganizationSection">
    <span *ngSwitchCase="0">
      <h3>Di che tipo è la tua organizzazione?</h3>
      <span class="flex gap-3 flex-column">
        <p-radioButton
          *ngFor="let type of organizationTypes"
          [label]="type.label"
          [value]="type.value"
          [(ngModel)]="organization.type"
          (keydown.enter)="setCreationWizardOrganization()"
        ></p-radioButton>
      </span>
    </span>
    <span *ngSwitchCase="1">
      <h3>Come si chiama la tua {{ getOrganizationTypeLabel() | titlecase }}?</h3>
      <input class="w-full" pInputText [(ngModel)]="organization.name" autofocus (keydown.enter)="setCreationWizardOrganization()" />
    </span>
    <span *ngSwitchCase="2">
      <h3>{{ organization.name | titlecase }} si occupa di...</h3>
      <textarea
        class="w-full"
        pInputTextarea
        [(ngModel)]="organization.description"
        autofocus
        (keydown.enter)="setCreationWizardOrganization()"
      ></textarea>
    </span>
  </span>
  <div class="flex justify-content-end gap-3">
    <button pButton class="p-button-outlined" [disabled]="activeOrganizationSection === 0" label="Torna indietro" (click)="goBack()"></button>
    <button
      pButton
      [disabled]="isNextButtonDisabled()"
      [label]="activeOrganizationSection === 2 ? 'Next' : 'Continua...'"
      (click)="setCreationWizardOrganization()"
    ></button>
  </div>
</span>
