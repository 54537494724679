import { Component, OnDestroy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TagsService } from 'src/app/main/services/tags.service';
import { ICreateTagDTO, KbTag } from '../../models/kb-tags.model';
import { finalize, iif, switchMap, tap } from 'rxjs';
import { cloneDeep, differenceBy } from 'lodash';
import { MultiSelectChangeEvent } from 'primeng/multiselect';

@Component({
  selector: 'app-add-tag-modal',
  templateUrl: './add-tag-modal.component.html',
  styleUrl: './add-tag-modal.component.scss',
})
export class AddTagModalComponent implements OnDestroy {
  botId: string;
  sourceId: string;
  previousCurrentTags: KbTag[];
  currentTags: KbTag[];
  newTags: KbTag[] = [];
  tags: KbTag[] = [];
  loadingTags: boolean = false;
  isUploading: boolean = false;

  newTag: string = '';
  tagColor: string = '#FF0000';

  updated: boolean = false;

  constructor(private readonly tagsService: TagsService, private readonly config: DynamicDialogConfig, private readonly ref: DynamicDialogRef) {
    this.botId = this.config.data.botId;
    this.sourceId = this.config.data.sourceId;
    this.previousCurrentTags = cloneDeep(this.config.data.currentTags);
    this.currentTags = cloneDeep(this.config.data.currentTags);

    this.getTags();
  }

  ngOnDestroy(): void {
    this.ref.close({ updated: this.updated });
  }

  getTags() {
    this.loadingTags = true;
    this.tagsService
      .getAllTagsForBot$(this.botId)
      .pipe(finalize(() => (this.loadingTags = false)))
      .subscribe({
        next: (v) => (this.tags = v),
      });
  }

  getTagsForSource() {
    this.loadingTags = true;
    this.tagsService
      .getAllTagsForSource$(this.botId, this.sourceId)
      .pipe(finalize(() => (this.loadingTags = false)))
      .subscribe({
        next: (v) => {
          this.currentTags = v;
          this.previousCurrentTags = v;
        },
      });
  }

  onChange(event: MultiSelectChangeEvent) {
    iif(
      () => !!(event.value as KbTag[]).find((t) => t.id === event.itemValue.id),
      this.tagsService.associateTag$(this.botId, event.itemValue.id, this.sourceId),
      this.tagsService.deAssociateTag$(this.botId, event.itemValue.id, this.sourceId)
    ).subscribe({
      next: () => {
        this.updated = true;

        this.getTags();
        this.getTagsForSource();
      },
    });
  }

  addTag() {
    if (!this.newTag || !this.tagColor) return;
    const body: ICreateTagDTO = { label: this.newTag, color: this.tagColor.replace('#', '').toUpperCase() };
    this.tagsService
      .createTag$(this.botId, body)
      .pipe(
        tap((tag) => {
          this.newTag = '';
          this.tagColor = '#FF0000';
          this.updated = true;
        }),
        switchMap((tag) => this.tagsService.associateTag$(this.botId, tag.id, this.sourceId))
      )
      .subscribe({
        next: () => {
          this.getTags();
          this.getTagsForSource();
        },
      });
  }
}
