import { CommercialWeDoComponent } from '../../components/assistant-creation-wizard/commercial/commercial-we-do/commercial-we-do.component';
import { CommercialWeDontComponent } from '../../components/assistant-creation-wizard/commercial/commercial-we-dont/commercial-we-dont.component';
import { CharacterComponent } from '../../components/assistant-creation-wizard/common/character/character.component';
import { OrganizationComponent } from '../../components/assistant-creation-wizard/common/organization/organization.component';
import { RecapComponent } from '../../components/assistant-creation-wizard/common/recap/recap.component';
import { TargetAudienceComponent } from '../../components/assistant-creation-wizard/common/target-audience/target-audience.component';
import { AssistantWizardStepsItem } from '../../models/assistant-creation.model';

export const COMMERCIAL_STEPS: AssistantWizardStepsItem[] = [
  {
    label: 'Character',
    pipeKey: 'character',
    component: CharacterComponent,
  },
  {
    label: 'Organization',
    pipeKey: 'organization',
    component: OrganizationComponent,
  },
  {
    label: 'Target Audience',
    pipeKey: 'targetAudience',
    component: TargetAudienceComponent,
  },
  {
    label: 'We do',
    pipeKey: 'weDo',
    component: CommercialWeDoComponent,
  },
  {
    label: "We don't",
    pipeKey: 'weDont',
    component: CommercialWeDontComponent,
  },
  {
    label: 'Recap',
    pipeKey: 'recap',
    component: RecapComponent,
  },
];
