import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ICreateTagDTO, IKbTag, KbTag } from '../shared/models/kb-tags.model';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private readonly apiService: ApiService) {}

  createTag$(chatBotId: string, body: ICreateTagDTO) {
    return this.apiService.post<IKbTag>(`chat-bots/${chatBotId}/tags`, body).pipe(map((t) => new KbTag(t)));
  }

  deleteTag$(chatBotId: string, tagId: string) {
    return this.apiService.delete(`chat-bots/${chatBotId}/tags/${tagId}`);
  }

  associateTag$(chatBotId: string, tagId: string, dataSourceId: string | string[]) {
    return this.apiService.post<any>(`chat-bots/${chatBotId}/tags/${tagId}/data-sources`, {
      dataSourceIds: Array.isArray(dataSourceId) ? dataSourceId : [dataSourceId],
    });
  }

  deAssociateTag$(chatBotId: string, tagId: string, dataSourceId: string | string[]) {
    return this.apiService.delete(
      `chat-bots/${chatBotId}/tags/${tagId}/data-sources`,
      {},
      {
        dataSourceIds: Array.isArray(dataSourceId) ? dataSourceId : [dataSourceId],
      }
    );
  }

  getAllTagsForBot$(chatBotId: string) {
    return this.apiService.get<IKbTag[]>(`chat-bots/${chatBotId}/tags`).pipe(map((tags) => tags.map((t) => new KbTag(t))));
  }

  getAllTagsForSource$(chatBotId: string, sourceId: string) {
    return this.apiService.get<IKbTag[]>(`chat-bots/${chatBotId}/tags?dataSourceId=${sourceId}`).pipe(map((tags) => tags.map((t) => new KbTag(t))));
  }
}
