<span class="h-full flex flex-column justify-content-between">
  <span>
    <h3>
      Opzionalmente elenca servizi che la tua {{ creationWizard.organization.type }} <span class="font-semibold">NON</span> svolge. Inserisci
      eventuali servizi per i quali vieni spesso contattato per errore.
    </h3>
    <div class="p-inputgroup">
      <input
        type="text"
        pInputText
        [(ngModel)]="newService"
        [disabled]="creationWizard.weDont.size >= 20"
        (keydown.enter)="newService && setWeDont()"
        placeholder="Aggiungi un servizio"
      />
      <button
        type="button"
        pButton
        icon="pi pi-plus"
        pTooltip="Aggiungi"
        styleClass="p-button-success"
        [disabled]="!newService"
        (click)="setWeDont()"
      ></button>
    </div>
    <small *ngIf="creationWizard.weDont.size >= 20" style="color: var(--yellow-500)">Numero massimo di servizi raggiunti</small>
    <ul class="h-20rem flex flex-column flex-wrap gap-2 p-0">
      <li
        *ngFor="let item of creationWizard.weDont"
        class="flex align-items-center justify-content-between p-2 border-1 border-gray-400 border-round-md"
        style="max-width: 33%"
      >
        <p class="m-0">{{ item }}</p>
        <i class="pi pi-times-circle cursor-pointer" style="color: var(--red-500)" (click)="creationWizard.removeWeDont(item)"></i>
      </li>
    </ul>
  </span>
  <div class="flex justify-content-end">
    <button pButton label="View recap" (click)="setCreationWizard()"></button>
  </div>
</span>
