import { Injectable } from '@angular/core';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
import { BOT_CHAT_THEME_KEY } from '../shared/constants/local-storage-keys.consts';
import { IBotThemes } from '../shared/models/bot-themes.model';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(private readonly localStorageService: LocalstorageService) {}

  getThemes() {
    return this.localStorageService.get(BOT_CHAT_THEME_KEY) as IBotThemes[];
  }

  getTheme(botId: string) {
    return (this.localStorageService.get(BOT_CHAT_THEME_KEY) as IBotThemes[])?.find((v) => v.botId === botId);
  }

  setBotTheme(botId: string, theme?: string) {
    let array = this.getThemes();
    if (!array) array = [];
    const newTheme: IBotThemes = {
      botId,
      theme: theme || 'assets/chat-themes/emoticons.png',
    };

    const i = array.findIndex((t) => t.botId === botId);
    if (i >= 0) {
      if (theme) array[i].theme = theme;
    } else {
      array.push(newTheme);
    }

    this.localStorageService.set(BOT_CHAT_THEME_KEY, array);
  }
}
