import { AfterViewInit, Component } from '@angular/core';
import { AssistantCreationService } from 'src/app/main/services/assistant-creation.service';
import { ORGANIZATION_TYPES } from 'src/app/main/shared/constants/assistant-creation-wizard/organization.consts';
import { AssistantCreationWizard } from 'src/app/main/shared/models/assistant-creation.model';
import { AssistantOrganization } from 'src/app/main/shared/models/assistant-organization.model';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent {
  creationWizard: AssistantCreationWizard = new AssistantCreationWizard();

  organization: AssistantOrganization = new AssistantOrganization();

  activeOrganizationSection: number = 0;

  readonly organizationTypes = ORGANIZATION_TYPES;

  constructor(private readonly assistantCreationService: AssistantCreationService) {
    this.assistantCreationService.getAssistantCreationWizard$().subscribe({
      next: (v) => (this.creationWizard = v),
    });
  }

  setCreationWizardOrganization() {
    this.creationWizard.organization = this.organization;
    if (this.activeOrganizationSection < 2) {
      this.activeOrganizationSection++;
    } else {
      this.assistantCreationService.setAssistantCreationWizard(this.creationWizard);
      this.assistantCreationService.setNextStep(true);
    }
  }

  getOrganizationTypeLabel() {
    return ORGANIZATION_TYPES.find((o) => o.value === this.organization.type)?.label;
  }

  goBack() {
    this.activeOrganizationSection -= 1;
  }

  isNextButtonDisabled() {
    switch (this.activeOrganizationSection) {
      case 0:
        return !this.organization.type;
      case 1:
        return !this.organization.name;
      case 2:
        return !this.organization.description;
      default:
        return false;
    }
  }
}
