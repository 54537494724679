<div class="flex flex-column gap-4">
  <span class="flex flex-column">
    <label class="mb-2">{{ 'private.sourceDetailsPage.document.tagsModal.selectTags' | translate }}</label>
    <p-multiSelect
      [options]="tags"
      [loading]="loadingTags"
      styleClass="w-full tags-multiselect"
      optionLabel="label"
      appendTo="body"
      [(ngModel)]="currentTags"
      (onChange)="onChange($event)"
    >
      <ng-template let-value pTemplate="selectedItems">
        @for (tag of value; track $index) {
        <p-tag class="source-tag" [style]="{ background: '#' + tag.color }">
          <span class="flex gap-2 align-items-center p-1">
            <p class="my-0">{{ tag.label }}</p>
          </span>
        </p-tag>
        }
      </ng-template>
    </p-multiSelect>
  </span>

  <span class="flex flex-column">
    <label class="mb-2">{{ 'private.sourceDetailsPage.document.tagsModal.addNew' | translate }}</label>
    <span class="flex">
      <p-inputGroup>
        <input pInputText #tagAdd class="border-noround-right" placeholder="Tag" [(ngModel)]="newTag" (keydown.enter)="addTag()" />
        <p-inputGroupAddon class="input-group-colorpicker">
          <p-colorPicker styleClass="color-picker" [(ngModel)]="tagColor" appendTo="body"></p-colorPicker>
        </p-inputGroupAddon>
      </p-inputGroup>
      <button pButton class="p-button-success border-noround-left" icon="pi pi-check" [disabled]="!newTag || !tagColor" (click)="addTag()"></button>
    </span>
  </span>
</div>
