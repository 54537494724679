export enum ApiKeyTypeEnum {
  ASSISTANT = 'ASSISTANT',
  GLOBAL = 'GLOBAL',
}

export enum ApiKeyRestrictionTypeEnum {
  NONE = 'NONE',
  IP = 'IP',
  ORIGIN = 'ORIGIN',
}
