import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/main/services/users.service';
import { Share } from '../../models/share.model';
import { ContinueChatModalComponent } from '../continue-chat-modal/continue-chat-modal.component';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnChanges, OnDestroy {
  @ViewChild('sharedContainer') sharedContainer?: ElementRef<HTMLDivElement>;
  @Input() sharedConversation?: Share;
  @Input() isDialog: boolean = false;
  private readonly THRESHOLD = 50;

  isLoggedIn: boolean = false;

  conversationId: string = '';
  showScrollToTopButton: boolean = false;

  userSubscription?: Subscription;

  constructor(private readonly userService: UsersService, private readonly dialogService: DialogService) {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.getUser$().subscribe({
        next: (u) => {
          if (u) this.isLoggedIn = true;
          else this.isLoggedIn = false;
        },
        error: (e) => (this.isLoggedIn = false),
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  get conversationPublicLink() {
    return `${window.origin}/share/${this.sharedConversation?.id}`;
  }

  registerScrollEvent() {
    if (this.sharedContainer) {
      this.sharedContainer.nativeElement.onscroll = (event) => {
        if ((event.target as HTMLDivElement).scrollTop <= this.THRESHOLD) {
          this.showScrollToTopButton = false;
          return;
        }

        this.showScrollToTopButton = true;
      };
    }
  }

  continueConversation() {
    this.dialogService.open(ContinueChatModalComponent, {
      styleClass: 'md:w-6 w-full',
      header: this.isLoggedIn ? 'Continua questa conversazione' : 'Accedi o registrati per continuare',
      data: {
        authenticated: this.isLoggedIn,
        share: this.sharedConversation,
      },
    });
  }

  scrollToTop() {
    if (this.sharedContainer) {
      this.sharedContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
