import { Component, OnInit } from '@angular/core';
import { AssistantUsersService } from 'src/app/main/services/assistant-users.service';
import { AssistantUse, AssistantUsePermission } from '../../models/assistant-use.model';
import { BOT_USER_PERMISSIONS_OPTIONS } from '../../models/users-and-access.model';
import { UsersAndGroupsService } from 'src/app/main/services/users-and-groups.service';
import { CompanyGroupUser } from '../../models/users-and-groups.model';
import { Observable, finalize, forkJoin } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { difference, intersection } from 'lodash';

@Component({
  selector: 'app-create-assistant-use-modal',
  templateUrl: './create-assistant-use-modal.component.html',
  styleUrl: './create-assistant-use-modal.component.scss',
})
export class CreateAssistantUseModalComponent implements OnInit {
  readonly permissionOptions = BOT_USER_PERMISSIONS_OPTIONS;
  permission?: AssistantUsePermission;

  botId: string = '';

  isLoadingUsers: boolean = false;
  isCreating: boolean = false;

  alreadyInsertedUsers: AssistantUse[] = [];
  selectedUsers: CompanyGroupUser[] = [];
  users: CompanyGroupUser[] = [];

  hasFiltered: boolean = false;
  filteredUsers: CompanyGroupUser[] = [];

  searchTerm: string = '';

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    private readonly assistantUseService: AssistantUsersService,
    private readonly usersAndGroupsService: UsersAndGroupsService
  ) {
    this.botId = this.config.data.botId;
    this.alreadyInsertedUsers = this.config.data.alreadyInsertedUsers;
  }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.isLoadingUsers = true;
    this.usersAndGroupsService
      .getUsers$(0, 1000, '')
      .pipe(finalize(() => (this.isLoadingUsers = false)))
      .subscribe({
        next: ({ items }) => (this.users = items),
      });
  }

  createAssistantUse() {
    this.isCreating = true;

    const calls: Observable<any>[] = [];

    this.selectedUsers.forEach((u) =>
      calls.push(this.assistantUseService.createAssistantUse$(this.botId, u.id, { permission: this.permission, archived: false }))
    );

    forkJoin(calls)
      .pipe(finalize(() => (this.isCreating = false)))
      .subscribe({
        next: () => this.ref.close({ created: true }),
      });
  }

  getIsUserAlreadyAdded(user: CompanyGroupUser) {
    return !!this.alreadyInsertedUsers.find((au) => au.userId === user.id);
  }

  getEveryUserAlreadyAdded() {
    const userIds = this.users.map((u) => u.id);
    const usesIds = this.alreadyInsertedUsers.map((au) => au.userId);

    return !!difference(userIds, usesIds).length;
  }

  filterUsers() {
    if (this.searchTerm) {
      this.hasFiltered = true;
      this.filteredUsers = this.users.filter((u) => u.fullName.toLowerCase().includes(this.searchTerm.toLowerCase()));
    } else {
      this.hasFiltered = false;
    }
  }
}
