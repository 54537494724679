<div class="px-4 pt-6 md:px-6 lg:px-8 h-full">
  <div class="text-900 font-bold text-6xl mb-4 text-center">{{ mainTitle | translate }}</div>
  <div class="text-700 text-xl mb-5 text-center line-height-3">
    {{ subtitle | translate }}
  </div>

  <div class="flex justify-content-center align-items-center gap-3 mb-4">
    <p class="mb-0" [ngClass]="{ 'text-gray-300': isYearlyPayment, 'text-primary-800 font-semibold': !isYearlyPayment }">
      {{ 'private.buySubscriptionPage.costs.monthly' | translate }}
    </p>
    <p-inputSwitch [(ngModel)]="isYearlyPayment" class="flex"></p-inputSwitch>
    <div class="flex flex-column align-items-center relative">
      <p class="mb-0 relative" [ngClass]="{ 'text-gray-300': !isYearlyPayment, 'text-primary-800 font-semibold': isYearlyPayment }">
        {{ 'private.buySubscriptionPage.costs.yearly' | translate }}
      </p>
      <p class="absolute text-green-500 text-sm w-max pl-8" style="bottom: -1.5rem">
        {{ 'private.buySubscriptionPage.costs.twoMonthsFree' | translate }}
      </p>
    </div>
  </div>

  <div class="flex flex-wrap sm:flex-row flex-column mt-6">
    @for (sub of subscriptions; track $index) {
    <div class="md:col-3 sm:col-6 col-12 p-0 h-full flex align-items-stretch">
      <div class="h-full w-full">
        <div class="shadow-2 p-3 h-full flex flex-column surface-card price-card relative">
          @if (sub.bestSeller) {
          <div class="ribbon">{{ 'private.buySubscriptionPage.bestSeller' | translate }}</div>
          }
          <div class="text-900 font-medium text-xl">{{ sub.planName }}</div>
          <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
          <div class="flex align-items-center">
            @if (getIsNumber(isYearlyPayment ? sub.monthlyCostAnnual : sub.monthlyCostMonth )) {
            <span class="font-bold text-2xl text-900">{{
              isYearlyPayment ? (sub.monthlyCostAnnual | currency : 'USD' : '$') : (sub.monthlyCostMonth | currency : 'USD' : '$')
            }}</span>
            <span class="ml-2 font-medium text-600">{{ 'private.buySubscriptionPage.perMonth' | translate }}</span>
            } @else {
            <span class="font-bold text-lg text-900">{{ 'private.buySubscriptionPage.customPrice' | translate }}</span>
            }
          </div>
          <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
          @if(getIsDefinedPrice(sub)) {
          <ul class="list-disc mb-3 p-0 m-0 flex-grow-1 pl-4 flex flex-column gap-2">
            <li>
              <span class="text-900"
                >{{ 'private.buySubscriptionPage.credits' | translate : { monthlyCredits: sub.monthlyCredits } }}
                {{ (sub.isMonthlyCreditsDaily ? 'private.buySubscriptionPage.perDay' : 'private.buySubscriptionPage.perMonth') | translate }}</span
              >
            </li>
            <li>
              <span class="text-900">{{ 'private.buySubscriptionPage.users' | translate : { users: sub.users } }}</span>
            </li>
            <li>
              <span class="text-900">{{ 'private.buySubscriptionPage.assistants' | translate : { createdAssistants: sub.createdAssistants } }}</span>
            </li>
            <li>
              <span class="text-900">{{ 'private.buySubscriptionPage.apiKeys' | translate : { apiKeys: sub.apiKeys } }}</span>
            </li>
            <li>
              <span class="text-900">{{ 'private.buySubscriptionPage.kbTraffic' | translate : { kbTraffic: sub.kbTraffic } }}</span>
            </li>
          </ul>
          } @else {
          <ul class="list-disc mb-3 p-0 m-0 flex-grow-1 pl-4 flex flex-column gap-2">
            <li>
              <span class="text-900"
                >{{
                  'private.buySubscriptionPage.credits'
                    | translate : { monthlyCredits: 'private.buySubscriptionPage.' + sub.monthlyCredits | translate }
                }}
                {{ 'private.buySubscriptionPage.perMonth' | translate }}</span
              >
            </li>
            <li>
              <span class="text-900">{{
                'private.buySubscriptionPage.users' | translate : { users: 'private.buySubscriptionPage.' + sub.users | translate }
              }}</span>
            </li>
            <li>
              <span class="text-900">{{
                'private.buySubscriptionPage.assistants'
                  | translate : { createdAssistants: 'private.buySubscriptionPage.' + sub.createdAssistants | translate }
              }}</span>
            </li>
            <li>
              <span class="text-900">{{
                'private.buySubscriptionPage.apiKeys' | translate : { apiKeys: 'private.buySubscriptionPage.' + sub.apiKeys | translate }
              }}</span>
            </li>
            <li>
              <span class="text-900">{{
                'private.buySubscriptionPage.kbTraffic' | translate : { kbTraffic: 'private.buySubscriptionPage.' + sub.kbTraffic | translate }
              }}</span>
            </li>
          </ul>
          } @if (sub.bringYourOwnModel !== undefined) {
          <span class="flex align-items-center gap-4 mb-3">
            <i
              class="text-2xl pi"
              [ngClass]="{ 'pi-check-circle text-green-400': sub.bringYourOwnModel, 'pi-times-circle text-red-400': !sub.bringYourOwnModel }"
            ></i>
            <p>{{ (sub.bringYourOwnModel ? 'private.buySubscriptionPage.byom.yes' : 'private.buySubscriptionPage.byom.no') | translate }}</p>
          </span>
          }
          <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
          <button
            pButton
            pRipple
            [label]="'private.buySubscriptionPage.buyNow' | translate"
            [loading]="sub.isBuying"
            class="p-3 w-full mt-auto"
            (click)="initializeCheckout(sub)"
          ></button>
        </div>
      </div>
    </div>
    }
  </div>
</div>
