import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  constructor(private readonly apiService: ApiService) {}
  getAgencyList(items: number = 20, page: number = 0) {
    const qs = `?items=${items}&page=${page}`;
    return this.apiService.get(`agency/agencies${qs}`).pipe(
      map((response: any) => ({
        items: response.items ? response.items : response,
        total: response.total ? response.total : response.length,
      }))
    );
  }
}
