import { ICrawlingMethod } from '../models/add-knowledge-base.model';

export const CRAWLING_METHODS: ICrawlingMethod[] = [
  {
    label: 'private.knowledgePage.table.crawlingMethods.singleCrawl.label',
    value: 'SINGLE_CRAWL',
    description: 'private.knowledgePage.table.crawlingMethods.singleCrawl.description',
  },
  {
    label: 'private.knowledgePage.table.crawlingMethods.sitemapCrawl.label',
    value: 'SITEMAP_CRAWL',
    description: 'private.knowledgePage.table.crawlingMethods.sitemapCrawl.description',
  },
  {
    label: 'private.knowledgePage.table.crawlingMethods.fullCrawl.label',
    value: 'FULL_CRAWL',
    description: 'private.knowledgePage.table.crawlingMethods.fullCrawl.description',
  },
];
