<span class="mt-5">
  <form [formGroup]="knowledgeForm" class="flex flex-column gap-3 w-full relative mb-4 kb-settings-form">
    <div class="flex flex-column">
      <div class="w-full flex flex-row align-items-center">
        <div class="w-full gap-3 flex flex-row justify-content-between p-0">
          <span class="flex flex-row align-items-center flex-grow-1">
            <div class="flex p-0 flex-column">
              <h4 class="mb-2">{{ 'private.knowledgePage.settingsModal.settings.kbMatch.title' | translate }}</h4>
              <p [innerHTML]="'private.knowledgePage.settingsModal.settings.kbMatch.description' | translate"></p>
            </div>
          </span>
          <div class="flex p-0 justify-content-end align-items-center col-2">
            <p-inputSwitch
              formControlName="ASSISTANT_SHOW_KB_MATCH"
              (onChange)="onToggleChange(Settings.ASSISTANT_SHOW_KB_MATCH, $event)"
            ></p-inputSwitch>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-column">
      <div class="w-full flex flex-row align-items-center">
        <div class="w-full gap-3 flex flex-row justify-content-between p-0">
          <span class="flex flex-row align-items-center flex-grow-1">
            <div class="flex p-0 flex-column">
              <h4 class="mb-2">{{ 'private.knowledgePage.settingsModal.settings.kbResources.title' | translate }}</h4>
              <p [innerHTML]="'private.knowledgePage.settingsModal.settings.kbResources.description' | translate"></p>
            </div>
          </span>
          <div class="flex p-0 justify-content-end align-items-center col-2">
            <p-inputSwitch
              formControlName="ASSISTANT_SHOW_KB_RESOURCES"
              (onChange)="onToggleChange(Settings.ASSISTANT_SHOW_KB_RESOURCES, $event)"
            ></p-inputSwitch>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-column">
      <div class="w-full flex flex-row align-items-center">
        <div class="w-full gap-3 flex flex-row justify-content-between p-0">
          <span class="flex flex-row align-items-center flex-grow-1">
            <div class="flex p-0 flex-column">
              <h4 class="mb-2">{{ 'private.knowledgePage.settingsModal.settings.kbDownload.title' | translate }}</h4>
              <p [innerHTML]="'private.knowledgePage.settingsModal.settings.kbDownload.description' | translate"></p>
            </div>
          </span>
          <div class="flex p-0 justify-content-end align-items-center col-2">
            <p-inputSwitch
              formControlName="ASSISTANT_KB_RESOURCES_DOWNLOAD"
              (onChange)="onToggleChange(Settings.ASSISTANT_KB_RESOURCES_DOWNLOAD, $event)"
            ></p-inputSwitch>
          </div>
        </div>
      </div>
    </div>
    <!-- <base-input-element
      ngDefaultControl
      *ngIf="getFormControl(option.formControlName)"
      [formControl]="getFormControl(option.formControlName)"
      (onValueChange)="onToggleChange(option.settingId!, $event)"
      [customTextClass]="true"
      class="w-full"
      [element]="option"
    ></base-input-element> -->
  </form>
</span>
