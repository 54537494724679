import { AssistantCreationWizard } from '../../models/assistant-creation.model';

export const INTERNAL_KB_RECAP_KEYS: (keyof AssistantCreationWizard)[] = [
  'character',
  'organization',
  'expertise',
  'targetAudience',
  'targetLanguage',
  'replyExtraDetails',
];
export const COMMERCIAL_RECAP_KEYS: (keyof AssistantCreationWizard)[] = ['character', 'organization', 'targetAudience', 'weDo', 'weDont'];
