export const CHARACTERS = [
  {
    label: 'Informale',
    value: 'informal',
  },
  {
    label: 'Formale',
    value: 'formal',
  },
  {
    label: 'Entusiasta',
    value: 'enthusiastic',
  },
  {
    label: 'Molto entusiasta',
    value: 'very enthusiastic',
  },
];
