<p-confirmDialog></p-confirmDialog>

<p-header>
  <div class="flex justify-content-between pb-4 pt-2">
    <p-tabMenu [model]="tabMenuItems" [(activeItem)]="activeMenuItem">
      <ng-template pTemplate="item" let-item>
        <a class="p-menuitem-link p-3" [routerLink]="item.routerLink" [routerLinkActive]="'p-menuitem-link-active'"> {{ item.label | translate }}</a>
      </ng-template>
    </p-tabMenu>
    <i class="pi pi-times cursor-pointer flex align-items-center" (click)="ref.close()"></i>
  </div>
</p-header>
@switch (activeMenuItem.tabindex) { @case ('tags') {
<div class="flex flex-column gap-4">
  <span class="flex flex-column">
    <label class="mb-2 font-semibold">{{ 'private.knowledgePage.massiveTagsActionsModal.addTags' | translate }}</label>
    <p-multiSelect
      [options]="allTags"
      [loading]="loading"
      styleClass="w-full tags-multiselect"
      optionLabel="label"
      appendTo="body"
      [(ngModel)]="currentTags"
      (onChange)="onChange($event)"
    >
      <ng-template let-value pTemplate="selectedItems">
        @for (tag of value; track $index) {
        <p-tag class="source-tag" [style]="{ background: '#' + tag.color }">
          <span class="flex gap-2 align-items-center p-1">
            <p class="my-0">{{ tag.label }}</p>
          </span>
        </p-tag>
        }
      </ng-template>
    </p-multiSelect>
  </span>
  <span class="flex flex-column">
    <label class="mb-2 font-semibold">{{ 'private.knowledgePage.massiveTagsActionsModal.removeTags' | translate }}</label>
    <app-source-tags-list
      [tags]="currentTags"
      [showAdd]="false"
      [botId]="botId"
      [sourceIds]="dataSourcesIds"
      (onTagChanged)="getBotTags($event, true)"
    ></app-source-tags-list>
  </span>
</div>
} @case ('access') {
<div class="relative">
  @if (isDeletingAllVisibilities) {
  <div class="h-full w-full absolute top-0 left-0 bg-black-alpha-30 z-3 flex justify-content-center align-items-center">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>
  }
  <div class="w-12 p-2">
    <h4>{{ 'private.sourceDetailsPage.document.access' | translate }}</h4>
    <p>{{ 'private.sourceDetailsPage.document.accessDescription' | translate }}</p>

    <div class="mb-3">
      <button
        pButton
        class="border-noround-right"
        [class]="accessType === 'PUBLIC' ? 'p-button-primary' : 'p-button-outlined p-button-secondary'"
        [label]="getAccessType('PUBLIC').label | translate"
        (click)="handleAccessButtonClick($event, 'PUBLIC')"
      ></button>
      <button
        pButton
        class="border-noround-left"
        [class]="accessType === 'PRIVATE' ? 'p-button-primary' : 'p-button-outlined p-button-secondary'"
        [label]="getAccessType('PRIVATE').label | translate"
        (click)="handleAccessButtonClick($event, 'PRIVATE')"
      ></button>
    </div>
  </div>
  <p class="text-gray-300 font-italic">{{ getAccessType(accessType).description | translate }}</p>
  @if (accessType === 'PRIVATE') {
  <div class="mt-3">
    <div class="flex align-items-center flex-wrap gap-2">
      <p-chip styleClass="bg-primary text-white cursor-pointer" (click)="openPermissionModal()">
        <span class="flex align-items-center px-1 py-2">
          <i class="pi pi-plus-circle"></i>
          <p>{{ 'private.sourceDetailsPage.createAccess.addRule' | translate }}</p>
        </span>
      </p-chip>
      @for (visibility of commonVisibilities; track $index) {
      <p-chip [styleClass]="visibility.user.id ? 'bg-secondary' : 'bg-cyan-300'">
        <span class="flex align-items-center gap-2 px-1 py-2">
          <i [class]="visibility.user.id ? 'pi pi-user' : 'pi pi-users'" class="w-fit"></i>
          <p class="mb-0 text-antialiased">{{ visibility.user.id ? visibility.user.name + ' ' + visibility.user.surname : visibility.group.name }}</p>
          <i class="pi pi-times-circle w-fit cursor-pointer" (click)="deleteVisibility(visibility.user.id || visibility.group.id, visibility.id)"></i>
        </span>
      </p-chip>
      }
    </div>
  </div>
  }
</div>
} @case ('validity') {
<div class="flex flex-column">
  <div class="grid md:flex-row flex-column">
    <div class="md:col-6 col-12">
      <p-calendar
        styleClass="w-full"
        appendTo="body"
        [placeholder]="'common.date.from' | translate"
        [maxDate]="validityPeriod.to"
        [(ngModel)]="validityPeriod.from"
      ></p-calendar>
    </div>
    <div class="md:col-6 col-12">
      <p-calendar
        styleClass="w-full"
        appendTo="body"
        [placeholder]="'common.date.to' | translate"
        [minDate]="validityPeriod.from"
        [(ngModel)]="validityPeriod.to"
      ></p-calendar>
    </div>
  </div>
  <div class="flex justify-content-end gap-2 pt-2">
    <button
      pButton
      [label]="'private.knowledgePage.massiveTagsActionsModal.removeValidityPeriod' | translate"
      class="p-button-danger p-button-outlined"
      icon="pi pi-trash"
      [loading]="updatingValidityFrom"
      (click)="updateValidityPeriod(true)"
    ></button>
    <button
      pButton
      [label]="'common.add' | translate"
      [disabled]="!validityPeriod.from || !validityPeriod.to"
      [loading]="updatingValidityFrom"
      (click)="updateValidityPeriod()"
    ></button>
  </div>
</div>
} }
