@if (!isSessionActive) {
<div class="flex justify-content-center py-5">
  <button pButton [label]="'private.knowledgePage.addSourceModal.drive.start' | translate" [loading]="isSearching" (click)="onButtonClick()"></button>
</div>
} @else if(loading) {
<div class="flex justify-content-center align-items-center py-5 h-15rem">
  <p-progressSpinner></p-progressSpinner>
</div>
} @else {
<div class="h-full overflow-y-scroll">
  @if(!isProcessing) {
  <div class="flex mb-3 justify-content-between align-items-center p-fluid p-1">
    <div class="col-6 p-0 flex gap-4">
      <p-iconField iconPosition="right" class="w-full" styleClass="w-full">
        <input
          type="text"
          pInputText
          [placeholder]="'private.knowledgePage.addSourceModal.drive.searchFile' | translate"
          [(ngModel)]="filters.search"
          (keydown.enter)="onSortChange()"
          (ngModelChange)="searchChanged()"
        />
        @if (isSearching) {
        <p-inputIcon styleClass="pi pi-spinner pi-spin"></p-inputIcon>
        }
      </p-iconField>
    </div>
    <div class="flex gap-4">
      <p-dropdown
        [options]="orderByOptions"
        appendTo="body"
        [(ngModel)]="filters.orderBy"
        (onChange)="onSortChange()"
        optionValue="value"
        [placeholder]="'private.knowledgePage.addSourceModal.drive.orderBy' | translate"
      >
        <ng-template pTemplate="selectedItem" let-item>
          <span>{{ item.label | translate }}</span>
        </ng-template>
        <ng-template pTemplate="item" let-item>
          <span>{{ item.label | translate }}</span>
        </ng-template>
      </p-dropdown>
      <p-toggleButton
        [(ngModel)]="filters.desc"
        (onChange)="onSortChange()"
        [onIcon]="'pi pi-sort-amount-down'"
        [offIcon]="'pi pi-sort-amount-up-alt'"
      />
    </div>
  </div>
  <div class="relative">
    <p-tree
      [value]="driveFiles"
      class="drive-tree"
      [selectionMode]="'checkbox'"
      [(selection)]="selectedDriveFiles"
      (onNodeExpand)="getSingleItem($event.node)"
      loadingMode="mask"
      [loading]="isPaginating || isSearching"
      scrollHeight="100%"
    >
      <ng-template let-node pTemplate="default">
        <div class="flex w-full justify-content-between align-items-center">
          <span class="flex align-items-center gap-1 md:w-5 w-8">
            <span style="line-break: anywhere">{{ node.name }}</span>
            <button
              pButton
              icon="pi pi-external-link"
              text="true"
              class="ml-3 py-1 px-2 open-file-button"
              (click)="$event.stopPropagation(); openFile(node)"
            ></button>
          </span>
          <span class="text-gray-400 md:block md:w-5 hidden">
            {{ node.data.modifiedTime | datestringToDate : 'dd/MM/yyyy, HH:mm' }}
          </span>
          <span class="md:w-2 w-4">
            @if (node.data.type === 'folder' && node.children) {
            <p class="my-0 text-gray-500">
              ({{
                node.children.length > 100
                  ? ('private.knowledgePage.addSourceModal.drive.items.advanced' | translate)
                  : ('private.knowledgePage.addSourceModal.drive.items.simple' | translate : { count: node.children.length })
              }})
            </p>
            }
          </span>
        </div>
      </ng-template>
    </p-tree>
    @if(nextPageToken && !isProcessing) {
    <div class="absolute bottom-0 right-0 p-3">
      <button
        pButton
        [label]="'private.knowledgePage.addSourceModal.drive.loadMore' | translate"
        icon="pi pi-plus"
        class="mr-auto p-button-outlined p-button-rounded bg-white"
        (click)="onSortChange(nextPageToken)"
      ></button>
    </div>
    }
  </div>
  } @else {
  <div class="mt-7 mb-8">
    <p>{{ 'private.knowledgePage.addSourceModal.drive.processing' | translate }}</p>
    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }" />
  </div>
  }
  <div class="flex justify-content-end mt-4 mx-2 mb-2">
    <div class="flex gap-3">
      <button
        pButton
        [label]="'private.knowledgePage.addSourceModal.drive.logout' | translate"
        [loading]="isLoggingOut"
        [disabled]="isProcessing"
        (click)="logoutDrive()"
      ></button>
      <button
        pButton
        [label]="'private.knowledgePage.addSourceModal.drive.process' | translate"
        severity="success"
        [loading]="isProcessing"
        [disabled]="!selectedDriveFiles.length || isLoggingOut"
        (click)="saveFiles()"
      ></button>
    </div>
  </div>
</div>
}
