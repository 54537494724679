import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MarketplaceItem } from '../../models/marketplace.model';
import { MenuItem } from 'primeng/api';
import { ImageUuidService } from 'src/app/main/services/image-uuid.service';

@Component({
  selector: 'app-product-details-modal',
  templateUrl: './product-details-modal.component.html',
  styleUrl: './product-details-modal.component.scss',
})
export class ProductDetailsModalComponent {
  readonly tabMenuItem: MenuItem[] = [
    {
      tabindex: 'product',
      label: 'private.dashboardPage.marketplaceSection.productDetailsModal.tabs.product',
    },
    {
      tabindex: 'author',
      label: 'private.dashboardPage.marketplaceSection.productDetailsModal.tabs.author',
    },
    {
      tabindex: 'previews',
      label: 'private.dashboardPage.marketplaceSection.productDetailsModal.tabs.previews',
    },
  ];
  activeItem: MenuItem = this.tabMenuItem[0];
  item: MarketplaceItem;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    public readonly imageUuidService: ImageUuidService
  ) {
    this.item = this.config.data.item;
  }

  closeModal(result: 'useNow' | 'add') {
    this.ref.close({ result });
  }
}
