import { Component } from '@angular/core';
import { CREDITS } from '../../constants/subscriptions.consts';
import { SubscriptionService } from 'src/app/main/services/subscription.service';
import { IStripeCheckoutDTO } from '../../models/subscription.model';
import { finalize } from 'rxjs';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from 'src/app/main/services/toast.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buy-credits-modal',
  templateUrl: './buy-credits-modal.component.html',
  styleUrl: './buy-credits-modal.component.scss',
})
export class BuyCreditsModalComponent {
  private readonly isProduction = environment.production;
  readonly credits = CREDITS;
  currentCredits: {
    staging: string;
    production: string;
  } = {
    staging: '',
    production: '',
  };

  isLoading: boolean = false;

  constructor(
    public readonly ref: DynamicDialogRef,
    private readonly toastService: ToastService,
    private readonly subscriptionService: SubscriptionService,
    private readonly mainLanguageService: MainLanguageService
  ) {}

  buyCredits() {
    this.isLoading = true;
    const body: IStripeCheckoutDTO = {
      subscriptionId: this.isProduction ? this.currentCredits.production : this.currentCredits.staging,
    };
    this.subscriptionService
      .checkoutStripe$(body)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: ({ url }) => {
          this.ref.close();
          window.open(url);
        },
        error: ({ error }) => this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: error.message }),
      });
  }
}
