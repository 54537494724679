import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { BotsService } from '../../services/bots.service';
import { AssistantType, ChatBot } from '../models/chat-bot.model';

@Directive({
  selector: '[hideIfMarketplace]',
})
export class HideIfMarketplaceDirective implements OnDestroy {
  private subscription?: Subscription;
  private hasPrimaryView: boolean = false;

  noMarketplace?: boolean;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly botsService: BotsService
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.viewContainer.clear();
  }

  @Input() set hideIfMarketplace(noMarketplace: boolean) {
    this.noMarketplace = noMarketplace;
    this.setupSubscription();
  }

  private setupSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.botsService.currentBot$.subscribe({
      next: (v) => {
        if (!v) {
          this.viewContainer.clear();
        } else {
          this.updateView(v);
        }
      },
    });
  }

  private updateView(bot: ChatBot) {
    if (bot.type === 'ASSISTANT' || !this.noMarketplace) {
      if (!this.hasPrimaryView) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasPrimaryView = true;
      }
    } else if (this.noMarketplace) {
      this.viewContainer.clear();
      this.hasPrimaryView = true;
    }
  }
}
