import { LanguagesEnum } from '../enums/app-languages.enum';

export const LANGUAGES = [
  { label: 'Italiano', value: LanguagesEnum.ITA, flag: 'assets/svgs/flags/it.svg', disabled: false },
  { label: 'English', value: LanguagesEnum.ENG_US, flag: 'assets/svgs/flags/en.svg', disabled: false },
  { label: 'Espanol', value: LanguagesEnum.ESP, flag: 'assets/svgs/flags/es.svg', disabled: true },
  { label: 'Francais', value: LanguagesEnum.FRA, flag: 'assets/svgs/flags/fr.svg', disabled: true },
  { label: 'Polski', value: LanguagesEnum.POL, flag: 'assets/svgs/flags/pl.svg', disabled: false },
  { label: 'Shqiptare', value: LanguagesEnum.ALB, flag: 'assets/svgs/flags/al.svg', disabled: false },
  { label: 'Deutsch', value: LanguagesEnum.DEU, flag: 'assets/svgs/flags/de.svg', disabled: true },
  { label: 'Nederlands', value: LanguagesEnum.NED, flag: 'assets/svgs/flags/nl.svg', disabled: true },
  { label: 'Türkçe', value: LanguagesEnum.TUR, flag: 'assets/svgs/flags/tr.svg', disabled: false },
  { label: '한국인', value: LanguagesEnum.KOR, flag: 'assets/svgs/flags/kr.svg', disabled: true },
  { label: '中国人', value: LanguagesEnum.CHN, flag: 'assets/svgs/flags/cn.svg', disabled: true },
  { label: 'हिंदी', value: LanguagesEnum.IND, flag: 'assets/svgs/flags/in.svg', disabled: true },
];

export const PREFERRED_BOT_LANGUAGES = [
  {
    label: 'Italiano',
    value: LanguagesEnum.IT.toUpperCase(),
  },
  {
    label: 'English',
    value: LanguagesEnum.EN.toUpperCase(),
  },
  {
    label: 'Español',
    value: LanguagesEnum.ES.toUpperCase(),
  },
];
