<div>
  <div>
    <p>{{ 'common.leaveEditedPageAlert.message' | translate }}</p>
  </div>
  <div class="flex justify-content-end gap-2 mt-4">
    <button pButton class="p-button-outlined" (click)="ref.close()">{{ 'common.leaveEditedPageAlert.cancel' | translate }}</button>
    <button pButton class="p-button-warning" (click)="ref.close({ save: false })">
      {{ 'common.leaveEditedPageAlert.continueWithoutSaving' | translate }}
    </button>
    <button pButton class="p-button-primary" (click)="ref.close({ save: true })">{{ 'common.leaveEditedPageAlert.saveAndExit' | translate }}</button>
  </div>
</div>
