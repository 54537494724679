import { Component, EventEmitter, Input, Output } from '@angular/core';
import { REQUIRED_SYNC_VALIDATOR } from '../../constants/bot.consts';
import { ILGFDataFormFields } from '../../models/marketing-tools.model';

@Component({
  selector: 'app-form-fields-table',
  templateUrl: './form-fields-table.component.html',
  styleUrls: ['./form-fields-table.component.scss'],
})
export class FormFieldsTableComponent {
  @Input() buttonAlignment: 'justify-content-end' | 'justify-content-start' = 'justify-content-end';
  @Input() inputsForTable: ILGFDataFormFields[] = [];
  @Input() isEnabled: boolean = true;

  @Output() addControlEmitter: EventEmitter<any> = new EventEmitter();
  @Output() removeControlEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  removeControl(id: any) {
    this.removeControlEmitter.emit(id);
  }

  showDialogForAddNewControl() {
    this.addControlEmitter.emit();
  }

  isRequired(control: any) {
    return (control.syncValidators as string[]).includes(REQUIRED_SYNC_VALIDATOR);
  }
}
