<form [formGroup]="documentForm" class="flex flex-column gap-5" (ngSubmit)="addDocument()">
  <div class="w-full">
    <label *ngIf="!totalFiles" for="file">{{ 'private.knowledgePage.addSourceModal.document.files' | translate }}</label>
    <span *ngIf="totalFiles">
      <p class="font-semibold">
        {{ 'private.knowledgePage.addSourceModal.document.fileProgress' | translate : { completed: completedFiles, total: totalFiles } }}
      </p>
      <p-progressBar class="total-progressbar" [showValue]="false" [value]="(completedFiles * 100) / totalFiles"></p-progressBar>
    </span>
    <ngx-file-drag-drop
      *ngIf="!currentFileUploading"
      id="file"
      formControlName="file"
      [multiple]="true"
      [emptyPlaceholder]="'private.knowledgePage.addSourceModal.document.dragDropPlaceholder' | translate"
      [activeBorderColor]="'var(--primary-color)'"
      [accept]="allowedFileExtensions.join(',')"
      [ngClass]="{ hidden: (progressBars | keyvalue).length }"
    ></ngx-file-drag-drop>
    <small class="text-red-500" *ngIf="getFileUploadError()" [innerHTML]="getFileUploadError()"></small>
  </div>
  <div class="flex flex-column" *ngIf="currentFileUploading">
    <label for="progress-bar">{{ currentFileUploading.name }}</label>
    <div class="flex align-items-center">
      <p-progressBar
        [value]="!currentFileUploading.errorUploading ? currentFileUploading.progress : 100"
        [showValue]="!currentFileUploading.errorUploading ? true : false"
        class="pl-0"
        [ngClass]="{ 'col-11 error-progressbar': currentFileUploading.errorUploading, 'col-12 pr-0': !currentFileUploading.errorUploading }"
      ></p-progressBar>
      <div
        class="justify-content-center align-items-center pr-0"
        [ngClass]="{ 'flex col-1': currentFileUploading.errorUploading, hidden: !currentFileUploading.errorUploading }"
      >
        <p-button icon="pi pi-replay" class="p-button-sm p-button-rounded"></p-button>
      </div>
    </div>
    <small class="text-red-500" *ngIf="currentFileUploading.errorUploading">
      {{ 'private.knowledgePage.addSourceModal.document.errors.uploadError' | translate }}
    </small>
  </div>
  <div class="flex justify-content-center gap-5">
    <button pButton pRipple type="submit" [loading]="addLoading" [disabled]="documentForm.invalid" [label]="'Add'"></button>
  </div>
</form>
