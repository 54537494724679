import { Component, EventEmitter, Output } from '@angular/core';
import { STARTING_GUIDE_STEPS } from '../../constants/starting-guide-steps.consts';
import { IStartingGuideSteps } from '../../models/starting-guide-steps.model';
import { startingGuideAnim } from '../../animations/starting-guide-switch.anim';

@Component({
  selector: 'app-starting-guide',
  templateUrl: './starting-guide.component.html',
  styleUrls: ['./starting-guide.component.scss'],
  animations: [startingGuideAnim],
})
export class StartingGuideComponent {
  @Output() onCreateClick: EventEmitter<any> = new EventEmitter();

  readonly startingGuideSteps = STARTING_GUIDE_STEPS;

  activeIndex: number = 0;
  selectedStep?: IStartingGuideSteps;

  constructor() {
    this.setSelectedStep(this.activeIndex);
  }

  onActiveItemIndexChange(id: number) {
    this.activeIndex = id;
    this.setSelectedStep(id);
  }

  private setSelectedStep(id: number) {
    this.selectedStep = this.startingGuideSteps.find((v) => v.itemId === id);
  }

  emitClick() {
    this.onCreateClick.emit();
  }
}
