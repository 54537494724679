export interface IAssistantOrganization {
  type: string;
  name: string;
  description: string;
}

export class AssistantOrganization {
  type: string = '';
  name: string = '';
  description: string = '';

  constructor(res?: IAssistantOrganization) {
    if (res) {
      this.type = res.type;
      this.name = res.name;
      this.description = res.description;
    }
  }
}
