interface IActorConfiguration {
  assistantId: string;
  functioning: string;
}

class ActorConfiguration {
  assistantId: string = '';
  functioning: string = '';

  constructor(res?: IActorConfiguration) {
    if (res) {
      this.assistantId = res.assistantId;
      this.functioning = res.functioning;
    }
  }
}

interface ILayerActor {
  actorId: number;
  type: string;
  configuration: IActorConfiguration;
}

class LayerActor {
  actorId: number = 0;
  type: string = '';
  configuration?: ActorConfiguration;

  constructor(res?: ILayerActor) {
    if (res) {
      this.actorId = res.actorId;
      this.type = res.type;
      this.configuration = new ActorConfiguration(res.configuration);
    }
  }
}

interface IPipelineLayer {
  layerId: number;
  timeout: number;
  outputTarget: number[];
  actors?: ILayerActor[];
}

class PipelineLayer {
  layerId: number = 0;
  timeout: number = 0;
  outputTarget: number[] = [];
  actors: LayerActor[] = [];

  constructor(res?: IPipelineLayer) {
    if (res) {
      this.layerId = res.layerId;
      this.timeout = res.timeout;
      this.outputTarget = res.outputTarget;
      if (res.actors?.length) this.actors = res.actors.map((actor) => new LayerActor(actor));
    }
  }
}

export interface IPipelineConfig {
  inputFormat: string;
  outputFormat: string;
  layers?: IPipelineLayer[];
}

export class PipelineConfig {
  inputFormat: string = '';
  outputFormat: string = '';
  layers: PipelineLayer[] = [];

  constructor(res?: IPipelineConfig) {
    //@todo: IVAN WTF!!!! QUESTA NON è LA MIA IDEA DI IMPLEMENTA VELOCEMENTE
    //ANCHE PERCHE NON TI HO NEMMENO PASSATO IL FORMATO E QUESTO E' SBAGLIATO
    if (res) {
      this.inputFormat = res.inputFormat;
      this.outputFormat = res.outputFormat;
      if (res.layers?.length) this.layers = res.layers.map((layer) => new PipelineLayer(layer));
    }
  }
}

export interface IPipeline {
  id: string;
  name: string;
  config: IPipelineConfig;
}

export class Pipeline {
  id: string = '';
  name: string = '';
  config?: PipelineConfig;

  constructor(res?: IPipeline) {
    if (res) {
      this.id = res.id;
      this.name = res.name;
      this.config = new PipelineConfig(res.config);
    }
  }
}
