<div class="mt-4">
  @if (authenticated) {
  <span class="p-float-label w-full">
    <p-dropdown [options]="bots" optionLabel="name" optionValue="id" styleClass="w-full" appendTo="body" [(ngModel)]="selectedBot"></p-dropdown>
    <label for="bots">Seleziona l'assistente con cui continuare</label>
  </span>
  <div class="flex justify-content-end mt-4">
    <button pButton label="Continua" [disabled]="!selectedBot" (click)="goToShared()"></button>
  </div>
  } @else {
  <div class="flex flex-column">
    <h2 class="font-light login-text">Per continuare questa conversazione, è necessario accedere a Pupau</h2>
    <span class="flex flex-column align-items-center justify-content-center">
      <div class="md:col-6 col-12">
        <button pButton label="Accedi" class="w-full" (click)="closeModal()" [routerLink]="['/', 'public', 'auth', 'login']"></button>
      </div>
      <span>---- oppure ----</span>
      <div class="md:col-6 col-12">
        <button
          pButton
          label="Registrati gratuitamente"
          class="w-full"
          (click)="closeModal()"
          [routerLink]="['/', 'public', 'auth', 'register']"
        ></button>
      </div>
    </span>
  </div>
  }
</div>
