import { Component } from '@angular/core';
import { Share } from '../../models/share.model';
import { ShareService } from 'src/app/main/services/share.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-share-modal-wrapper',
  templateUrl: './share-modal-wrapper.component.html',
  styleUrl: './share-modal-wrapper.component.scss',
})
export class ShareModalWrapperComponent {
  sharedConversation?: Share;
  isLoading: boolean = true;

  constructor(private readonly shareService: ShareService, private readonly config: DynamicDialogConfig, private readonly ref: DynamicDialogRef) {
    const id = this.config.data.id;

    this.shareService
      .getSharedConversationById$(id)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (conv) => {
          this.sharedConversation = conv;
        },
      });
  }

  get conversationPublicLink() {
    return `${window.origin}/share/${this.sharedConversation?.id}`;
  }

  closeModal() {
    this.ref.close();
  }
}
