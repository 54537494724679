<form class="mt-4 flex flex-column gap-5" [formGroup]="createKeyForm" (ngSubmit)="createKey()">
  <span class="w-full">
    <h4 for="name" class="apikey-input-label mb-1">{{ 'private.configurationPage.integrationsPage.createAPIKeyModal.apiKeyName' | translate }}</h4>
    <p class="text-gray-400">{{ 'private.configurationPage.integrationsPage.createAPIKeyModal.subtitle' | translate }}</p>
    <input pInputText id="name" class="w-full" formControlName="name" />
  </span>
  <div class="flex justify-content-end">
    <button
      pButton
      type="submit"
      [label]="'private.configurationPage.integrationsPage.createAPIKeyModal.create' | translate"
      [disabled]="createKeyForm.invalid"
      [loading]="isCreating"
    ></button>
  </div>
</form>
