<span class="h-full flex flex-column justify-content-between">
  <span>
    <h3>
      Aggiungi qualche dettaglio su come desideri che l’assistente risponda ai/agli {{ creationWizard.targetAudience }}. Completa la frase descrivendo
      come desideri la risposta: La Risposta dell’assistente dovrà aiutare {{ creationWizard.targetAudience }} a ...
    </h3>
    <textarea
      pInputTextarea
      class="w-full"
      [(ngModel)]="creationWizard.replyExtraDetails"
      placeholder="Testo libero, ad esempio: 

      - Discernere il livello di gravità dell'infrazione, comprendere che tipo di sanzione applicare e, se la sanzione prevede diversi livelli di entità, dovrai indicare e motivare quale è livello di sanzione applicabile in relazione alla gravità del caso specifico.
      
      - Fornisci i parametri per la graduazione della gravità dell'infrazione, gli importi specifici delle sanzioni e le altre conseguenze penali, civili e amministrative previste per il contesto specifico fornito e considerando ogni fattispecie di reato ipotizzabile e di condizione aggravante o attenuante per la sua graduazione.
      
      - Dovrai inoltre concludere consigliando la procedura più corretta da mettere in atto nella gestione del caso specifico e i dettagli di maggior rilevanza da menzionare all'atto della redazione del Verbale da presentare all'Ufficio della Polizia dello Stato Italiano.
      "
      [autoResize]="true"
    ></textarea>
  </span>
  <div class="flex justify-content-end">
    <button pButton label="View recap" (click)="setExtraDetails()"></button>
  </div>
</span>
