import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChatUpdateEvent } from '../shared/models/web-component-config.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { UsersService } from './users.service';
import { Token } from 'src/app/core/models/token.model';

@Injectable({
  providedIn: 'root',
})
export class WebComponentService {
  private readonly events$: BehaviorSubject<ChatUpdateEvent> = new BehaviorSubject({});
  private readonly production = environment.production;

  private token?: Token;

  constructor(private readonly authService: AuthService, private readonly userService: UsersService) {
    this.userService.getToken$().subscribe({
      next: (t) => (this.token = t),
    });
  }

  changeWebComponentUrl() {
    const scriptEl = document.createElement('script');
    scriptEl.src = `https://storage.googleapis.com/cdn.pupau.ai/pupau-assistant/${
      !this.production ? 'dev' : 'stable'
    }/latest.js?d=${DateTime.now().toMillis()}`;
    scriptEl.async = true;
    scriptEl.defer = true;
    document.body.appendChild(scriptEl);
  }

  get getEvents$() {
    return this.events$;
  }

  set setEvents$(event: ChatUpdateEvent) {
    this.events$.next(event);
  }

  refreshToken() {
    if (this.token) {
      this.authService.refreshToken(this.token);
    }
  }
}
