import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IAvailableSubscription, IStripeCheckoutDTO } from '../../models/subscription.model';
import { SubscriptionService } from 'src/app/main/services/subscription.service';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs';
import { ToastService } from 'src/app/main/services/toast.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';

@Component({
  selector: 'app-apply-promo-code-modal',
  templateUrl: './apply-promo-code-modal.component.html',
  styleUrl: './apply-promo-code-modal.component.scss',
})
export class ApplyPromoCodeModalComponent {
  private readonly useProductionIds = environment.production;
  private readonly isYearlyPayment: boolean;

  isLoading: boolean = false;

  subscription: IAvailableSubscription;
  promoCode: string = '';

  constructor(
    public readonly ref: DynamicDialogRef,
    private readonly toastService: ToastService,
    private readonly config: DynamicDialogConfig,
    private readonly mainLanguageService: MainLanguageService,
    private readonly subscriptionService: SubscriptionService
  ) {
    this.subscription = this.config.data.subscription;
    this.isYearlyPayment = this.config.data.isYearlyPayment;
  }

  stripeCheckout() {
    this.isLoading = true;
    const body: IStripeCheckoutDTO = {
      subscriptionId: this.getCorrectSubscriptionId(),
    };
    if (this.promoCode) body.discountCode = this.promoCode;
    this.subscriptionService
      .checkoutStripe$(body)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (v) => {
          this.ref.close();
          window.open(v.url);
        },
        error: ({ error }) => this.toastService.addError({ summary: this.mainLanguageService.instant('common.error'), detail: error.message }),
      });
  }

  private getCorrectSubscriptionId() {
    if (this.useProductionIds) {
      return this.isYearlyPayment ? this.subscription.subscriptionId.production.annual : this.subscription.subscriptionId.production.monthly;
    } else {
      return this.isYearlyPayment ? this.subscription.subscriptionId.staging.annual : this.subscription.subscriptionId.staging.monthly;
    }
  }
}
