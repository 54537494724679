import { Pipe, PipeTransform } from '@angular/core';
import { LANGUAGES } from '../constants/languages.items';

@Pipe({
  name: 'language',
})
export class LanguagePipe implements PipeTransform {
  transform(outerValue?: string): string {
    // return Object.entries(LANGUAGE).find(([key, _value]) => value === _value)?.[0]!;
    return LANGUAGES.find(({ value }) => value === outerValue)?.label!;
  }
}
