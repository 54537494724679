import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { AssistantUse, IAssistantUse, ICreateAssistantUseDTO } from '../shared/models/assistant-use.model';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssistantUsersService {
  constructor(private readonly apiService: ApiService) {}

  getUsersForBot$(botId: string, page: number, items: number) {
    return this.apiService.get<{ items: IAssistantUse[]; total: number }>(`chat-bots/${botId}/users?page=${page}&items=${items}`).pipe(
      map(({ items, total }) => ({
        items: items.map((use) => new AssistantUse(use)),
        total,
      }))
    );
  }

  createAssistantUse$(botId: string, userId: string, body: Partial<ICreateAssistantUseDTO>) {
    return this.apiService.post<any>(`chat-bots/${botId}/users/${userId}`, body);
  }

  editAssistantUse$(botId: string, userId: string, assistantUseId: string, body: Partial<ICreateAssistantUseDTO>) {
    return this.apiService.patch<any>(`chat-bots/${botId}/users/${userId}/assistant-use/${assistantUseId}`, body);
  }

  deleteAssistantUse$(botId: string, userId: string, assistantUseId: string) {
    return this.apiService.delete(`chat-bots/${botId}/users/${userId}/assistant-use/${assistantUseId}`);
  }
}
