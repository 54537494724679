import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { Conversation, IConversation } from 'src/app/main/private/chat/api/conversation.model';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { ShareService } from 'src/app/main/services/share.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { ICreateSharedConversationDTO, SharedVisibility, VISIBILITIES } from '../../models/share.model';

@Component({
  selector: 'app-share-creation-modal',
  templateUrl: './share-creation-modal.component.html',
  styleUrls: ['./share-creation-modal.component.scss'],
})
export class ShareCreationModalComponent {
  readonly visibilities = VISIBILITIES;

  loading: boolean = false;
  hasConversationBeenCreated: boolean = false;
  createdConversationId?: string;

  conversation: Conversation;
  shareForm: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly ref: DynamicDialogRef,
    private readonly shareService: ShareService,
    private readonly config: DynamicDialogConfig,
    private readonly toastService: ToastService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.conversation = this.config.data.conversation;

    this.shareForm = this.fb.group({
      conversationId: new FormControl(this.conversation.id),
      title: new FormControl(this.conversation.title, [Validators.required]),
      visibility: new FormControl<SharedVisibility>('public', [Validators.required]),
      enableComments: new FormControl(false),
      enableUpdate: new FormControl(false),
      enableReuse: new FormControl(false),
    });
  }

  onFormSubmit() {
    const body = this.shareForm.getRawValue() as ICreateSharedConversationDTO;

    this.loading = true;

    this.shareService
      .createSharedConversation$(body)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (share) => {
          this.hasConversationBeenCreated = true;
          this.createdConversationId = share.id;
        },
        error: ({ error }) => {
          this.toastService.addError({
            summary: this.mainLanguageService.instant('common.error'),
            detail: error.message,
          });
        },
      });
  }

  get sharedUI() {
    return `${window.origin}/share/${this.createdConversationId}`;
  }

  copyURL() {
    navigator.clipboard.writeText(this.sharedUI).then(() => {
      this.toastService.addSuccess({
        summary: this.mainLanguageService.instant('common.success'),
        detail: 'URL copiata con successo',
      });
    });
  }
}
