import { Injectable } from '@angular/core';
import { APIKey, IAPIKey, ICreateAPIKeyDTO } from '../shared/models/api-key.model';
import { map } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiKeyService {
  constructor(private readonly apiService: ApiService) {}

  getApiKeys$(companyId: string, botId: string) {
    return this.apiService
      .get<IAPIKey[]>(`companies/${companyId}/api-keys?assistantId=${botId}`)
      .pipe(map((array) => array.map((k) => new APIKey(k))));
  }

  getApiKeyById$(companyId: string, keyId: string) {
    return this.apiService.get<IAPIKey>(`companies/${companyId}/api-keys/${keyId}`).pipe(map((key) => new APIKey(key)));
  }

  createApiKey$(companyId: string, body: Partial<ICreateAPIKeyDTO>) {
    return this.apiService.post<IAPIKey>(`companies/${companyId}/api-keys`, body).pipe(map((key) => new APIKey(key)));
  }

  editApiKey$(companyId: string, keyId: string, body: Partial<ICreateAPIKeyDTO>) {
    return this.apiService.patch<any>(`companies/${companyId}/api-keys/${keyId}`, body);
  }

  deleteApiKey$(companyId: string, keyId: string) {
    return this.apiService.delete<any>(`companies/${companyId}/api-keys/${keyId}`);
  }
}
