import { HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { first } from 'lodash';
import { FileValidators } from 'ngx-file-drag-drop';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, catchError, concat, delay, finalize, map, of, retry, switchMap, tap, throwError, timer } from 'rxjs';
import { DataSourcesService } from 'src/app/main/services/data-sources.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { IFileUploadProgressBar } from 'src/app/main/shared/models/add-knowledge-base.model';
import { IAddDocumentDTO, IFileTraining } from 'src/app/main/shared/models/chat-bot-source.model';

@Component({
  selector: 'app-add-document-tab',
  templateUrl: './add-document-tab.component.html',
  styleUrls: ['./add-document-tab.component.scss'],
})
export class AddDocumentTabComponent {
  idBot: string = '';
  documentForm: FormGroup = new FormGroup({});

  completedFiles: number = 0;

  totalFiles: number = 0;

  readonly eventType = HttpEventType;

  readonly allowedFileExtensions = ['.csv', '.xlsx', '.pdf', '.docx', '.doc', '.txt'];
  readonly allowedFileExtensionsForValidation = ['csv', 'xlsx', 'pdf', 'docx', 'doc', 'txt'];

  progressBars: {
    [key: string]: IFileUploadProgressBar;
  } = {};

  currentFileUploading?: IFileUploadProgressBar;
  failedUpload?: Observable<any>;

  addLoading: boolean = false;

  constructor(
    private readonly mainLanguageService: MainLanguageService,
    private readonly dataSourceService: DataSourcesService,
    private readonly config: DynamicDialogConfig,
    private readonly ref: DynamicDialogRef,
    private readonly fb: FormBuilder
  ) {
    this.idBot = this.config.data.id;

    this.documentForm = this.fb.group({
      file: new FormControl(
        [],
        [FileValidators.required, FileValidators.fileExtension(this.allowedFileExtensionsForValidation), FileValidators.uniqueFileNames]
      ),
    });
  }

  /** @deprecated Please use `addDocument()` */
  addDocumentLegacy() {
    const calls = this.createRequestsArrayLegacy();
    this.addLoading = true;
    concat(...calls)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            if (!this.currentFileUploading?.errorUploading) {
              this.addLoading = false;
              this.ref.close({ added: true, error: undefined });
            }
          }, 2000);
        })
      )
      .subscribe({
        next: ({ type }) => {
          if (this.eventType.Sent === type) {
            this.completedFiles++;
          }
        },
        // error: ({ error }) => this.ref.close({ added: false, error }),
        error: (errorObservable: Observable<any>) => (this.failedUpload = errorObservable),
      });
  }

  addDocument() {
    this.createSignedUrlBody()
      .pipe(
        tap(() => (this.addLoading = true)),
        map((res) => this.createRequestsArray(res)),
        switchMap((r) =>
          concat(...r).pipe(
            finalize(() => {
              setTimeout(() => {
                if (!this.currentFileUploading?.errorUploading) {
                  this.addLoading = false;
                  this.ref.close({ added: true, error: undefined });
                }
              }, 2000);
            })
          )
        )
      )
      .subscribe({
        next: (event) => {
          if (this.eventType.Response === event.type) {
            this.completedFiles++;
            if (this.currentFileUploading) {
              const value = Object.values(this.progressBars)[this.currentFileUploading?.order + 1];
              if (value) this.currentFileUploading = Object.values(this.progressBars)[this.currentFileUploading?.order + 1];
            }
          }
        },
        // error: ({ error }) => this.ref.close({ added: false, error }),
        error: (errorObservable: Observable<any>) => (this.failedUpload = errorObservable),
      });
  }

  createSignedUrlBody() {
    const fileArray = this.documentForm.get('file')?.value as File[];
    const body = fileArray.map((f) => ({ filename: f.name, description: f.name }));

    return this.dataSourceService.createFileSignedUrl(this.idBot, body);
  }

  /** @deprecated Please use `createRequestsArray()` */
  createRequestsArrayLegacy() {
    const calls: Observable<any>[] = [];
    const fileArray = this.documentForm.get('file')?.value as File[];
    fileArray.forEach((file, i) => {
      const body: IAddDocumentDTO = {
        description: file.name,
        file,
      };
      calls.push(
        this.dataSourceService.createFileLegacy(this.idBot, body).pipe(
          tap((event) => {
            this.currentFileUploading = this.progressBars[file.name];
            this.progressBars[file.name] = {
              name: file.name,
              order: i,
              type: event.type,
              progress: event.total ? Math.round((100 * event.loaded) / event.total) : 0,
              errorUploading: false,
            };
          }),
          retry(3),
          catchError(() => {
            if (this.currentFileUploading) {
              this.currentFileUploading.errorUploading = true;
            }
            return throwError(() => calls[i]);
          }),
          delay(2000)
        )
      );
    });
    this.totalFiles = calls.length;
    return calls;
  }

  createRequestsArray(res: IFileTraining[]) {
    const calls: Observable<any>[] = [];
    const fileArray = this.documentForm.get('file')?.value as File[];
    fileArray.forEach((file, i) => {
      const signedUrl = res.find((f) => f.filename === file.name)?.signedUrl!;

      this.progressBars[file.name] = {
        name: file.name,
        progress: 0,
        type: this.eventType.Sent,
        order: i,
        errorUploading: false,
      };

      if (!this.currentFileUploading) {
        this.currentFileUploading = Object.values(this.progressBars)[0];
      }

      calls.push(
        this.dataSourceService.createFile(signedUrl, file).pipe(
          map(() => {
            this.progressBars[file.name] = {
              name: file.name,
              order: i,
              type: this.eventType.Response,
              progress: 100,
              errorUploading: false,
            };
            this.currentFileUploading = this.progressBars[file.name];
            return this.progressBars[file.name];
          }),
          retry({
            count: 3,
            delay: (error, count) => timer(2 ^ (count * 500)),
          }),
          catchError((e) => {
            if (this.currentFileUploading) {
              this.currentFileUploading.errorUploading = true;
            }
            return throwError(() => calls[i]);
          }),
          delay(2000)
        )
      );
    });
    this.totalFiles = calls.length;
    return calls;
  }

  getFileUploadError() {
    if (this.documentForm.get('file')?.errors) {
      if (this.documentForm.get('file')?.errors!['fileExtension']) {
        return this.mainLanguageService.instant('private.knowledgeBase.addSourceModal.document.errors.fileExtension', {
          span: `<span class="font-semibold">${this.allowedFileExtensions.join(', ')}</span>`,
        });
      } else if (this.documentForm.get('file')?.errors!['uniqueFileNames']) {
        return this.mainLanguageService.instant('private.knowledgeBase.addSourceModal.document.errors.uniqueFileNames');
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  retryUpload() {}
}
