import { HUNDRED } from 'src/app/main/shared/constants/chat.consts';
import { Message } from './message.model';

export interface IReaction {
  comment?: string;
  rating?: number;
  likes?: number;
  dislikes?: number;
}

export interface IReactionDTO {
  comment?: string;
  rating?: number;
}

export interface IConversation {
  chatBotId: string | null;
  marketplaceId: string | null;
  id: string;
  data: any;
  image: string;
  status: string;
  messages: Message[];
  source: SourceType | null;
  createdAt: string | null;
  lastQueryTimestamp: string | null;
  reaction: IReaction;
  queryCount: number;
  userId: string;
  title: string;

  user?: {
    name: string;
    surname: string;
  };

  deleteObject: {
    isBeingDeleted: boolean;
    deleteTimeLeft: number;
    softDeleted: boolean;
  };

  editObject: {
    inplaceActive: boolean;
    currentValue: string;
    isHovered: boolean;
  };
}

export class Conversation {
  chatBotId: string | null = null;
  marketplaceId: string | null = null;
  id: string = '';
  title: string = '';
  data: any;
  image: string = '';
  status: string = '';
  messages: Message[] = [];
  source: SourceType | null = null;
  createdAt: string | null = null;
  lastQueryTimestamp: string | null = null;
  reaction: IReaction = {
    comment: '',
    rating: 0,
    likes: 0,
    dislikes: 0,
  };
  queryCount: number = 0;
  userId: string = '';
  user?: { name: string; surname: string } | undefined;
  deleteObject: { isBeingDeleted: boolean; deleteTimeLeft: number; softDeleted: boolean } = {
    isBeingDeleted: false,
    deleteTimeLeft: 0,
    softDeleted: false,
  };
  editObject: { inplaceActive: boolean; currentValue: string; isHovered: boolean } = {
    inplaceActive: false,
    currentValue: '',
    isHovered: false,
  };

  constructor(res?: IConversation) {
    if (res) {
      if (res.chatBotId) this.chatBotId = res.chatBotId;
      if (res.marketplaceId) this.marketplaceId = res.marketplaceId;
      this.id = res.id;
      this.title = res.title;
      this.data = res.data;
      this.image = res.image;
      this.status = res.status;
      this.messages = res.messages;
      this.source = res.source;
      this.createdAt = res.createdAt;
      this.lastQueryTimestamp = res.lastQueryTimestamp;
      this.reaction = res.reaction;
      this.queryCount = res.queryCount;
      this.userId = res.userId;
      this.title = res.title;
      this.user = res.user;

      this.deleteObject = {
        isBeingDeleted: false,
        deleteTimeLeft: HUNDRED,
        softDeleted: false,
      };
      this.editObject = {
        inplaceActive: false,
        currentValue: res.title,
        isHovered: false,
      };
    }
  }
}

export type SourceType = 'WEB' | 'ANDROID' | 'IOS';
