import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { ICreateSharedConversationDTO, IShare, Share } from '../shared/models/share.model';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  constructor(private readonly apiService: ApiService) {}

  getFeeds(page: number, items: number) {
    return this.apiService
      .get<{ items: IShare[]; total: number }>(`social/feed?page=${page}&items=${items}`)
      .pipe(map(({ items, total }) => ({ items: items.map((c) => new Share(c)), total })));
  }

  getSharedConversations$() {
    return this.apiService.get<{ items: IShare[] }>(`share`).pipe(map(({ items }) => items.map((c) => new Share(c))));
  }

  getSharedConversationById$(id: string) {
    return this.apiService.get<IShare>(`share/${id}`, {}).pipe(map((s) => new Share(s)));
  }

  createSharedConversation$(body: ICreateSharedConversationDTO) {
    return this.apiService
      .post<IShare>(`share`, body, {
        'Allow-Interceptor': 'true',
      })
      .pipe(map((s) => new Share(s)));
  }
}
