import { DateTime } from 'luxon';

export interface IStarterMessageHistory {
  id: string;
  createdAt: string;
  updatedAt: string;
  chatBotId: string;
  starterMessage: string;
}

export class StarterMessageHistory {
  id: string = '';
  createdAt: DateTime = DateTime.now();
  updatedAt: DateTime = DateTime.now();
  chatBotId: string = '';
  starterMessage: string = '';

  constructor(res?: IStarterMessageHistory) {
    if (res) {
      this.id = res.id;
      this.createdAt = DateTime.fromISO(res.createdAt);
      this.updatedAt = DateTime.fromISO(res.updatedAt);
      this.chatBotId = res.chatBotId;
      this.starterMessage = res.starterMessage;
    }
  }
}
