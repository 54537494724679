@if(history.length) {
<div class="flex flex-row" style="height: 40rem">
  <div class="overflow-y-scroll" [class]="selectedHistory ? 'w-6' : 'w-full'">
    @for (message of history; track $index; let last = $last) {
    <div class="w-full" (click)="setSelected(message)">
      <span class="cursor-pointer history-card">
        <p class="font-semibold mb-0">{{ message.createdAt | dateTime : 'F' }}</p>
        <p class="mb-0">{{ message.starterMessage | truncate : 50 }}</p>
      </span>
      @if (!last) {
      <p-divider styleClass="my-2"></p-divider>
      }
    </div>
    }
  </div>
  @if (selectedHistory) {
  <p-divider layout="vertical"></p-divider>
  <div class="w-6 flex flex-column justify-content-between overflow-y-scroll relative">
    <p class="text-lg">{{ selectedHistory.starterMessage }}</p>
    <div class="sticky left-0 bottom-0 bg-white flex justify-content-end pt-3 pb-1 pr-1">
      <button pButton [label]="'common.apply' | translate" [loading]="isApplying" (click)="applyStarterMessage()"></button>
    </div>
  </div>
  }
</div>
} @else {
<div class="w-full h-15rem flex justify-content-center align-items-center">
  <h4 class="text-gray-400 font-italic">{{ 'private.configurationPage.assistantPage.programmingHistoryModal.noData' | translate }}</h4>
</div>
}
