import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AssistantCreationWizard } from '../shared/models/assistant-creation.model';

@Injectable({
  providedIn: 'root',
})
export class AssistantCreationService {
  private readonly assistantCreationWizard$: BehaviorSubject<AssistantCreationWizard> = new BehaviorSubject(new AssistantCreationWizard());

  private readonly nextStep$: Subject<boolean> = new Subject();

  constructor() {}

  getAssistantCreationWizard$() {
    return this.assistantCreationWizard$.asObservable();
  }

  getNextStep$() {
    return this.nextStep$.asObservable();
  }

  setNextStep(value: boolean) {
    this.nextStep$.next(value);
  }

  setAssistantCreationWizard(wizard: AssistantCreationWizard) {
    this.assistantCreationWizard$.next(wizard);
  }
}
