import { Component } from '@angular/core';
import { AssistantCreationWizard } from '../../models/assistant-creation.model';
import { AssistantType } from '../../enums/assistant-creation-type.enum';
import { AssistantCreationService } from 'src/app/main/services/assistant-creation.service';

@Component({
  selector: 'app-assistant-creation-wizard',
  templateUrl: './assistant-creation-wizard.component.html',
  styleUrls: ['./assistant-creation-wizard.component.scss'],
})
export class AssistantCreationWizardComponent {
  readonly AssistantType = AssistantType;
  creationWizard: AssistantCreationWizard = new AssistantCreationWizard();

  constructor(private readonly assistantCreationService: AssistantCreationService) {}

  setType(type: AssistantType) {
    this.creationWizard.setType(type);

    this.assistantCreationService.setAssistantCreationWizard(this.creationWizard);
  }
}
