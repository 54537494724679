import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, combineLatest, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs';
import { IRouterStoreState } from 'src/app/core/store/router/router.reducer';
import { selectIsOnUrlSegment } from 'src/app/core/store/router/router.selectors';

@Directive({
  selector: '[hideInSegments]',
})
export class HideInSegmentDirective {
  private segments$ = new BehaviorSubject<string[]>([]);
  private destroy$ = new Subject<void>();

  appendClass: boolean = false;
  class: string = '';

  constructor(
    private readonly elementRef: TemplateRef<HTMLElement>,
    private viewContainer: ViewContainerRef,
    private readonly routerStore: Store<{ routerState: IRouterStoreState }>
  ) {
    this.segments$
      .pipe(
        distinctUntilChanged(),
        switchMap((segments) => combineLatest(segments.map((segment) => this.routerStore.select(selectIsOnUrlSegment(segment))))),
        map((results) => results.some((isOnSegment) => isOnSegment)),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((isOnAnySegment: boolean) => {
        this.updateView(isOnAnySegment);
      });
  }

  @Input() set hideInSegments(params: { segments: string[]; appendClass?: boolean; class?: string }) {
    if (params.appendClass) this.appendClass = params.appendClass;
    if (params.class) this.class = params.class;
    this.segments$.next(params.segments);
  }

  private updateView(isOnAnySegment: boolean): void {
    if (this.appendClass && this.class && this.viewContainer.length === 0) this.viewContainer.createEmbeddedView(this.elementRef);
    if (isOnAnySegment) {
      if (this.appendClass && this.class) {
        (this.elementRef.elementRef.nativeElement.previousSibling as HTMLElement).classList.remove(...this.class.split(' '));
      } else {
        this.viewContainer.clear();
      }
    } else {
      if (this.appendClass && this.class) {
        (this.elementRef.elementRef.nativeElement.previousSibling as HTMLElement).classList.add(...this.class.split(' '));
      }
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(this.elementRef);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
