import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LevelDirective } from './directives/level.directive';

/**
 *
 * Contiene tutte le funzionalità base dell’applicazione.
 *
 */
@NgModule({
  declarations: [LevelDirective],
  imports: [CommonModule],
  exports: [LevelDirective],
})
export class CoreModule {}
