<form
  *ngIf="!hasConversationBeenCreated; else conversationCreated"
  [formGroup]="shareForm"
  (ngSubmit)="onFormSubmit()"
  class="mt-5 flex flex-column gap-5"
>
  <span class="p-float-label">
    <input pInputText id="title" class="w-full" formControlName="title" />
    <label htmlFor="title">Titolo conversazione</label>
  </span>
  <span class="p-float-label">
    <p-dropdown id="visibility" [options]="visibilities" optionLabel="label" optionValue="value" appendTo="body" styleClass="w-full"></p-dropdown>
    <label htmlFor="visibility">Visibilità</label>
  </span>
  <span class="flex flex-column gap-3">
    <p-checkbox [binary]="true" formControlName="enableComments" label="Abilità commenti"></p-checkbox>
    <p-checkbox [binary]="true" formControlName="enableUpdate" label="Abilità aggiornamento chat"></p-checkbox>
    <p-checkbox [binary]="true" formControlName="enableReuse" label="Abilità riutilizzo chat"></p-checkbox>
  </span>
  <div class="flex justify-content-end">
    <button type="submit" pButton label="Condividi" [disabled]="shareForm.invalid" [loading]="loading"></button>
  </div>
</form>
<ng-template #conversationCreated>
  <h3 class="text-center">Conversazione creata con successo!</h3>
  <p class="text-center">Ecco il link della conversazione condivisa:</p>
  <div class="text-center">
    <a class="text-center text-lg underline text-blue-400" [href]="sharedUI" target="_blank">{{ sharedUI }}</a>
    <sup><i class="pi pi-copy text-blue-400 cursor-pointer" (click)="copyURL()"></i></sup>
  </div>
</ng-template>
