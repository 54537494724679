import { Pipe, PipeTransform } from '@angular/core';
import MarkdownIt, { Token } from 'markdown-it';
//@todo: Quix fix, si può migliorare
// @ts-ignore
import iterator from 'markdown-it-for-inline';
import hljs from 'highlight.js';

@Pipe({
  name: 'markdownParser',
})
export class MarkdownParserPipe implements PipeTransform {
  private mdParser: MarkdownIt = MarkdownIt({ breaks: true, linkify: true, highlight: this.customCodeHighlighter });

  constructor() {
    this.overrideLinkOpenRules();
  }

  transform(value: string): string {
    if (value) {
      value = value.replaceAll(/^---$/g, '\n --- \n');
      //value = value.replaceAll(/---/g, '\n --- \n');
      //value = value.replaceAll('\n', '\n ');
      return this.mdParser.render(value);
    } else {
      return '';
    }
  }

  private overrideLinkOpenRules() {
    this.mdParser.use(iterator, 'url_new_win', 'link_open', function (tokens: Token[], idx: number) {
      tokens[idx].attrSet('target', '_blank');
    });
  }

  private customCodeHighlighter(str: string, lang: string, attrs: string) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return '<pre><code class="hljs">' + hljs.highlight(str, { language: lang, ignoreIllegals: true }).value + '</code></pre>';
      } catch (__) {}
    }
    if (!this.mdParser) {
      //@todo: workaround da approfondire
      this.mdParser = MarkdownIt({ breaks: true, linkify: true, highlight: this.customCodeHighlighter });
    }
    return '<pre><code class="hljs">' + this.mdParser.utils.escapeHtml(str) + '</code></pre>';
  }
}
