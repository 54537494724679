import { AddDocumentTabComponent } from '../components/knowledge-base-table/add-knowledge-modal/add-document-tab/add-document-tab.component';
import { AddDriveTabComponent } from '../components/knowledge-base-table/add-knowledge-modal/add-drive-tab/add-drive-tab.component';
import { AddDropboxTabComponent } from '../components/knowledge-base-table/add-knowledge-modal/add-dropbox-tab/add-dropbox-tab.component';
import { AddOnedriveTabComponent } from '../components/knowledge-base-table/add-knowledge-modal/add-onedrive-tab/add-onedrive-tab.component';
import { AddUrlTabComponent } from '../components/knowledge-base-table/add-knowledge-modal/add-url-tab/add-url-tab.component';
import { IAddKnowledgeBaseTab } from '../models/add-knowledge-base.model';

export const ADD_KNOWLEDGE_BASE_TABS: IAddKnowledgeBaseTab[] = [
  {
    label: 'url',
    component: AddUrlTabComponent,
  },
  {
    label: 'document',
    component: AddDocumentTabComponent,
  },
  {
    label: 'googleDrive',
    component: AddDriveTabComponent,
    inputs: { active: false },
  },
  {
    label: 'microsoftOneDrive',
    component: AddOnedriveTabComponent,
  },
  {
    label: 'dropbox',
    component: AddDropboxTabComponent,
  },
];
