<div class="flex flex-row gap-2">
  <p-chip
    styleClass="mt-2 ml-2 h-2rem cursor-pointer"
    (click)="showKbMessage()"
    [ngClass]="{ 'kb-present': message?.kb, 'kb-not-present': !message?.kb }"
  >
    <span class="pr-2">KB</span>
    <span class="flex align-items-center justify-content-center"><i class="w-full" [class]="message?.kb ? 'pi pi-check' : 'pi pi-times'"></i></span>
  </p-chip>
  <span class="flex align-items-center relative" *ngIf="message && message.kbReferences && message?.kbReferences?.length">
    <div *ngIf="!isKbReferencesLarge(); else longerKbReferences" id="kb-references">
      <ng-container [ngTemplateOutlet]="singleKbRef"></ng-container>
    </div>
    <ng-template #longerKbReferences>
      <span class="flex flex-column align-items-start relative">
        <p class="cursor-pointer my-auto underline" (click)="onViewAllKbClick()">Vedi tutti i riferimenti ({{ message.kbReferences.length }})</p>
        <!-- <ng-container *ngIf="isWholeKbListVisible" [ngTemplateOutlet]="singleKbRef"></ng-container> -->
      </span>
    </ng-template>
  </span>
</div>

<ng-template #singleKbRef>
  <div class="flex flex-column">
    <div
      *ngFor="let ref of message?.kbReferences; let i = index"
      (click)="setSelectedSource(ref)"
      class="text-sm font-italic text-gray-400"
      [ngClass]="{ 'ref-div': isKbReferencesLarge(), 'cursor-pointer': ref.type != 'FILE' }"
    >
      <p [ngClass]="{ 'my-3': isKbReferencesLarge(), 'my-1': !isKbReferencesLarge() }">
        <span class="font-semibold">{{ ref.type + ': ' }}</span>
        {{ ref.data | truncate : 50 }}
      </p>
      <p-divider styleClass="my-0" *ngIf="isKbReferencesLarge() && i < message!.kbReferences.length - 1"></p-divider>
    </div>
  </div>
</ng-template>

<p-dialog [(visible)]="isWholeKbListVisible" [modal]="true" [header]="'References'">
  <ng-container [ngTemplateOutlet]="singleKbRef"></ng-container>
</p-dialog>
