<div class="flex flex-column gap-5">
  <p class="my-auto">
    {{ 'private.configurationPage.advancedPage.deleteModal.secondConfirmation' | translate }}
  </p>
  <h5 class="my-auto" id="secure-string">{{ secureString }}</h5>
  <input pInputText [(ngModel)]="currentInputString" blockCopyPaste />
  <button
    pButton
    [disabled]="currentInputString != secureString"
    [loading]="loading"
    (click)="deleteBot()"
    [label]="'common.delete' | translate"
    class="p-button-danger"
  ></button>
</div>
