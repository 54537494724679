export const ORGANIZATION_TYPES = [
  {
    label: 'Compagnia',
    value: 'company',
  },
  {
    label: 'Non profit',
    value: 'non-profit',
  },
  {
    label: 'Sportiva',
    value: 'sport',
  },
];
