<p-header>
  <div class="flex justify-content-between pt-4">
    @if (sharedConversation) {
    <span>
      <a [href]="conversationPublicLink" target="_blank" class="flex align-items-center gap-1"
        >{{ conversationPublicLink }}<custom-icon [iconName]="'open_in_new'" [type]="'material'" class="flex" [class]="'text-sm'"></custom-icon
      ></a>
    </span>
    }
    <span>
      <i class="pi pi-times w-fit cursor-pointer" (click)="closeModal()"></i>
    </span>
  </div>
</p-header>

<div style="height: 92%">
  @if(!isLoading) {
  <app-share [sharedConversation]="sharedConversation" [isDialog]="true"></app-share>
  } @else {
  <div class="h-full flex justify-content-center align-items-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
  }
</div>
