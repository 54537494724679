import { Pipe, PipeTransform } from '@angular/core';
import { DateTime, Duration } from 'luxon';
import { Observable, map } from 'rxjs';
import { MainLanguageService } from '../../services/main-language.service';
import { TODAY } from '../constants/generic.consts';

@Pipe({
  name: 'lastMessage',
})
export class LastMessagePipe implements PipeTransform {
  private readonly today = TODAY.setZone('utc'); // Assicurati che TODAY sia in UTC
  private locale = DateTime.local().locale;

  private neverUsedWord = '';

  constructor(private readonly mainLanguageService: MainLanguageService) {}

  transform(value: DateTime | null, short: boolean = false): Observable<string> {
    return this.mainLanguageService.defaultLanguage$.pipe(
      map((l) => {
        if (l) this.locale = l;
        if (value) {
          const utcValue = value.setZone('utc'); // Assicurati che il value sia in UTC
          const difference = this.calculateDifference(utcValue);
          return this.timeAgo(difference, short);
        } else {
          this.neverUsedWord = this.mainLanguageService.instant('private.dashboardPage.neverUsed');
          return this.neverUsedWord;
        }
      })
    );
  }

  private calculateDifference(date: DateTime): Duration {
    // Se la data di confronto è nel futuro rispetto a TODAY
    if (date > this.today) {
      return date.diff(this.today, ['days', 'hours', 'minutes', 'seconds']);
    } else {
      return this.today.diff(date, ['days', 'hours', 'minutes', 'seconds']);
    }
  }

  private timeAgo(diff: Duration, short: boolean = false) {
    let value: number;
    let unit: Intl.RelativeTimeFormatUnit;
    let shortUnit: string;

    if (diff.days >= 1) {
      value = diff.days;
      unit = 'day';
      shortUnit = 'g';
    } else if (diff.hours > 0) {
      value = diff.hours;
      unit = 'hour';
      shortUnit = 'h';
    } else if (diff.minutes > 0) {
      value = diff.minutes;
      unit = 'minute';
      shortUnit = 'min';
    } else {
      value = diff.seconds;
      unit = 'second';
      shortUnit = 's';
    }

    // Usa Intl.RelativeTimeFormat per ottenere la parte "ago" localizzata
    const rtf = new Intl.RelativeTimeFormat(this.locale, { numeric: 'always' });
    const localizedAgo = rtf.format(-value, unit).split(' ').slice(-1)[0];

    if (short) {
      return `${value.toFixed(0)}${shortUnit} ${localizedAgo}`;
    } else {
      return rtf.format(-value.toFixed(0), unit);
    }
  }
}
