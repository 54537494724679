import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { TagsService } from 'src/app/main/services/tags.service';
import { KbTag } from '../../models/kb-tags.model';
import { AddTagModalComponent } from '../add-tag-modal/add-tag-modal.component';

@Component({
  selector: 'app-source-tags-list',
  templateUrl: './source-tags-list.component.html',
  styleUrls: ['./source-tags-list.component.scss'],
})
export class SourceTagsListComponent {
  @ViewChild('tagAdd') tagAdd?: ElementRef<HTMLInputElement>;
  @Input() tags: KbTag[] = [];
  @Input() botId: string = '';
  @Input() sourceId: string = '';
  @Input() sourceIds: string[] = [];
  @Input() showAdd: boolean = true;
  @Input() showDelete: boolean = true;

  @Output() onTagChanged: EventEmitter<KbTag> = new EventEmitter();

  inplaceActive: boolean = false;
  newTag: string = '';
  tagColor: string = '#FF0000';

  constructor(
    private readonly tagsService: TagsService,
    private readonly dialogService: DialogService,
    private readonly mainLanguageService: MainLanguageService
  ) {}

  deleteTag(tag: KbTag) {
    this.tagsService.deAssociateTag$(this.botId, tag.id, this.sourceId || this.sourceIds).subscribe({
      next: () => this.onTagChanged.emit(tag),
    });
  }

  addTag() {
    this.dialogService
      .open(AddTagModalComponent, {
        header: this.mainLanguageService.instant('private.sourceDetailsPage.document.addTag'),
        styleClass: 'md:w-7 w-full',
        data: {
          botId: this.botId,
          sourceId: this.sourceId,
          currentTags: this.tags,
        },
      })
      .onClose.subscribe({
        next: (v) => {
          if (v && v.updated) {
            this.onTagChanged.emit();
          }
        },
      });
    // this.onInplaceDeactivate();
    // console.log(this.newTag, this.tagColor);
    // const body: ICreateTagDTO = {
    //   label: this.newTag,
    //   color: this.tagColor.replace('#', ''),
    //   dataSourceId: this.sourceId,
    // };

    // this.tagsService.createTag$(this.botId, body).subscribe({
    //   next: () => {
    //     this.newTag = '';
    //     this.tagColor = '';
    //     this.onTagChanged.emit(true);
    //   },
    // });
  }

  onInplaceActivate() {
    this.inplaceActive = true;
    setTimeout(() => {
      this.tagAdd?.nativeElement.focus();
    }, 100);
  }

  onInplaceDeactivate() {
    this.inplaceActive = false;
  }
}
