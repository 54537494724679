import { IAvailableCredits, IAvailableSubscription } from '../models/subscription.model';

export const SUBSCRIPTIONS: IAvailableSubscription[] = [
  // {
  //   planId: 'P-FREETIER',
  //   planName: 'Free',
  //   planType: 'PERSONAL',
  //   monthlyCostAnnual: 0,
  //   monthlyCostMonth: 0,
  //   monthlyCredits: '10',
  //   isMonthlyCreditsDaily: true,
  //   users: 1,
  //   createdAssistants: 5,
  //   apiKeys: 0,
  //   kbTraffic: '250.000',
  // },
  {
    planId: 'P-XPLORER',
    planName: 'Explorer',
    planType: 'PERSONAL',
    subscriptionId: {
      staging: {
        annual: 'mMLHa',
        monthly: 'E5mOq',
      },
      production: {
        annual: 'xUvuQ',
        monthly: '8jBSD',
      },
    },
    monthlyCostAnnual: 9.99,
    monthlyCostMonth: 12,
    monthlyCredits: '1.000',
    users: 1,
    createdAssistants: 2,
    apiKeys: 0,
    kbTraffic: '10.000.000',
    isBuying: false,
  },
  {
    planId: 'P-PERSONAL',
    planName: 'Personal',
    planType: 'PERSONAL',
    subscriptionId: {
      staging: {
        annual: '27VJe',
        monthly: 'VSOwy',
      },
      production: {
        annual: 'FstNg',
        monthly: 'YvST3',
      },
    },
    bestSeller: true,
    monthlyCostAnnual: 19.99,
    monthlyCostMonth: 24,
    monthlyCredits: '2.100',
    users: 1,
    createdAssistants: 15,
    apiKeys: 0,
    kbTraffic: '20.000.000',
    isBuying: false,
  },
  {
    planId: 'P-FAMILY',
    planName: 'Family',
    planType: 'PERSONAL',
    subscriptionId: {
      staging: {
        annual: 'ZnWXE',
        monthly: 'qxg9Z',
      },
      production: {
        annual: 'XrGLe',
        monthly: 'vwi2s',
      },
    },
    monthlyCostAnnual: 49.99,
    monthlyCostMonth: 60,
    monthlyCredits: '5.200',
    users: 6,
    createdAssistants: 30,
    apiKeys: 5,
    kbTraffic: '50.000.000',
    isBuying: false,
  },
  {
    planId: 'P-UNLIMITED',
    planName: 'Unlimited',
    planType: 'PERSONAL',
    subscriptionId: {
      staging: {
        annual: 'kIswF',
        monthly: 'ikAHS',
      },
      production: {
        annual: 'Hs3Fr',
        monthly: 'ZaWXE',
      },
    },
    monthlyCostAnnual: 99.99,
    monthlyCostMonth: 120,
    monthlyCredits: '11.000',
    users: 20,
    createdAssistants: 100,
    apiKeys: 100,
    kbTraffic: '100.000.000',
    isBuying: false,
  },
  // ------------------------------- //
  // {
  //   planId: 'P-FREETIER',
  //   planName: 'Free',
  //   planType: 'BUSINESS',
  //   monthlyCostAnnual: 0,
  //   monthlyCostMonth: 0,
  //   monthlyCredits: '10',
  //   isMonthlyCreditsDaily: true,
  //   users: 1,
  //   createdAssistants: 5,
  //   apiKeys: 0,
  //   kbTraffic: '250.000',
  //   bringYourOwnModel: false,
  // },
  {
    planId: 'B-STARTER',
    planName: 'Business Starter',
    planType: 'BUSINESS',
    subscriptionId: {
      staging: {
        annual: '',
        monthly: '',
      },
      production: {
        annual: 'ShZD7',
        monthly: 'hn9QL',
      },
    },
    monthlyCostAnnual: 49.99,
    monthlyCostMonth: 60,
    monthlyCredits: '5.100',
    users: 6,
    createdAssistants: 30,
    apiKeys: 5,
    kbTraffic: '50.000.000',
    bringYourOwnModel: false,
    isBuying: false,
  },
  {
    planId: 'B-PRO',
    planName: 'Business Pro',
    planType: 'BUSINESS',
    subscriptionId: {
      staging: {
        annual: '',
        monthly: '',
      },
      production: {
        annual: 'gsJHv',
        monthly: '96xfl',
      },
    },
    monthlyCostAnnual: 149.99,
    monthlyCostMonth: 180,
    monthlyCredits: '16.000',
    users: 150,
    createdAssistants: 150,
    apiKeys: 150,
    kbTraffic: '150.000.000',
    bringYourOwnModel: false,
    isBuying: false,
  },
  {
    planId: 'B-CORPORATE',
    planName: 'Business Corporate',
    planType: 'BUSINESS',
    subscriptionId: {
      staging: {
        annual: '',
        monthly: '',
      },
      production: {
        annual: 'bkq1L',
        monthly: 'e4oWt',
      },
    },
    monthlyCostAnnual: 299.99,
    monthlyCostMonth: 360,
    monthlyCredits: '33.000',
    users: 300,
    createdAssistants: 400,
    apiKeys: 500,
    kbTraffic: '300.000.000',
    bringYourOwnModel: false,
    isBuying: false,
  },
  {
    planId: 'B-CUSTOM',
    planName: 'Custom',
    planType: 'BUSINESS',
    subscriptionId: {
      staging: {
        annual: '',
        monthly: '',
      },
      production: {
        annual: '',
        monthly: '',
      },
    },
    monthlyCostAnnual: 'sales',
    monthlyCostMonth: 'sales',
    monthlyCredits: 'unlimited.plural.m',
    users: 'unlimited.plural.m',
    createdAssistants: 'unlimited.plural.m',
    apiKeys: 'unlimited.plural.f',
    kbTraffic: 'unlimited.singular',
    bringYourOwnModel: true,
    isBuying: false,
  },
];

export const CREDITS: IAvailableCredits[] = [
  {
    planId: 'C-10',
    creditsNumber: 1050,
    price: 10,
    subscriptionId: {
      staging: '4gXxa',
      production: '',
    },
  },
  {
    planId: 'C-25',
    creditsNumber: 2625,
    price: 25,
    subscriptionId: {
      staging: 'fL4Vv',
      production: '',
    },
  },
  {
    planId: 'C-50',
    creditsNumber: 5250,
    price: 50,
    subscriptionId: {
      staging: '7mKGm',
      production: '',
    },
  },
  {
    planId: 'C-100',
    creditsNumber: 10500,
    price: 100,
    subscriptionId: {
      staging: 'fe4Vv',
      production: '',
    },
  },
  {
    planId: 'C-500',
    creditsNumber: 52500,
    price: 500,
    subscriptionId: {
      staging: 'uk86E',
      production: '',
    },
  },
];
