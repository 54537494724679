import { DateTime } from 'luxon';

export type AssistantUsePermission = 'USE' | 'EDIT';

export interface ICreateAssistantUseDTO {
  archived: boolean;
  chatGroupId?: string;
  marketplaceId?: string;
  permission: AssistantUsePermission;
}

export interface IAssistantUse {
  id: string;
  chatBotId: string;
  chatGroupId: string | null;
  userId: string;
  firstName: string;
  lastName: string;
  isCreator: boolean;
  marketplaceId: string | null;
  lastUseTimestamp: string | null;
  permission: AssistantUsePermission;
}

export class AssistantUse {
  id: string = '';
  chatBotId: string = '';
  chatGroupId: string | null = null;
  userId: string = '';
  firstName: string = '';
  lastName: string = '';
  isCreator: boolean = false;
  marketplaceId: string | null = null;
  lastUseTimestamp?: DateTime;
  permission?: AssistantUsePermission;

  constructor(res?: IAssistantUse) {
    if (res) {
      this.id = res.id;
      this.chatBotId = res.chatBotId;
      this.chatGroupId = res.chatGroupId;
      this.userId = res.userId;
      this.firstName = res.firstName;
      this.lastName = res.lastName;
      this.isCreator = res.isCreator;
      this.marketplaceId = res.marketplaceId;
      if (res.lastUseTimestamp) this.lastUseTimestamp = DateTime.fromISO(res.lastUseTimestamp);
      this.permission = res.permission;
    }
  }

  updatePermission(value: AssistantUsePermission) {
    this.permission = value;
  }
}
