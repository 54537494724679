import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize, tap } from 'rxjs';
import { DataSourcesService } from 'src/app/main/services/data-sources.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { CRAWLING_METHODS } from 'src/app/main/shared/constants/crawling-methods.consts';
import { NOT_URL_REGEXP, URL_REGEXP } from 'src/app/main/shared/constants/data-sources.consts';
import { ICrawlingMethod } from 'src/app/main/shared/models/add-knowledge-base.model';
import { IAddWebsiteDTO, IURLDryrunResults } from 'src/app/main/shared/models/chat-bot-source.model';
import { CrawlingMethod } from 'src/app/main/shared/types/crawling-method.type';

@Component({
  selector: 'app-add-url-tab',
  templateUrl: './add-url-tab.component.html',
  styleUrls: ['./add-url-tab.component.scss'],
})
export class AddUrlTabComponent {
  idBot: string = '';
  readonly regUrl = URL_REGEXP;
  readonly notUrlReg = NOT_URL_REGEXP;

  readonly crawlingMethods = CRAWLING_METHODS;

  websiteForm: FormGroup = new FormGroup({});

  addLoading: boolean = false;
  testLoading: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly ref: DynamicDialogRef,
    private readonly toastService: ToastService,
    private readonly config: DynamicDialogConfig,
    private readonly dataSourceService: DataSourcesService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.idBot = this.config.data.id;

    this.websiteForm = this.fb.group({
      crawlingMethod: new FormControl<ICrawlingMethod>(this.crawlingMethods[0], [Validators.required]),
      description: new FormControl(''),
      url: new FormControl('', [Validators.required, Validators.pattern(this.regUrl)]),
      sitemap: new FormControl('sitemap.xml', [Validators.pattern(this.notUrlReg)]),
    });

    this.subscribeToCrawlingMethodUpdate();
  }

  private subscribeToCrawlingMethodUpdate() {
    this.websiteForm
      .get('crawlingMethod')
      ?.valueChanges.pipe(
        tap((v: ICrawlingMethod) => {
          if (v.value === 'SITEMAP_CRAWL') {
            this.websiteForm.controls['sitemap'].setValidators(Validators.required);
          } else {
            this.websiteForm.controls['sitemap'].removeValidators(Validators.required);
          }
          this.websiteForm.controls['sitemap'].updateValueAndValidity();
        })
      )
      .subscribe();
  }

  addWebsite(dryrun: boolean = false) {
    dryrun ? (this.testLoading = true) : (this.addLoading = true);
    const body = this.buildBody();
    this.dataSourceService
      .createUrl(this.idBot, body, dryrun)
      .pipe(
        finalize(() => {
          dryrun ? (this.testLoading = false) : (this.addLoading = false);
        })
      )
      .subscribe({
        next: (r: IURLDryrunResults) => {
          if (dryrun && r.sitemapValidity) {
            this.toastService.addSuccess({
              summary: this.mainLanguageService.instant('common.success'),
              detail: this.mainLanguageService.instant('private.knowledgePage.addSourceModal.url.testSuccessful'),
            });
          } else if (dryrun && !r.sitemapValidity) {
            this.toastService.addSuccess({
              summary: this.mainLanguageService.instant('common.warning'),
              detail: this.mainLanguageService.instant('private.knowledgePage.addSourceModal.url.testUnsuccessful'),
            });
          } else {
            this.ref.close({ added: true, error: undefined });
          }
        },
        error: ({ error }) => {
          this.toastService.addError({
            summary: this.mainLanguageService.instant('common.error'),
            detail: error.message,
          });
        },
      });
  }

  private buildBody() {
    const rawValue = this.websiteForm.getRawValue();
    const body: IAddWebsiteDTO = {
      crawlingMethod: rawValue.crawlingMethod.value,
      description: `${this.getDescriptionPrefix(rawValue.crawlingMethod.value)}: ${rawValue.url}`,
      data: this.appendLastSlash(rawValue.crawlingMethod.value, rawValue.url),
    };
    if (rawValue.sitemap && this.getIsSitemapCrawling()) {
      body.sitemap = rawValue.sitemap;
    }
    return body;
  }

  private appendLastSlash(crawlingMethod: CrawlingMethod, url: string) {
    if (crawlingMethod === 'SITEMAP_CRAWL') return url.endsWith('/') ? url : `${url}/`;
    else return url;
  }

  private getDescriptionPrefix(method: CrawlingMethod) {
    switch (method) {
      case 'SINGLE_CRAWL':
        return 'URL per la pagina singola \n';
      case 'SITEMAP_CRAWL':
        return 'URL per il crawling da Sitemap \n';
      case 'FULL_CRAWL':
        return 'URL per il crawling da Autodiscovery \n';
    }
  }

  getCrawlingMethodDescription() {
    return (this.websiteForm.get('crawlingMethod')?.value as ICrawlingMethod).description;
  }

  getUrl() {
    return this.websiteForm.get('url')?.value as string;
  }

  getIsSitemapCrawling() {
    return (this.websiteForm.get('crawlingMethod')?.value as ICrawlingMethod).value === 'SITEMAP_CRAWL';
  }
}
