import { Pipe, PipeTransform } from '@angular/core';
import { TrainingStatus, TrainingStatusBadgeColor, TrainingStatusLabel } from '../enums/training-status.enum';

export interface ITrainingStatusChip {
  label: string;
  severity: 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast';
}

@Pipe({
  name: 'trainingStatus',
})
export class TrainingStatusPipe implements PipeTransform {
  transform(value: TrainingStatus | null): ITrainingStatusChip {
    if (value) {
      return {
        label: TrainingStatusLabel[value],
        severity: TrainingStatusBadgeColor[value],
      };
    } else {
      return {
        label: 'NO STATUS',
        severity: TrainingStatusBadgeColor.NULL,
      };
    }
  }
}
