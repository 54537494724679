import * as i0 from '@angular/core';
import { Pipe, EventEmitter, forwardRef, Component, HostBinding, Input, Output, ViewChild, HostListener, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/material/chips';
import { MatChipsModule } from '@angular/material/chips';
import * as i3 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import * as i4 from '@angular/material/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
const _c0 = ["fileInputEl"];
function NgxFileDragDropComponent_mat_chip_listbox_0_mat_chip_1_mat_icon_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon", 9);
    i0.ɵɵtext(1, "cancel");
    i0.ɵɵelementEnd();
  }
}
function NgxFileDragDropComponent_mat_chip_listbox_0_mat_chip_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "mat-chip", 6);
    i0.ɵɵpipe(1, "byte");
    i0.ɵɵlistener("removed", function NgxFileDragDropComponent_mat_chip_listbox_0_mat_chip_1_Template_mat_chip_removed_0_listener() {
      const file_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.removeFile(file_r2));
    });
    i0.ɵɵelementStart(2, "span", 7);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, NgxFileDragDropComponent_mat_chip_listbox_0_mat_chip_1_mat_icon_4_Template, 2, 0, "mat-icon", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const file_r2 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵpropertyInterpolate("matTooltip", i0.ɵɵpipeBind1(1, 6, file_r2.size));
    i0.ɵɵproperty("matTooltipDisabled", ctx_r2.displayFileSize)("disabled", ctx_r2.disabled)("removable", !ctx_r2.disabled);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r2.getFileName(file_r2));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.disabled);
  }
}
function NgxFileDragDropComponent_mat_chip_listbox_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-chip-listbox", 4);
    i0.ɵɵtemplate(1, NgxFileDragDropComponent_mat_chip_listbox_0_mat_chip_1_Template, 5, 8, "mat-chip", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.files);
  }
}
function NgxFileDragDropComponent_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r2.emptyPlaceholder);
  }
}
class BytePipe {
  unit = 'Bytes';
  transform(value, decimals) {
    value = value.toString();
    if (parseInt(value, 10) >= 0) {
      value = this.formatBytes(+value, +decimals);
    }
    return value;
  }
  // https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  static ɵfac = function BytePipe_Factory(t) {
    return new (t || BytePipe)();
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "byte",
    type: BytePipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BytePipe, [{
    type: Pipe,
    args: [{
      name: 'byte'
    }]
  }], null, null);
})();
class NgxFileDragDropComponent {
  constructor() {}
  get disabled() {
    return this._disabled;
  }
  set disabled(val) {
    this._disabled = coerceBooleanProperty(val);
  }
  set multiple(value) {
    this._multiple = coerceBooleanProperty(value);
  }
  get multiple() {
    return this._multiple;
  }
  set displayFileSize(value) {
    this._displayFileSize = coerceBooleanProperty(value);
  }
  get displayFileSize() {
    return this._displayFileSize;
  }
  set borderColor(color) {
    this._activeBorderColor = color;
  }
  get borderColor() {
    return this.isDragover ? this._activeBorderColor : '#ccc';
  }
  get files() {
    return this._files;
  }
  get isEmpty() {
    return !this.files?.length;
  }
  // @HostBinding('class.drag-over')
  get isDragover() {
    return this._isDragOver;
  }
  set isDragover(value) {
    if (!this.disabled) {
      this._isDragOver = value;
    }
  }
  valueChanged = new EventEmitter();
  fileInputEl;
  // does no validation, just sets the hidden file input
  accept = '*';
  _disabled = false;
  _multiple = false;
  emptyPlaceholder = `Drop file${this.multiple ? 's' : ''} or click to select`;
  _displayFileSize = false;
  _activeBorderColor = 'purple';
  _files = [];
  _isDragOver = false;
  // https://angular.io/api/forms/ControlValueAccessor
  _onChange = val => {};
  _onTouched = () => {};
  writeValue(files) {
    const fileArray = this.convertToArray(files);
    if (fileArray.length < 2 || this.multiple) {
      this._files = fileArray;
      this.emitChanges(this._files);
    } else {
      throw Error('Multiple files not allowed');
    }
  }
  registerOnChange(fn) {
    this._onChange = fn;
  }
  registerOnTouched(fn) {
    this._onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  emitChanges(files) {
    this.valueChanged.emit(files);
    this._onChange(files);
  }
  addFiles(files) {
    // this._onTouched();
    const fileArray = this.convertToArray(files);
    if (this.multiple) {
      // this.errorOnEqualFilenames(fileArray);
      const merged = this.files.concat(fileArray);
      this.writeValue(merged);
    } else {
      this.writeValue(fileArray);
    }
  }
  removeFile(file) {
    const fileIndex = this.files.indexOf(file);
    if (fileIndex >= 0) {
      const currentFiles = this.files.slice();
      currentFiles.splice(fileIndex, 1);
      this.writeValue(currentFiles);
    }
  }
  clear() {
    this.writeValue([]);
  }
  change(event) {
    event.stopPropagation();
    this._onTouched();
    const fileList = event.target.files;
    if (fileList?.length) {
      this.addFiles(fileList);
    }
    // clear it so change is triggered if same file is selected again
    event.target.value = '';
  }
  activate(e) {
    e.preventDefault();
    this.isDragover = true;
  }
  deactivate(e) {
    e.preventDefault();
    this.isDragover = false;
  }
  handleDrop(e) {
    this.deactivate(e);
    if (!this.disabled) {
      const fileList = e.dataTransfer.files;
      this.removeDirectories(fileList).then(files => {
        if (files?.length) {
          this.addFiles(files);
        }
        this._onTouched();
      });
    }
  }
  open() {
    if (!this.disabled) {
      this.fileInputEl?.nativeElement.click();
    }
  }
  // @HostListener('focusout')
  // blur() {
  //   console.log('blurred')
  //   this._onTouched();
  // }
  // private errorOnEqualFilenames(files: File[]) {
  //   if (this.files.some(file => files.some(file2 => file.name === file2.name))) {
  //     throw Error('one of the provided filenames already exists')
  //   }
  //   for (let i = 0; i < files.length; i++) {
  //     for (let j = i + 1; j < files.length; j++) {
  //       if (files[i].name === files[j].name) {
  //         throw Error(`can't add multiple files with same name`)
  //       }
  //     }
  //   }
  // }
  removeDirectories(files) {
    return new Promise((resolve, reject) => {
      const fileArray = this.convertToArray(files);
      const dirnames = [];
      const readerList = [];
      for (let i = 0; i < fileArray.length; i++) {
        const reader = new FileReader();
        reader.onerror = () => {
          dirnames.push(fileArray[i].name);
        };
        reader.onloadend = () => addToReaderList(i);
        reader.readAsArrayBuffer(fileArray[i]);
      }
      function addToReaderList(val) {
        readerList.push(val);
        if (readerList.length === fileArray.length) {
          resolve(fileArray.filter(file => !dirnames.includes(file.name)));
        }
      }
    });
  }
  convertToArray(files) {
    if (files) {
      if (files instanceof File) {
        return [files];
      } else if (Array.isArray(files)) {
        return files;
      } else {
        return Array.prototype.slice.call(files);
      }
    }
    return [];
  }
  getFileName(file) {
    if (!this._displayFileSize) {
      return file.name;
    }
    const size = new BytePipe().transform(file.size);
    return `${file.name} (${size})`;
  }
  static ɵfac = function NgxFileDragDropComponent_Factory(t) {
    return new (t || NgxFileDragDropComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: NgxFileDragDropComponent,
    selectors: [["ngx-file-drag-drop"]],
    viewQuery: function NgxFileDragDropComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileInputEl = _t.first);
      }
    },
    hostVars: 6,
    hostBindings: function NgxFileDragDropComponent_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("change", function NgxFileDragDropComponent_change_HostBindingHandler($event) {
          return ctx.change($event);
        })("dragenter", function NgxFileDragDropComponent_dragenter_HostBindingHandler($event) {
          return ctx.activate($event);
        })("dragover", function NgxFileDragDropComponent_dragover_HostBindingHandler($event) {
          return ctx.activate($event);
        })("dragleave", function NgxFileDragDropComponent_dragleave_HostBindingHandler($event) {
          return ctx.deactivate($event);
        })("drop", function NgxFileDragDropComponent_drop_HostBindingHandler($event) {
          return ctx.handleDrop($event);
        })("click", function NgxFileDragDropComponent_click_HostBindingHandler() {
          return ctx.open();
        });
      }
      if (rf & 2) {
        i0.ɵɵstyleProp("border-color", ctx.borderColor);
        i0.ɵɵclassProp("disabled", ctx.disabled)("empty-input", ctx.isEmpty);
      }
    },
    inputs: {
      disabled: "disabled",
      multiple: "multiple",
      displayFileSize: "displayFileSize",
      borderColor: [i0.ɵɵInputFlags.None, "activeBorderColor", "borderColor"],
      accept: "accept",
      emptyPlaceholder: "emptyPlaceholder"
    },
    outputs: {
      valueChanged: "valueChanged"
    },
    features: [i0.ɵɵProvidersFeature([{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NgxFileDragDropComponent),
      multi: true
    }])],
    decls: 5,
    vars: 4,
    consts: [["fileInputEl", "", "fileInput", ""], ["selectable", "false", 4, "ngIf"], ["class", "placeholder", 4, "ngIf"], ["type", "file", 1, "hidden"], ["selectable", "false"], ["matTooltipPosition", "below", "selected", "", "highlighted", "", "color", "accent", "disableRipple", "true", 3, "matTooltip", "matTooltipDisabled", "disabled", "removable", "removed", 4, "ngFor", "ngForOf"], ["matTooltipPosition", "below", "selected", "", "highlighted", "", "color", "accent", "disableRipple", "true", 3, "removed", "matTooltip", "matTooltipDisabled", "disabled", "removable"], [1, "filename"], ["matChipRemove", "", 4, "ngIf"], ["matChipRemove", ""], [1, "placeholder"]],
    template: function NgxFileDragDropComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, NgxFileDragDropComponent_mat_chip_listbox_0_Template, 2, 1, "mat-chip-listbox", 1)(1, NgxFileDragDropComponent_span_1_Template, 2, 1, "span", 2);
        i0.ɵɵelement(2, "input", 3, 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.files.length);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.files.length);
        i0.ɵɵadvance();
        i0.ɵɵattribute("multiple", ctx.multiple ? "" : null)("accept", ctx.accept);
      }
    },
    dependencies: [i1.NgForOf, i1.NgIf, i2.MatChip, i2.MatChipListbox, i2.MatChipRemove, i3.MatIcon, i4.MatTooltip, BytePipe],
    styles: ["input[_ngcontent-%COMP%]{width:0px;height:0px;opacity:0;overflow:hidden;position:absolute;z-index:-1}[_nghost-%COMP%]{display:block;border:2px dashed;border-radius:20px;min-height:50px;margin:10px auto;max-width:500px;padding:20px;cursor:pointer}.disabled[_nghost-%COMP%]{opacity:.5;cursor:unset}.placeholder[_ngcontent-%COMP%]{color:gray;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}mat-chip[_ngcontent-%COMP%]{max-width:100%}.filename[_ngcontent-%COMP%]{max-width:calc(100% - 1em);white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.empty-input[_nghost-%COMP%]{display:flex;align-items:center;justify-content:center}.mat-mdc-chip.mat-mdc-standard-chip.mat-focus-indicator[_ngcontent-%COMP%]{box-shadow:none}.mat-mdc-chip.mat-mdc-standard-chip[_ngcontent-%COMP%]:after{background:unset}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxFileDragDropComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-file-drag-drop',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NgxFileDragDropComponent),
        multi: true
      }],
      template: "<mat-chip-listbox *ngIf=\"files.length\" selectable=\"false\">\r\n    <mat-chip matTooltip={{file.size|byte}} matTooltipPosition=\"below\" [matTooltipDisabled]=\"displayFileSize\" selected highlighted\r\n        *ngFor=\"let file of files\" [disabled]=\"disabled\" color=\"accent\" disableRipple=\"true\" [removable]=\"!disabled\"\r\n        (removed)=\"removeFile(file)\">\r\n        <span class=\"filename\">{{getFileName(file)}}</span>\r\n        <mat-icon *ngIf=\"!disabled\" matChipRemove>cancel</mat-icon>\r\n    </mat-chip>\r\n</mat-chip-listbox>\r\n<span class=\"placeholder\" *ngIf=\"!files.length\">{{emptyPlaceholder}}</span>\r\n<input #fileInputEl class=\"hidden\" #fileInput type=\"file\" [attr.multiple]=\"multiple? '' : null\" [attr.accept]=\"accept\">",
      styles: ["input{width:0px;height:0px;opacity:0;overflow:hidden;position:absolute;z-index:-1}:host{display:block;border:2px dashed;border-radius:20px;min-height:50px;margin:10px auto;max-width:500px;padding:20px;cursor:pointer}:host.disabled{opacity:.5;cursor:unset}.placeholder{color:gray;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}mat-chip{max-width:100%}.filename{max-width:calc(100% - 1em);white-space:nowrap;overflow:hidden;text-overflow:ellipsis}:host.empty-input{display:flex;align-items:center;justify-content:center}.mat-mdc-chip.mat-mdc-standard-chip.mat-focus-indicator{box-shadow:none}.mat-mdc-chip.mat-mdc-standard-chip:after{background:unset}\n"]
    }]
  }], () => [], {
    disabled: [{
      type: HostBinding,
      args: ['class.disabled']
    }, {
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    displayFileSize: [{
      type: Input
    }],
    borderColor: [{
      type: Input,
      args: ['activeBorderColor']
    }, {
      type: HostBinding,
      args: ['style.border-color']
    }],
    isEmpty: [{
      type: HostBinding,
      args: ['class.empty-input']
    }],
    valueChanged: [{
      type: Output
    }],
    fileInputEl: [{
      type: ViewChild,
      args: ['fileInputEl']
    }],
    accept: [{
      type: Input
    }],
    emptyPlaceholder: [{
      type: Input
    }],
    change: [{
      type: HostListener,
      args: ['change', ['$event']]
    }],
    activate: [{
      type: HostListener,
      args: ['dragenter', ['$event']]
    }, {
      type: HostListener,
      args: ['dragover', ['$event']]
    }],
    deactivate: [{
      type: HostListener,
      args: ['dragleave', ['$event']]
    }],
    handleDrop: [{
      type: HostListener,
      args: ['drop', ['$event']]
    }],
    open: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class NgxFileDragDropModule {
  static ɵfac = function NgxFileDragDropModule_Factory(t) {
    return new (t || NgxFileDragDropModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxFileDragDropModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, MatChipsModule, MatIconModule, MatTooltipModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxFileDragDropModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxFileDragDropComponent, BytePipe],
      imports: [CommonModule, MatChipsModule, MatIconModule, MatTooltipModule],
      exports: [NgxFileDragDropComponent, BytePipe]
    }]
  }], null, null);
})();

// @dynamic
class FileValidators {
  static fileExtension(ext) {
    return control => {
      const validExtensions = ext.map(e => e.trim().toLowerCase());
      const fileArray = control.value;
      const invalidFiles = fileArray.map(file => file.name).filter(fname => {
        const extension = fname.slice((fname.lastIndexOf('.') - 1 >>> 0) + 2).toLowerCase();
        return !validExtensions.includes(extension);
      }).map(name => ({
        name,
        ext: name.slice((name.lastIndexOf('.') - 1 >>> 0) + 2)
      }));
      return !invalidFiles.length ? null : {
        fileExtension: {
          requiredExtension: ext.toString(),
          actualExtensions: invalidFiles
        }
      };
    };
  }
  static uniqueFileNames(control) {
    const fileNameArray = control.value.map(file => file.name);
    const duplicates = fileNameArray.reduce((acc, curr) => {
      acc[curr] = acc[curr] ? acc[curr] + 1 : 1;
      return acc;
    }, {});
    const duplicatesArray = Object.entries(duplicates).filter(arr => arr[1] > 1).map(arr => ({
      name: arr[0],
      count: arr[1]
    }));
    return !duplicatesArray.length ? null : {
      uniqueFileNames: {
        duplicatedFileNames: duplicatesArray
      }
    };
  }
  static fileType(types) {
    return control => {
      let regExp;
      if (Array.isArray(types)) {
        const joinedTypes = types.join('$|^');
        regExp = new RegExp(`$${joinedTypes}^`, 'i');
      } else {
        regExp = types;
      }
      const fileArray = control.value;
      const invalidFiles = fileArray.filter(file => !regExp.test(file.type)).map(file => ({
        name: file.name,
        type: file.type
      }));
      return !invalidFiles.length ? null : {
        fileType: {
          requiredType: types.toString(),
          actualTypes: invalidFiles
        }
      };
    };
  }
  static maxFileCount(count) {
    return control => {
      const fileCount = control?.value ? control.value.length : 0;
      const result = count >= fileCount;
      return result ? null : {
        maxFileCount: {
          maxCount: count,
          actualCount: fileCount
        }
      };
    };
  }
  static maxFileSize(bytes) {
    return control => {
      const fileArray = control.value;
      const invalidFiles = fileArray.filter(file => file.size > bytes).map(file => ({
        name: file.name,
        size: file.size
      }));
      return !invalidFiles.length ? null : {
        maxFileSize: {
          maxSize: bytes,
          actualSizes: invalidFiles
        }
      };
    };
  }
  static maxTotalSize(bytes) {
    return control => {
      const size = control?.value ? control.value.map(file => file.size).reduce((acc, i) => acc + i, 0) : 0;
      const result = bytes >= size;
      return result ? null : {
        maxTotalSize: {
          maxSize: bytes,
          actualSize: size
        }
      };
    };
  }
  static required(control) {
    const count = control?.value?.length;
    return count ? null : {
      required: true
    };
  }
}

/*
 * Public API Surface of ngx-file-drag-drop
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BytePipe, FileValidators, NgxFileDragDropComponent, NgxFileDragDropModule };
