import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Share } from '../../models/share.model';
import { BotsService } from 'src/app/main/services/bots.service';
import { map, tap } from 'rxjs';
import { ChatBot } from '../../models/chat-bot.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-continue-chat-modal',
  templateUrl: './continue-chat-modal.component.html',
  styleUrl: './continue-chat-modal.component.scss',
})
export class ContinueChatModalComponent {
  authenticated: boolean;
  sharedConversation: Share;
  bots: ChatBot[] = [];

  selectedBot: string = '';

  constructor(
    private readonly router: Router,
    private readonly ref: DynamicDialogRef,
    private readonly botsService: BotsService,
    private readonly config: DynamicDialogConfig
  ) {
    this.authenticated = this.config.data.authenticated;
    this.sharedConversation = this.config.data.share;

    if (this.authenticated) {
      this.botsService
        .getAssistants(false)
        .pipe(
          map((chatBots) => {
            const foundBot = chatBots.splice(
              chatBots.findIndex((b) => b.id === this.sharedConversation.assistant!.id),
              1
            );
            return [...foundBot, ...chatBots];
          })
        )
        .subscribe({
          next: (chatBots) => (this.bots = chatBots),
        });
    }
  }

  closeModal() {
    this.ref.close();
  }

  goToShared() {
    this.router
      .navigate(['/', 'private', this.selectedBot, 'chat', 'new'], {
        queryParams: {
          fromShare: this.sharedConversation.id,
        },
      })
      .then(() => this.ref.close());
  }
}
