import { Component } from '@angular/core';

@Component({
  selector: 'app-utilizzo',
  templateUrl: './utilizzo.component.html',
  styleUrls: ['./utilizzo.component.scss']
})
export class UtilizzoComponent {

}
