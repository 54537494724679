<span class="h-full flex flex-column justify-content-between">
  <span>
    <h3>In che tipo di linguaggio l’assistente Deve parlare ai/agli {{ creationWizard.targetAudience }}</h3>
    <textarea
      pInputTextarea
      class="w-full"
      [(ngModel)]="creationWizard.targetLanguage"
      placeholder="Testo libero, es:
      - Tecnico-giuridico 
      - Semplice ma dettagliato
      - Tecnico
      - Non troppo tecnico
      ecc...
      "
      [autoResize]="true"
    ></textarea>
  </span>
  <div class="flex justify-content-end gap-3">
    <button pButton [disabled]="!creationWizard.targetLanguage" label="Next" (click)="setTargetLanguage()"></button>
  </div>
</span>
