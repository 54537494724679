import { AfterViewInit, Directive, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { GalleryService } from '../../services/gallery.service';

@Directive({
  selector: '[galleryLayout]',
})
export class GalleryLayoutDirective implements AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private imagesLoaded = 0;
  private totalImages = 0;
  private imageGeneratedSubscription = this.galleryService.imagesGenerated$.subscribe(() => {
    this.initializeGallery();
    setTimeout(() => this.initializeGallery(), 500);
    setTimeout(() => this.initializeGallery(), 1000);
  });
  constructor(private el: ElementRef, private zone: NgZone, private galleryService: GalleryService) {}

  ngAfterViewInit() {
    this.initializeGallery();
    this.galleryService.imagesGenerated$.subscribe(() => {
      this.initializeGallery();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.imageGeneratedSubscription.unsubscribe();
  }

  private initializeGallery() {
    this.setupImageLoadListeners();
    this.setupResizeListener();
    // Esegui organizeGallery immediatamente e di nuovo dopo un breve ritardo
    this.organizeGallery();
    //Workaround for the layout to be applied after the images are loaded
    const interval = setInterval(() => this.organizeGallery(), 500);
    setTimeout(() => clearInterval(interval), 20000);
  }

  private setupImageLoadListeners() {
    const galleryItems = this.el.nativeElement.querySelectorAll('.gallery-item');
    this.totalImages = galleryItems.length;
    this.imagesLoaded = 0;

    galleryItems.forEach((item: HTMLElement) => {
      const img = item.querySelector('img');
      if (img) {
        if (img.complete) {
          this.onImageLoad();
        } else {
          img.addEventListener('load', () => this.onImageLoad());
          img.addEventListener('error', () => this.onImageLoad());
        }
      } else {
        this.onImageLoad();
      }
    });
  }

  private onImageLoad() {
    this.imagesLoaded++;
    if (this.imagesLoaded === this.totalImages) {
      this.organizeGallery();
    }
  }

  private setupResizeListener() {
    fromEvent(window, 'resize')
      .pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe(() => {
        this.organizeGallery();
      });
  }

  private organizeGallery() {
    this.zone.runOutsideAngular(() => {
      const gallery = this.el.nativeElement;
      const items = Array.from(gallery.querySelectorAll('.gallery-item'));
      const width = gallery.clientWidth;

      let columns = 1;
      // if (width >= 1400) columns = 6;
      if (width >= 1470) columns = 5;
      else if (width >= 1160) columns = 4;
      else if (width >= 890) columns = 3;
      else if (width >= 576) columns = 2;

      gallery.style.display = 'grid';
      gallery.style.gridTemplateColumns = `repeat(${columns}, 1fr)`;
      gallery.style.gap = '10px';

      items.forEach((item: any) => {
        const img = item.querySelector('img');
        if (img) {
          const aspectRatio = img.naturalHeight / img.naturalWidth;
          const rowSpan = Math.ceil(aspectRatio * 10);
          item.style.gridRowEnd = `span ${rowSpan}`;
        }
      });

      // Forza un reflow
      gallery.offsetHeight;
    });
  }
}
