import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';
import { BotsService } from 'src/app/main/services/bots.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { ToastService } from 'src/app/main/services/toast.service';
import { ChatBot } from '../../models/chat-bot.model';

@Component({
  selector: 'app-delete-bot-modal',
  templateUrl: './delete-bot-modal.component.html',
  styleUrls: ['./delete-bot-modal.component.scss'],
})
export class DeleteBotModalComponent {
  bot: ChatBot;
  secureString: string = '';
  loading: boolean = false;

  currentInputString: string = '';

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly botsService: BotsService,
    private readonly toastService: ToastService,
    private readonly config: DynamicDialogConfig,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.bot = this.config.data.bot;
    this.secureString = this.botsService.generateSecureStringForDeletion(this.bot.name);
  }

  deleteBot() {
    this.loading = true;
    this.botsService
      .deleteBot(this.bot.id)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.ref.close({
            deleted: true,
          });
        })
      )
      .subscribe({
        next: () =>
          this.toastService.addSuccess({
            summary: this.mainLanguageService.instant('common.success'),
            detail: this.mainLanguageService.instant('private.configurationPage.advancedPage.deleteModal.botDeleted'),
          }),
        error: ({ error }) =>
          this.toastService.addError({
            summary: this.mainLanguageService.instant('common.error'),
            detail: error.message || this.mainLanguageService.instant('private.configurationPage.advancedPage.deleteModal.unexpectedError'),
          }),
      });
  }
}
