import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { range } from 'lodash';
import { DateTime } from 'luxon';
import { Subject, Subscription, debounceTime, filter, tap } from 'rxjs';
import { ILastAssistantChat } from 'src/app/core/store/bot';
import { BotsService } from 'src/app/main/services/bots.service';
import { ImageUuidService } from 'src/app/main/services/image-uuid.service';
import { infoExpandAnim, latestAssistantAnim } from '../../animations/latest-assistants-sidebar.anim';
import { ChatBot } from '../../models/chat-bot.model';

@Component({
  selector: 'app-latest-assistants-sidebar',
  templateUrl: './latest-assistants-sidebar.component.html',
  styleUrl: './latest-assistants-sidebar.component.scss',
  animations: [latestAssistantAnim, infoExpandAnim],
})
export class LatestAssistantsSidebarComponent implements OnInit, OnDestroy {
  @HostBinding('class.sidebar-expanded')
  get isExpanded() {
    return this.sidebarState === 'expanded';
  }

  readonly BOT_SHOW_LIMIT = 6;
  readonly SKELETONS_ARRAY = range(0, this.BOT_SHOW_LIMIT);

  isLoadingAssistants: boolean = false;
  isSearching: boolean = false;
  hasSearched: boolean = false;
  isNavigating: boolean = false;

  searchTerm: string = '';
  searchSubject: Subject<string> = new Subject<string>();

  botsSubscription?: Subscription;
  assistants: ChatBot[] = [];
  filteredAssistants: ChatBot[] = [];

  currentBotSubscription?: Subscription;
  currentBot?: ChatBot;

  lastChatsSubscription?: Subscription;
  hasStartedChatSubscription?: Subscription;
  lastChats: ILastAssistantChat[] = [];
  hasLoadedBots: Subject<any> = new Subject();

  sidebarState: 'collapsed' | 'expanded' = 'collapsed';

  constructor(
    private readonly botsService: BotsService,
    public readonly imageUuidService: ImageUuidService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    this.searchSubject
      .pipe(
        debounceTime(300),
        tap((searchTerm) => {
          if (searchTerm.trim().length === 0) {
            this.hasSearched = false;
            this.clearSearch();
          }
        }),
        filter((searchTerm) => searchTerm.trim().length > 0),
        tap(() => {
          this.isSearching = true;
          this.isLoadingAssistants = true;
        })
      )
      .subscribe({
        next: (res) => {
          this.isSearching = false;
          this.hasSearched = true;
          this.isLoadingAssistants = false;
          this.filteredAssistants = this.assistants.filter((bot) => bot.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
        },
        error: () => {
          this.isSearching = false;
          this.isLoadingAssistants = false;
          this.hasSearched = true;
        },
      });
  }

  ngOnInit(): void {
    this.getAssistants();

    this.currentBotSubscription = this.botsService.currentBot$.subscribe({
      next: (assistant) => {
        this.currentBot = assistant;
        if (assistant && this.isNavigating) {
          this.isNavigating = false;
          const foundAssistant = this.lastChats.find(
            (a) => a.bot.id === assistant.id && a.bot.name === assistant.name && a.bot.type === assistant.type
          );
          if (foundAssistant) {
            this.router.navigate(
              ['/', 'private', assistant.type === 'ASSISTANT' ? 'o' : '', 'assistants', assistant.id, 'chat', foundAssistant.conversationId].filter(
                (v) => !!v
              ),
              {
                queryParams: {
                  noRefresh: true,
                },
              }
            );
          } else {
            this.router.navigate(
              ['/', 'private', assistant.type === 'ASSISTANT' ? 'o' : '', 'assistants', assistant.id, 'chat', 'new'].filter((v) => !!v),
              {
                queryParams: {
                  noRefresh: true,
                },
              }
            );
          }
        }
      },
    });

    this.lastChatsSubscription = this.botsService.getLastChats$().subscribe({
      next: (last) => (this.lastChats = last),
    });

    this.hasStartedChatSubscription = this.botsService.hasStartedChat$.subscribe({
      next: ({conversationId, assistant}) => {
        console.log('HAS STARTED CHAT', conversationId);
        if (assistant && this.currentBot && assistant.id === this.currentBot.id && assistant.type === this.currentBot.type && assistant.name === this.currentBot.name) {
          this.botsService.setLastChats$({
            bot: {
              id: assistant.id,
              name: assistant.name,
              type: assistant.type!,
            },
            conversationId,
            lastMessageSent: DateTime.now().toISO(),
          });
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.botsSubscription?.unsubscribe();
    this.currentBotSubscription?.unsubscribe();
    this.hasStartedChatSubscription?.unsubscribe();
  }

  onSearchTermChange() {
    this.isSearching = true;
    this.searchSubject.next(this.searchTerm);
  }

  getAssistants() {
    this.isLoadingAssistants = true;
    this.botsSubscription = this.botsService.getAssistants(false).subscribe({
      next: (bots) => {
        const filteredBots = bots.filter((b) => b.type === 'ASSISTANT' || b.type === 'MARKETPLACE');
        this.assistants = filteredBots;
        this.isLoadingAssistants = false;
        this.hasLoadedBots.next(true);
      },
      error: () => (this.isLoadingAssistants = false),
    });
  }

  onSeeAllClick() {
    this.router.navigate(['/', 'private', 'assistants-list']);
  }

  onAssistantClick(assistant: ChatBot) {
    this.isNavigating = true;
    this.botsService.selectBot(assistant.id, assistant.type!, false);
  }

  getIsALastChat(assistant: ChatBot) {
    return !!this.lastChats.filter(
      ({ bot, lastMessageSent }) =>
        assistant.id === bot.id && assistant.name === bot.name && assistant.type === bot.type && this.isDateTimeValid(lastMessageSent)
    ).length;
  }

  private isDateTimeValid(dateTime: string | DateTime | Date) {
    const now = DateTime.now();
    const givenDateTime = this.getDateTime(dateTime);
    const diff = now.diff(givenDateTime);

    return diff < this.botsService.MAX_TIME_FOR_LAST_CHAT;
  }

  private getDateTime(dateTime: string | DateTime | Date) {
    if (typeof dateTime === 'string') {
      return DateTime.fromISO(dateTime);
    } else if (DateTime.isDateTime(dateTime)) {
      return dateTime;
    } else {
      return DateTime.fromJSDate(dateTime);
    }
  }

  toggleExpand() {
    this.sidebarState = this.sidebarState === 'collapsed' ? 'expanded' : 'collapsed';
  }

  getIsActiveAssistant(assistant: ChatBot) {
    return this.currentBot?.id === assistant.id && this.currentBot.name === assistant.name && this.currentBot.type === assistant.type;
  }

  clearSearch() {
    this.searchTerm = '';
    this.filteredAssistants = [];
    this.isSearching = false;
  }
}
