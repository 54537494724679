export enum AspectRatio {
  SQUARE = '1:1',
  LANDSCAPE_3_2 = '3:2',
  LANDSCAPE_4_3 = '4:3',
  WIDESCREEN = '16:9',
  PORTRAIT = '9:16',
}

export const aspectRatioValues: { [key: string]: string } = {
  '1_1': AspectRatio.SQUARE,
  '3_2': AspectRatio.LANDSCAPE_3_2,
  '4_3': AspectRatio.LANDSCAPE_4_3,
  '16_9': AspectRatio.WIDESCREEN,
  '9_16': AspectRatio.PORTRAIT,
};

export enum IdeogramModel {
  V_1_TURBO = 'V_1_TURBO',
  V_2 = 'V_2',
  V_2_TURBO = 'V_2_TURBO',
}

export const ideogramModelValues: { [key: string]: string } = {
  [IdeogramModel.V_1_TURBO]: 'Ideogram (0.2)',
  [IdeogramModel.V_2]: 'Ideogram (1.0)',
  [IdeogramModel.V_2_TURBO]: 'Ideogram (2.0)',
};

export interface IImageGenerationOptions {
  prompt: string;
  vendor: string;
  modelId: string;
  imageStyle: string;
  aspectRatio: string;
  improvePrompt: boolean | null;
  negativePrompt: string;
  imageResolution: {
    width: number;
    height: number;
  };
  dateCreated: Date;
  seed: number;
  resolution: string;
  originalPrompt: string;
  visibility: string;
}

export class ImageGenerationOptions {
  prompt: string;
  vendor: string;
  modelId: string;
  imageStyle: string;
  aspectRatio: string;
  improvePrompt: boolean | null;
  negativePrompt: string;
  imageResolution: {
    width: number;
    height: number;
  };
  originalPrompt: string;
  dateCreated: Date;
  seed: number;
  resolution: string;
  visibility: string;
  constructor(options: Partial<IImageGenerationOptions> = {}) {
    this.prompt = options.prompt || '';
    this.vendor = options.vendor || 'IDEOGRAM';
    this.modelId = options.modelId || 'V_2_TURBO';
    this.imageStyle = options.imageStyle || 'GENERAL';
    this.aspectRatio = options.aspectRatio || '1_1';
    this.improvePrompt = options.improvePrompt || null;
    this.negativePrompt = options.negativePrompt || '';
    this.imageResolution = options.imageResolution || { width: 0, height: 0 };
    this.dateCreated = options.dateCreated || new Date();
    this.originalPrompt = options.originalPrompt || '';
    this.seed = options.seed || 0;
    this.resolution = options.resolution || '';
    this.visibility = options.visibility || 'PUBLIC';
  }
}

/**

{
  "id": "3shF3",
  "path": "https://storage.googleapis.com/pupau-staging/company/ru1f7/images/3shF3-T.webp?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=cloudsql-k8s-custom-account%40glue-api.iam.gserviceaccount.com%2F20240911%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240911T131557Z&X-Goog-Expires=518400&X-Goog-SignedHeaders=host&X-Goog-Signature=c45aae2672674330c4f2f8fb1d34a5f788453cfc6ee76af8500b6c03c072ee4ab66ed1e0db1ef63653ce5cfd3f4630d64960642a745b91a2d2a63674b0ccb67901315c97b697b3f11bb141f36d644ff971a0dd905de7b01f0771fe8887f6c3c40a1e4be598c03b208a5ea166278272da652cb006b3c0389f2f65fb2b1506e434adab1433d0da5b6a99f4b4cf3bb6b0fe314a423d0e7245a79b98a1e0f5c36cb79363d282595b8f11d3b4e8828b8ce255b27823450075d581b8a62ac193e86cae52aeee752b391c29c2ec9a4fb9550aaa2f7e3b96f82891a15d3f2be56acb84d071c8e9e5c74e5a8a793931b757e3f330e7c3fdacd9f62ac610c61a3835cf9ccc",
  "imageUrlFormat": "T",
  "companyId": "ru1f7",
  "visibility": "PUBLIC",
  "imageGroupId": "9ece768105066abef906a006bcd81f94d51b8a2fcd9f038cc8f44af892aa5b8f",
  "user": {
    "id": "Hs3Fr",
    "nick": "Antonio Barbatelli"
  },
  "imageGenerationOptions": {
    "vendor": "IDEOGRAM",
    "created": "2024-09-10T11:56:21.841Z",
    "modelId": "V_2_TURBO",
    "imageStyle": "GENERAL",
    "aspectRatio": "9_16",
    "imageResolution": {
      "width": 736,
      "height": 1312
    }
  }
}
 */

export interface IImageAi {
  userUpload: boolean;
  cover: boolean;
  id: string;
  imageGroupId: string;
  companyId: string;
  userId: string;
  cost: number | null;
  visibility: 'PUBLIC' | 'PRIVATE';
  imageGenerationOptions: IImageGenerationOptions;
  path: string;
  skeleton: boolean;
  imageUrlFormat: string;
  user: {
    id: string;
    nick: string;
  };
  created: Date;
}

export class ImageAi {
  userUpload: boolean;
  cover: boolean;
  id: string;
  imageGroupId: string;
  companyId: string;
  userId: string;
  cost: number | null;
  visibility: 'PUBLIC' | 'PRIVATE';
  imageGenerationOptions: ImageGenerationOptions;
  path: string;
  skeleton: boolean;
  imageUrlFormat: string;
  created: Date;
  user: {
    id: string;
    nick: string;
  };

  constructor(data: Partial<IImageAi> = {}) {
    this.userUpload = data.userUpload || false;
    this.cover = data.cover || false;
    this.id = data.id || '';
    this.imageGroupId = data.imageGroupId || '';
    this.companyId = data.companyId || '';
    this.userId = data.userId || '';
    this.cost = data.cost || null;
    this.visibility = data.visibility || 'PUBLIC';
    this.imageGenerationOptions = new ImageGenerationOptions(data.imageGenerationOptions);
    this.path = data.path || '';
    this.skeleton = data.skeleton || false;
    this.imageUrlFormat = data.imageUrlFormat || '';
    this.user = data.user || { id: '', nick: '' };
    this.created = data.created || new Date();
  }

  importImage(image: IImageAi) {
    this.userUpload = image.userUpload;
    this.cover = image.cover;
    this.id = image.id;
    this.imageGroupId = image.imageGroupId;
    this.companyId = image.companyId;
    this.userId = image.userId;
    this.cost = image.cost;
    this.visibility = image.visibility;
    this.imageGenerationOptions = new ImageGenerationOptions(image.imageGenerationOptions);
    this.path = image.path;
    this.skeleton = false;
    this.imageUrlFormat = image.imageUrlFormat;
    this.user = image.user;
    this.created = image.created;
  }

  downloadImage() {
    window.open(this.path, '_blank');
  }
}

export interface IImagePrompt {
  config: IImageGenerationOptions;
  quantity: number;
}
export class ImagePrompt {
  config: ImageGenerationOptions;
  quantity: number;

  constructor(data: Partial<IImagePrompt> = {}) {
    this.config = new ImageGenerationOptions(data.config);
    this.quantity = data.quantity || 4;
  }

  prepareForCreate() {
    const { imageResolution, ...restProperties } = this.config;
    return {
      config: restProperties,
      quantity: this.quantity,
    };
  }
}
