<div class="w-full h-full flex flex-column justify-content-between gap-3">
  <span class="flex flex-column gap-3">
    <div class="w-full text-center">
      <h2 class="my-auto">Ciao!</h2>
    </div>
    <div class="w-full text-center">
      <h4 class="my-auto">Benvenuto in questa versione beta di Pupau.ai!</h4>
    </div>
    <div class="text-justify p-3">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas nulla hic dolor! Laudantium ducimus numquam deleniti ratione soluta dicta! Non
      quis praesentium commodi rem in modi, quidem culpa placeat! Eius deleniti beatae ex sapiente voluptate in praesentium eum. Cumque dicta quisquam
      soluta, corporis ullam nemo illum labore maiores quibusdam qui? At consequuntur sit officiis eum veniam totam asperiores? Earum similique at
      error officia, et esse sequi rem deserunt itaque nisi adipisci, beatae officiis praesentium delectus quae cupiditate rerum numquam labore
      recusandae non eligendi? Iste aspernatur deserunt qui facilis eum sit dolorem pariatur sint at dolore magnam consequatur nobis possimus eaque ab
      sunt, minima fugiat suscipit consequuntur ullam exercitationem saepe maiores. Ullam nostrum maiores nihil recusandae possimus. Voluptatem ad,
      assumenda suscipit vel pariatur eligendi explicabo voluptas necessitatibus iste laudantium odit praesentium cumque dolor ipsa, aspernatur
      officiis recusandae fuga amet voluptatum sed nobis! Repudiandae inventore non recusandae adipisci, ratione, totam sed culpa minima, possimus
      reiciendis suscipit libero? Illo facere cumque doloribus numquam vitae asperiores, repudiandae reprehenderit cupiditate ut iste quae molestiae
      fugiat explicabo iure quisquam rem soluta atque nemo dolor architecto odio culpa, consectetur repellendus consequuntur! Dolorum harum eveniet
      ipsa voluptatibus vel mollitia obcaecati distinctio, corrupti cupiditate non temporibus! Alias, libero voluptatibus!
    </div>
  </span>

  <div class="p-dialog-footer p-0 pb-3">
    <button pButton class="p-button-primary" label="OK" (click)="closeModal()"></button>
  </div>
</div>
