import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BotsService } from 'src/app/main/services/bots.service';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { WebComponentService } from 'src/app/main/services/web-component.service';
import { ChatBot } from '../../models/chat-bot.model';

@Component({
  selector: 'app-assistant-chat-buttons',
  templateUrl: './assistant-chat-buttons.component.html',
  styleUrls: ['./assistant-chat-buttons.component.scss'],
})
export class AssistantChatButtonsComponent {
  bot?: ChatBot;
  optionsDialog: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly botService: BotsService,
    private readonly confirmationService: ConfirmationService,
    private readonly webComponentService: WebComponentService,
    private readonly mainLanguageService: MainLanguageService
  ) {
    this.botService.currentBot$.subscribe({
      next: (bot) => (this.bot = bot),
    });
  }

  options() {
    this.router.navigate(['private', 'o', 'assistants', this.bot?.id, 'configuration']);
  }

  reloadChat() {
    this.confirmationService.confirm({
      header: this.mainLanguageService.instant('common.warning'),
      message: this.mainLanguageService.instant('private.assistantPage.restartModal.message'),
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () =>
        this.router.navigate(
          ['/', 'private', this.bot?.type === 'ASSISTANT' ? 'o' : '', 'assistants', this.bot?.id, 'chat', 'new'].filter((v) => !!v),
          {
            onSameUrlNavigation: 'reload',
          }
        ),
    });
  }

  history() {
    this.webComponentService.setEvents$ = {
      detail: {
        type: 'HISTORY_TOGGLE',
        payload: '',
      },
    };
  }
}
