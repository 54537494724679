<div class="w-full flex cursor-pointer hover:bg-gray-100 transition-all transition-duration-200" (click)="onItemPriceClick.emit(item)">
  <div class="col-4">
    <img
      class="w-full border-circle"
      fallbackImage="https://cdn.pupau.ai/assets/logo.webp"
      [src]="imageUuidService.getMarketplaceImageUrlFromUUID(item, 'H')"
    />
  </div>
  <div class="col-8 relative">
    <p class="font-semibold mb-0">{{ item.name }}</p>
    <p class="my-0 text-gray-400">{{ item.creator?.name }}</p>
    @if (item.rating) {
    <span [pTooltip]="item.rating.rating.toString()">
      <p-rating [(ngModel)]="item.rating.rating" [readonly]="true" [cancel]="false" class="product-rating"></p-rating>
    </span>
    }
    <p class="my-0 bot-description">{{ item.description }}</p>
    @if (item.using) {
    <span class="absolute bottom-0 right-0 flex justify-content-end p-3 pb-4">
      <p-tag styleClass="bg-gray-200">
        <span class="bg-gray-200 text-gray-500">{{ 'private.dashboardPage.marketplaceSection.installed' | translate }}</span>
      </p-tag>
    </span>
    }
  </div>
</div>
