import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChatBot, ICreateAssistantGroupDTO } from '../../models/chat-bot.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { minArrayLengthValidator } from '../../validators/array-min-length.validator';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { AssistantGroupsService } from 'src/app/main/services/assistant-groups.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-create-group-modal',
  templateUrl: './create-group-modal.component.html',
  styleUrl: './create-group-modal.component.scss',
})
export class CreateGroupModalComponent {
  bots: ChatBot[];
  selectedBots: ChatBot[] = [];
  createGroupForm: FormGroup;

  loading: boolean = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly ref: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    private readonly assistantGroupsService: AssistantGroupsService
  ) {
    this.bots = this.config.data.bots;
    this.createGroupForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      bots: new FormControl(<ChatBot[]>[], [minArrayLengthValidator(2)]),
      moderatorIds: new FormControl(null, [Validators.required]),
    });
  }

  get botsFormArray() {
    return this.createGroupForm.get('bots') as FormControl;
  }

  addBot(event: CheckboxChangeEvent, bot: ChatBot) {
    if (event.checked) {
      this.selectedBots.push(bot);
    } else {
      this.selectedBots.splice(this.selectedBots.indexOf(bot), 1);
    }

    this.createGroupForm.patchValue({
      bots: this.selectedBots,
    });
  }

  createGroup() {
    const rawValue = this.createGroupForm.getRawValue();
    const body: ICreateAssistantGroupDTO = {
      name: rawValue.name,
      chatBotIds: (rawValue.bots as ChatBot[]).map((b) => b.id),
      moderatorIds: this.selectedModerator.id,
    };

    this.loading = true;
    this.assistantGroupsService
      .createGroup$(body)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => this.ref.close({ created: true }),
      });
  }

  get selectedModerator() {
    return this.createGroupForm.get('moderatorIds')?.value as ChatBot;
  }
}
