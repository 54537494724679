import { AssistantUsePermission } from './assistant-use.model';

export const BOT_USER_PERMISSIONS_OPTIONS: { label: string; value: AssistantUsePermission }[] = [
  {
    label: 'interaction',
    value: 'USE',
  },
  {
    label: 'editing',
    value: 'EDIT',
  },
];
