import { Component } from '@angular/core';
import { MainLanguageService } from 'src/app/main/services/main-language.service';
import { LANGUAGES } from '../../constants/languages.items';
import { DynamicLocaleService } from 'src/app/main/services/dynamic-locale.service';
import { LanguagesEnum } from '../../enums/app-languages.enum';
import { UsersService } from 'src/app/main/services/users.service';
import { tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent {
  readonly languages = LANGUAGES;

  defaultLanguage?: string;

  constructor(
    private readonly authService: AuthService,
    private readonly userService: UsersService,
    private readonly mainLanguageService: MainLanguageService,
    private readonly dynamicLocaleService: DynamicLocaleService
  ) {
    this.mainLanguageService.defaultLanguage$.subscribe((lang) => {
      if (lang && (Object.values(LanguagesEnum) as string[]).includes(lang)) {
        this.dynamicLocaleService.locale = lang;
        this.defaultLanguage = lang;
      }
    });
  }

  onValueChange(event: { value: LanguagesEnum }) {
    this.mainLanguageService.useLanguage(event.value);
    this.userService.updateUserLanguage$(event.value).subscribe({
      next: () => this.authService.updateMe(),
    });
  }
}
